@import '../../../styles/variables';

.search-select {
    position: relative;
    width: 100%;

    &--input-icon {
        position: absolute;
        right: 10px;
        opacity: 0;
    }

    &--current-option {
        position: relative;
        cursor: pointer;
        white-space: nowrap;

        &.not-clickable {
            cursor: not-allowed;
        }

        &.invalid-value {
            background: #c9626a !important;
        }
        
        &:hover {
            .search-select--input-icon {
                opacity: 1.0;
            }
        }
    }

    &--placeholder {
        opacity: 0.6;
    }

    &--body {
        position: absolute;
        margin: 5px 0px;
        display: flex;
        align-items: flex-start;
        z-index: 2;

        &.top {
            bottom: 100%;

            .search-select {
                &--search-input {
                    position: absolute;
                    bottom: 0px;
                    border-bottom: none;
                    border-top: 1px solid black;
                }
            }
        }
    }

    &--options-wrapper {
        width: auto;
        @include background-color-4;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 0px 3px black;
    }

    &--options {
        max-height: 279px;
        overflow-x: hidden;
        overflow-y: auto;

        &.move-up {
            margin-bottom: 27px;
        }
    }

    &--option {
        cursor: pointer;
        font-size: 13px;
        padding: 0px 10px;
        border-bottom: 1px solid black;
        line-height: 30px;
        color: #eee;
        white-space: nowrap;
        font-weight: bold;

        &:last-child {
            border: none !important;
        }

        &.focused-option {
            @include background-color-5;
        }

        &.disabled {
            cursor: not-allowed;
        }

        &:hover {
            @include background-color-6;
            color: white;
        }
    }

    &--search-input {
        position: relative;
        width: calc(100% - 20px);
        display: block;
        padding: 0px 10px;
        font-weight: bold;
        font-size: 14px;
        font-weight: bold;
        @include  background-color-4;
        color: white;
        border-bottom: 1px solid black;
        height: 28px;
        cursor: text;
    }

    &--empty-message {
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        color: #ddd;
        padding: 0px 10px;
        white-space: nowrap;
    }

    &--clear-button {
        position: absolute;
        right: 8px;
        border-radius: 21px;
        padding: 0px;
        width: 17px;
        height: 17px;
        color: white;
        font-size: 13px;
        opacity: 0.7;
        @include background-color-3;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            margin-top: 2px;
        }
    }

    &--supplement {
        position: relative;
        margin-left: 10px;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 0px 3px black;
    }
}