@import '../../styles/variables';

.auth-form {
    position: relative;
    width: 100%;
    color: white;
    min-height: 500px;
    font-weight: bold;
    
    &--title {
        font-family: 'Orator', sans-serif;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        text-shadow: 0px 0px 8px #616161;
        white-space: nowrap;
    }

    &--main {
        position: relative;
        margin: 50px auto 0px;
        max-width: 400px;
    }

    &--input-block {
        position: relative;
        width: 100%;
        margin-top: 20px;
    }

    &--input-label {
        position: relative;
        display: block;
        color: #ddd;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    }

    &--input {
        display: block;
        margin-top: 5px;
        width: 100%;
        background: none;
        border-bottom: 2px solid #ddd;
        color: white;
        font-size: 16px;
        padding: 7px 0px;
        font-weight: bold;
        cursor: text;

        &[type="email"] {
            width: calc(100% - 200px);
            padding-right: 200px;
        }
    }

    &--login-button, &--reset-password-button {
        width: 100%;
        margin-top: 30px;
        @include background-color-8;
        color: #ddd;
        font-size: 16px;
        padding: 12px 0px;
        font-weight: bold;
        border-radius: 3px;

        &:hover {
            color: white;
        }

        &:disabled {
            color: #ddd !important;
        }
    }

    &--message {
        background: #00000066;
        text-align: center;
        margin-top: 20px;
        padding: 7px;
        border-radius: 3px;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: -10px;
    }

    &--reset-password-button {
        background: #e09657;
    }

    &--change-form-button {
        float: right;
        background: none;
        color: #eee;
        font-size: 13px;
        margin-top: 70px;
        &:hover {
            color: white;
        }
    }
}