@import '../../styles/responsive.scss';
@import '../../styles/variables';

.header {
    position: relative;
    display: flex;
    height: 36px;
    line-height: 36px;
    font-weight: bold;
    white-space: nowrap;
    @include background-color-1;

    &--title {
        font-family: 'Orator';
        font-size: 18px;
        font-weight: bold;
        color: #ddd;
        margin-left: 10px;
    }

    &--navigators {
        margin-left: 20px;
        font-size: 14px;
        width: 100%;
        >a {
            display: inline-block;
            height: 100%;
            color: #ddd;
            padding: 0px 15px;

            &:hover {
                @include background-color-2;
                color: white;
            }

            &.active {
                @include background-color-2;
                color: white;
            }

            &.disabled {
                cursor: not-allowed;
            }
        }
    }

    &--right-content {
        display: flex;
    }

    &--clock {
        font-size: 14px;
        color: #ddd;
        margin-right: 10px;
    }

    &--logout-button {
        background: transparent;
        color: #ddd;
        font-size: 19px;
        width: 35px;
        padding: 0;
        margin: 0;
        margin-right: 5px;

        &:hover {
            @include background-color-2;
            color: white;
        }

        >svg {
            transform: translateY(3px);
        }
    }
}

.footer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 31px;
    overflow: hidden;
    @include background-color-3;
    font-weight: bold;

    &--username {
        position: absolute;
        left: 15px;
        font-size: 13px;
        font-weight: bold;
        color: #ddd;
    }

    &--right-content {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 15px;
    }

    &--connections-overview {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: bold;
        color: #ddd;
        margin-left: 15px;

        &--list {
            margin-left: 10px;
            position: relative;
            display: flex;
            align-items: center;
            gap: 2px;
        }

        &--item {
            width: 3px;
            height: 7px;
            border: 2px solid transparent;
            background: #c7767c;
            border-color: #c7767c;
            border-radius: 2px;
    
            &.ready-state-0 {
                background: #c7bd71;
                border-color: #c7bd71;
            }
    
            &.ready-state-1 {
                background: #67b7b7;
                border-color: #67b7b7;

                &.slow-network {
                    border-color: indianred;
                    box-shadow: 0px 0px 3px black;
                }
            }
        }
    }

    &--connections {
        position: relative;
        height: 100%;
        margin-left: 15px;
        display: flex;
        align-items: center;

        &--popup {
            background: #061928;
            padding: 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
            margin-bottom: 5px;
        }

        &--list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            .socket-connection {
                display: flex;
                width: 100%;
                align-items: center;
                font-size: 14px;
            
                &--text {
                    min-width: none;
                    flex-grow: 1;
                }
                &--text--delay {
                    display: inline-block;
                    min-width: 60px;
                }
            }
        }

        &--offline-label {
            font-size: 13px;
            margin-right: 20px;
        }

        &--disconnected-wrapper {
            position: fixed;
            bottom: 36px;
            z-index: 2;
            @include background-color-8;
            opacity: 0.8;
            right: 10px;
            padding: 5px 38px 5px 10px;
            box-shadow: 0px 0px 3px #001d1c;
            border-radius: 2px;
            color: white;
            font-size: 14px;
            line-height: 20px;

            &--close-button {
                position: absolute;
                right: 1px;
                background: none;
                color: white;
                top: 6px;
                font-size: 18px;
                opacity: 0.8;

                &:hover {
                    opacity: 1.0;
                }
            }
        }
    }
}

.verifying {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &--body {
        position: absolute;
        text-align: center;

        >img {
            display: block;
            width: 100px;
        }

        >div {
            font-size: 16px;
            font-weight: bold;
            transform: translateY(-25px);
        }
    }

}