@import '../styles/variables';

.login-page {
    position: absolute;
    width: 100%;
    height: 100%;

    &--curtain {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #0107176e;
    }

    &--main {
        position: absolute;
        width: 100%;
    }

    &--verifying-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include background-color-1;
        z-index: 2;

        &--content {
            text-align: center;
            &.is-verified {
                opacity: 0;
                transition: opacity 0.8s;
            }
            >img {
                height: 100px;
                opacity: 1.0;
            }
            >span {
                display: block;
                transform: translateY(-25px);
                font-size: 16px;
                font-weight: bold;
                color: #82a2a2;
            }
        }
    }
}