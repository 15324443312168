.loan-management-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    &--borrowers {
        width: 320px;
        border-right: 1px solid;
    }

    &--logs {
        flex-grow: 1;
    }
}

.loan-borrower-editor {
    &--inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        >div {
            display: flex;
            align-items: center;

            >label {
                font-size: 14px;
                font-weight: bold;
                width: 120px;
            }
        }
    }
}