.socket-connection {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 13px;
    font-weight: bold;

    &--fetching-ticket {
        font-size: 12px;
        margin-right: 5px;
    }

    &--text {
        color: #ddd;

        &--delay {
            margin-right: 5px;
        }
    }

    &--bulb {
        width: 9px;
        height: 9px;
        background: #c7767c;
        right: 0;
        border-radius: 50%;
        margin-left: 7px;
        box-shadow: 0px 0px 2px #001515;

        &.ready-state-0 {
            background: #c7bd71;
        }

        &.ready-state-1 {
            background: #67b7b7;
        }
    }

    &--reconnect-timeout {
        font-size: 12px;
        margin-right: 5px;
        color: #9db3b3;
    }
}