@import '../../../styles/variables';

.array-of-string-input {
    position: relative;
    @include background-color-3;
    padding: 10px 10px 0px;

    &--value {
        display: inline-flex;
        align-items: center;
        height: 26px;
        font-size: 14px;
        gap: 0px;
        font-weight: bold;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0px 10px;
        @include background-color-2;
        border: 1px solid;
        @include border-color-1;
        border-radius: 3px;

        >button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 20px;
            height: 20px;
            opacity: 0.6;
            background: none;
            font-size: 14px;
            padding: 0;
            color: white;
            transition: all 0.3s;

            &:hover {
                opacity: 1.0;
            }
        } 
    }

    >input {
        width: 150px;
        font-size: 14px;
        font-weight: bold;
        @include background-color-6;
        color: white;
        padding: 0px 7px;
        height: 28px;
        cursor: text;
        margin-bottom: 10px;
        border-radius: 3px;
    }
}