@import '../../styles/variables';

.option-strategy-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    &--left {
        position: relative;
        min-width: 280px;
        width: 280px;
        border-right: 1px solid black;
    }

    &--right {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &--item-detail, &--draggable-line, &--trade-detail {
        position: absolute;
        width: 100%;
    }

    &--item-detail {
        top: 0;
        height: 65%;
    }

    &--draggable-line {
        height: 6px;
        top: 65%;
        transform: translateY(-3px);
        z-index: 2;
        background: transparent;
        cursor: ns-resize;
    }

    &--trade-detail {
        top: 65%;
        bottom: 0;
        border-top: 1px solid black;
    }

    &--list {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        &--search-input {
            height: 28px;
            font-size: 14px;
            width: calc(100% - 20px);
            padding: 0px 10px;
            font-weight: bold;
            @include background-color-3;
            cursor: text;
            color: white;
        }
    }

    &--items {
        position: relative;
        height: 100%;
        width: 100%;
        border-top: 1px solid;
        overflow: auto;
        @include border-color-7;
    }

    &--item {
        border-bottom: 1px solid;
        cursor: pointer;
        padding: 10px;
        @include background-color-7;
        @include border-color-7;

        &:hover {
            @include background-color-9;
        }

        &--close-button {
            position: absolute;
            right: 10px;
            font-size: 13px;
            background: transparent;
            color: white;
            opacity: 0;
            z-index: 2;
            transition: all 0.3s;
            padding: 0;

            >svg {
                transform: translateY(3px);
            }
            &:hover {
                opacity: 1.0 !important;
            }
        }

        &:hover {
            .option-strategy-container--item--close-button {
                opacity: 0.7;
            }
        }
    }

    &--add-strategy-button {
        @include button-style-3;
        height: 30px;
        line-height: 28px;
        margin: 10px;
        align-items: center;
        width: calc(100% - 20px);
        text-align: center;
        font-size: 14px;
        font-weight: bold;

        >svg {
            transform: translateY(3px);
            font-size: 16px;
        }
        
        >span {
            margin-left: 5px;
        }
    }
}

.option-strategy-item {
    position: absolute;
    width: 100%;
    height: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: column;

    &--card {
        color: white;
        font-weight: bold;
        &--name {
            font-size: 14px;
            line-height: 20px;
            white-space: normal;
            word-break: break-word;
        }
        &--prices {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-top: 5px;
        }

        &--price {
            &:not(:first-child) {
                margin-left: 10px;
            }
            >label {
                font-size: 13px;
                margin-right: 5px;
                opacity: 0.8;
            }
            &.BID {
                @include text-color-positive;
            }
            &.ASK {
                @include text-color-negative;
            }
        }
    }

    &--header {
        display: flex;
        align-items: center;
        @include background-color-3;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        font-weight: bold;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &--left-content {
            display: flex;
            align-items: center;
            white-space: nowrap;
            width: 100%;
        }
    }

    &--balance-header, &--position-header, &--order-book-header {
        display: flex;
        align-items: center;
        white-space: nowrap;
        
        >span {
            margin-right: 10px;
        }

        &--toggle-button {
            height: 24px;
            font-size: 13px;
            @include button-style-5;
            border-radius: 0px;
        }
    }

    &--symbol-header {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &--left-content {
            display: flex;
            align-items: center;
            width: 100%;
        }
        &--trade-toggle {
            display: flex;
            align-items: center;
            margin-left: 10px;

            >label {
                margin-right: 5px;
                font-size: 13px;
            }

            .toggle {
                height: 18px;
                display: flex;
                width: auto;

                &--track {
                    width: 36px;
                }

                &--button {
                    width: 14px;
                    height: 14px;
                }

                &--label {
                    line-height: 18px;
                }
            }
        }
    }

    &--name {
        text-indent: 10px;
        color: #ddd;
    }

    &--add-symbol-button, &--reset-symbol-button {
        @include button-style-2;
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 0px;
        border-right: 1px solid black;

        >svg {
            transform: translateY(2px);
        }
    }

    &--add-symbol-button {
        margin-left: 10px;
        font-size: 13px;
    }

    &--reset-symbol-button {
        font-size: 16px;
    }

    &--remove-button {
        height: 100%;
        margin-right: 15px;
        font-size: 16px;
        background: transparent;
        color: white;
        padding: 0px;
        opacity: 0.4;
        transition: all 0.3s;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            transform: translateY(2px);
        }
    }

    &--inputs {
        min-width: 280px;
        max-width: 320px;
        display: flex;
        align-items: flex-start;

        >button {
            display: flex;
            align-items: center;
            padding: 0;
            height: 28px;
            margin-right: 10px;
            background: transparent;
            color: white;
            opacity: 0.4;
            transition: all 0.3s;

            &:hover {
                opacity: 1.0;
            }
        }

        &--main {
            width: 100%;
            div {
                &.search-select {
                    &--current-option {
                        @include background-color-6;
                        padding: 0px 10px;
                        min-height: 28px;

                        >span {
                            display: block;
                            line-height: 20px;
                            padding: 4px 0px;
                            padding-right: 20px;
                            white-space: normal;
                            word-break: break-word;
                        }
                    }
                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                }
            }

            >input {
                height: 28px;
                width: 50px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                @include background-color-6;
                color: white;
                cursor: text;
            }
        }

        .option-symbol-greeks {
            margin-top: 10px;
        }

        &--order-editor {
            .option-symbol-greeks {
                margin-left: 10px;
            }
            .order-editor {
                min-width: 280px;
                margin-left: 10px;
                &--row--body {
                    overflow: visible;
                }
    
                .search-select {
                    &--options-wrapper {
                        position: absolute;
                    }
                }
    
                &--symbol {
                    .search-select {
                        &--current-option {
                            white-space: normal;
                            word-break: break-word;
                            height: auto;
        
                            &--name {
                                white-space: normal;
                                word-break: break-word;
                                line-height: 20px;
                                padding: 4px 0px;
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    &--main {
        position: relative;
        height: 100%;
        overflow: auto;
        width: calc(100% - 20px);
        padding: 10px 10px 50px;
    }

    &--table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        
        >thead {
            >tr {
                >th {
                    font-size: 13px;
                    text-align: left;
                    @include background-color-1;
                    height: 24px;
                    line-height: 24px;
                    padding: 0px 10px;
                    color: #ccc;
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    vertical-align: top;
                    font-size: 14px;
                    padding: 10px;

                    &:first-child {
                        padding-left: 0px;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid black;
                    }
                }
                &:not(:first-child) {
                    >td {
                        border-top: 1px solid black;

                    }
                }
            }
        }
    }

    &--aggregated-book-column {
        .order-book {
            position: sticky;
            top: 0px;
        }
    }

    &--bulk-orders-popup {
        @include background-color-4;
        border-radius: 5px;
        box-shadow: 0px 0px 3px black;
        padding: 10px;
        font-weight: bold;
        color: white;

        &--trigger {
            @include button-style-4;
            height: 24px;
            border-radius: 0px;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 10px;
        }

        &--directions {
            display: flex;
            width: 100%;
        }

        &--direction {
            width: 50%;
            height: 28px;
            font-size: 14px;
            font-weight: bold;
            @include background-color-6;
            color: #ddd;

            &.selected {
                color: white;
                &.LONG {
                    background: #469a98;
                }
                &.SHORT {
                    background: #de6a74;
                }
            }

            border-radius: 0px;
        }

        &--order-multiplier {
            margin-top: 10px;
            >label {
                font-size: 13px;
                opacity: 0.7;
                margin-right: 10px;
            }   
            >input {
                @include background-color-6;
                width: 40px;
                padding: 0px 10px;
                height: 24px;
                color: white;
                font-size: 14px;
                font-weight: bold;
                cursor: text;
            }
        }

        &--main {
            margin-top: 10px;
        }

        &--order-item {
            &:not(:first-child) {
                margin-top: 10px;
            }

            &--main {
                font-size: 14px;
                line-height: 28px;
                color: #ddd;

                >span {
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }

                .side {
                    &.BUY {
                        @include text-color-positive;
                    }
                    &.SELL {
                        @include text-color-negative;
                    }
                }

                .size, .price {
                    font-size: 16px;
                    color: white;
                }
            }
    
            &--stats {
                display: flex;
                font-size: 13px;
                line-height: 18px;

                >div {
                    &:not(:first-child) {
                        margin-left: 10px;
                    }
                    >label {
                        margin-right: 5px;
                        opacity: 0.7;
                    }
                    >span {
                        color: white;
                    }
                }
            }
        }

        &--submit-button {
            width: 100%;
            margin-top: 20px;
            @include button-style-3;
            height: 30px;
            font-size: 14px;
        }

        &--message {
            font-size: 14px;
            color: #ddd;
            max-width: 280px;
            line-height: 20px;
        }
    }

    .swap-account-balance-item {
        min-width: 180px;
        max-width: 280px;
        &--coin {
            font-size: 14px;
            line-height: 20px;
            margin-top: 3px;
        }
        &--rows {
            margin-top: 5px;
            @include border-color-3;
        }
        &--row {
            &:last-child {
                >div {
                    padding-bottom: 5px;
                }
            }
        }
        &--summary {
            padding-top: 5px;
            margin: 0;
        }
    }

    .position-item {
        min-width: 180px;
        max-width: 320px;
        &--header {
            &--title {
                font-size: 14px;
                white-space: normal;
                word-break: break-all;
                line-height: 20px;
                margin-top: 3px;
            }
        }
        table {
            font-size: 13px;
            margin-top: 5px;
            th, td {
                font-size: 13px;
            }
            td {
                line-height: 18px;
            }
        }
        &--data {
            margin-top: 5px;
            @include border-color-3;
        }
        &--data-row {
            font-size: 13px;
            line-height: 18px;
            &:first-child {
                >div {
                    padding-top: 5px;
                }
            }
            &:last-child {
                >div {
                    padding-bottom: 5px;
                }
            }
        }
        &--pricing-wrapper {
            padding-top: 5px;
        }
    }

    &--empty-data {
        color: #ddd;
        font-size: 14px;
        text-align: center;
    }
}

.option-symbol-greeks {
    font-size: 14px;
    >label {
        opacity: 0.7;
    }
    >span {
        text-decoration: underline dotted;
    }

    &--popup {
        @include background-color-4;
        box-shadow: 0px 0px 3px black;
        padding: 10px;
        border-radius: 3px;
    }

    &--main {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        color: white;

        >hr {
            border-top: none;
            border-bottom: 1px solid;
            @include border-color-1;
        }

        >div {
            position: relative;
            display: flex;
            white-space: nowrap;
            width: 100%;
            line-height: 20px;

            >label {
                width: 100%;
                padding-right: 20px;
                opacity: 0.7;
            }
        }
    }
}