@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import './variables';

@font-face {
    font-family: 'Orator';
    font-style: normal;
	font-weight: normal;
    src: url('Orator-Medium.ttf') format('truetype');
}

html {
	position: relative;
	width: 100%;
	height: 100%;
	@include background-color-7;
	color: #eee;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
	overflow: hidden;
	min-width: 1024px;
	min-height: 400px;
}

body, div, p, span, video, button, select {
	font-family: "Source Sans Pro", "-apple-system", "BlinkMacSystemFont", "Helvetica Neue", "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif;
	image-rendering: initial;	
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-webkit-text-size-adjust: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a {
    text-decoration: none;
}

input, textarea {
    cursor: default;
    -webkit-font-smoothing: antialiased;
	font-family: "Source Sans Pro", "-apple-system", "BlinkMacSystemFont", "Helvetica Neue", "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif;
    image-rendering: initial;	 
}

input, textarea, div, button, select {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: 0px !important;
	-webkit-font-smoothing: antialiased;
	border-radius: 0px;
	border: none;

	&:disabled {
		cursor: not-allowed;
	}
}

button {
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
	}
}

::-webkit-scrollbar{
	width: 5px;
	height: 5px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background-color: lightslategray;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.hidden {
    display: none !important;
}

.clearfix:after {
	content: " ";
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
}
 
 .horizontal-centered {
	position: relative;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
 
 .vertical-centered {
	position: relative;
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
}
 
.centered {
	position: relative;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);	
}

.modal-overlay {
	position: fixed;
    width: 100%;
    height: 100%;
	background: #012727c7;
	z-index: 99;
}

.popup.visible {
	z-index: 99;
}