@import '../../styles/variables';

.profile-action-popup {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 0px 5px #032524;

    &.bottom.left {
        margin-right: 15px;   
    }

    &--button {
        text-align: left;
        position: relative;
        font-size: 12px;
        font-weight: bold;
        background: #227173ed;
        color: #ddd;
        border-bottom: 1px solid #053333;
        padding: 6px 9px;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background: #3d9194;
            color: white;
        }

        >svg {
            margin-right: 5px;
            transform: translateY(1px);
        }
    }
}

.profile-action-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    @include background-color-11;

    &--button {
        text-align: left;
        position: relative;
        font-size: 12px;
        font-weight: bold;
        color: #ddd;
        @include background-color-11;
        border-bottom: 1px solid;
        @include border-color-2;
        padding: 6px 9px;

        &:last-child {
            border-bottom: none;
        }

        &:disabled {
            opacity: 0.6;
        }

        &:not(:disabled) {
            &:hover {
                @include background-color-5;
                color: white;
            }
        }

        >svg {
            margin-right: 5px;
            transform: translateY(1px);
        }
    }
}