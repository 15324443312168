@import '../../styles/variables';

.funding-tags {
    font-size: 14px;
    font-weight: bold;

    &--current {
        display: flex;
        cursor: pointer;

        >label {
            margin-right: 10px;
            opacity: 0.7;
        }
        >span {
            margin-right: 5px;
            white-space: nowrap;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        >svg {
            opacity: 0.7;
            transform: translateY(7px);
            font-size: 16px;
        }
        &:hover {
            >svg {
                opacity: 1.0;
            }
        }
    }

    &--main {
        position: absolute;
        box-shadow: 0px 0px 3px black;
        font-size: 14px;

        color: #ddd;
        border-radius: 3px;
        overflow: hidden;
        @include background-color-5;

        &--all, &--add-tag-button {
            height: 28px;
            line-height: 28px;
            padding: 0px 10px;

            white-space: nowrap;
            cursor: pointer;
            &:hover {
                color: white;
            }
        }

        &--all {
            @include background-color-4;
            border-bottom: 1px solid black;

            &:hover {
                @include background-color-5;
            }
        }

        &--add-tag-button {
            @include background-color-6;

            >svg {
                font-size: 20px;
                transform: translateY(5px);
                margin-right: 10px;
            }
        }

        &--add-tag-popup {
            @include background-color-2;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;
            padding: 15px;
            width: 320px;
        }
    }
}

.funding-tag-item {
    position: relative;
    z-index: 100;
    color: #ddd;
    display: flex;
    line-height: 23px;
    @include background-color-4;

    &:hover {
        @include background-color-5;
        color: white;

        .funding-tag-item--trigger {
            opacity: 0.7;

            &:hover {
                opacity: 1.0;
            }
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid #002a2a;
    }

    &--trigger {
        position: relative;
        width: 28px;
        opacity: 0;
        cursor: pointer;



        >svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--button {
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        background: transparent;
        color: inherit;
        text-align: left;
        padding: 3px 11px;
        cursor: pointer;
    }

    &--popup {
        @include background-color-2;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        padding: 15px;
        width: 320px;
    }
}

.funding-tag-editor {
    &--section {
        font-weight: bold;
        margin-bottom: 10px;

        >label {
            display: block;
            width: 100%;
            font-size: 14px;
            opacity: 0.8;
        }
    }

    &--name-input {
        background: none;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 2px solid #aaa;
        width: 100%;
        padding: 3px 0px;
        margin-top: 2px;
        color: white;
        cursor: text;
    }

    &--coins {
        margin-top: 5px;
    }

    &--coin {
        float: left;
        margin-right: 5px;
        margin-bottom: 5px;
        @include background-color-5;
        height: 28px;
        padding: 0px 10px;
        padding-right: 8px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
        transition: all 0.3s;

        &:hover {
            background: lightslategray;
        }

        &--remove-button {
            padding: 0px;
            height: 100%;
            background: transparent;
            margin-left: 5px;
            color: #ddd;
            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(2px);
            }
        }
    }

    &--coins-editor {
        position: relative;
        margin-top: 10px;

        .search-select {
            width: auto;
            margin-right: 10px;

            &--current-option {
                @include background-color-6;
                line-height: 30px;
                text-align: center;
                padding: 0px 10px;
                font-size: 14px;
                color: #ddd;
                border-radius: 3px;
                transition: all 0.3s;

                &:hover {
                    color: white;
                    @include background-color-5;
                }
            }

            &--placeholder {
                opacity: 1.0;
            }
            
            &--input-icon {
                display: none;
            }
        }
    }

    &--add-coin-button, &--clear-coin-button {
        float: left;
    }

    &--clear-coin-button {
        @include button-style-5;
        font-size: 14px;
        height: 30px;
        padding: 0px 10px;
    }

    &--buttons {
        text-align: center;
        margin-top: 20px;


        >button {
            display: inline-block;
            height: 30px;
            font-weight: bold;
            width: 89px;
            margin: 0px 5px;
            @include button-style-4;
        }
    }

    &--button {
        &.delete {
            @include button-style-5;
        }
    }
}