@import '../../../styles/variables';

.table-column-selector {
    background: #0f2b3cd9;
    box-shadow: 0px 0px 3px black;
    margin-top: 5px;
    font-weight: bold;
    border-radius: 3px;
    overflow: hidden;

    &--trigger {
        position: relative;
        width: 100%;
        background-color: transparent;
        color: #ddd;
        font-size: 16px;
        padding: 0;

        &:hover {
            color: white;
        }

        >svg {
            vertical-align: middle;
        }
    }

    &--header {
        position: relative;
        height: 30px;
        line-height: 30px;
        @include background-color-4;
        padding: 0px 10px;

        >label {
            font-size: 14px;
        }

        &--buttons {
            position: relative;
            display: inline-flex;
            float: right;
            top: 50%;
            transform: translateY(-50%);

            >button {
                @include button-style-2;
                font-size: 14px;
                font-weight: bold;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }

    &--options {
        position: relative;
        min-width: 400px;
        max-width: 400px;
        padding: 10px 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    &--option {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: bold;
        gap: 8px;

        .checkbox {
            width: 20px;
            height: 20px;
        }
    }
}