@import '../../styles/variables';

@keyframes slideDown {
    0% {
        opacity: 0.6;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1.0;
        transform: translateY(0);
    }
}

.profile-editor {
    position: absolute;
    width: auto;
    max-width: 100%;
    color: white;
    background: #094a4a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 95%;
    box-shadow: 0px 0px 7px #021313;
    display: flex;
    flex-direction: column;

    &--header {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: #074a49;
        padding: 8px 0px 0px;
        box-shadow: 0px 0px 3px #011716;
    }

    &--main {
        position: relative;
        overflow-x: hidden;
        width: 100%;
        padding-bottom: 25px;
    }

    &--close-button {
        position: absolute;
        right: 15px;
        background: none;
        color: #eee;
        font-size: 20px;
        z-index: 1;
        top: 10px;

        &:hover {
            color: white;
        }
    }

    &--title {
        position: relative;
        font-size: 19px;
        font-weight: bold;
        color: #eee;
        text-indent: 26px;
        line-height: 25px;
        padding-right: 60px;
    }

    &--status-wrapper {
        position: relative;
        background: #063c3c;
        margin-top: 8px;
        height: 29px;
        min-width: 350px;
        display: flex;
        align-items: center;

        &--indicators {
            display: flex;
            align-items: center;
        }

        &--right-section {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: auto;
            height: 100%;
        }

        &--snapshots {

            &--popup {
                border-radius: 3px;
                box-shadow: 0px 0px 3px black;
                min-width: 350px;
                overflow: hidden;
            }

            &--trigger {
                @include button-style-2;
                font-weight: bold;
                margin-right: 10px;
            }
        }

        &--sync {
            display: flex;
            align-items: center;
            font-weight: bold;
            margin-right: 10px;

            &--label {
                font-size: 12px;
                line-height: 17px;
                margin-right: 9px;
                color: #dadada;

                &.syncing {
                    color: #d2b775;
                }

                &.sync-failed {
                    color: #ce757c;
                }
            }

            &--popup {
                font-size: 12px;
                background: #a3c3c3c2;
                padding: 4px 10px;
                box-shadow: 0px 0px 2px #063838;
                border-radius: 3px;
                color: #074a4a;
                line-height: 16px;
                max-width: 200px !important;
                font-weight: bold;
            }

            &--button {
                @include button-style-5;
    
                &:hover { 
                    background: #c3dada;
                    border-color: #c3dada;
                    color: #053c3b;
                }

                >svg {
                    font-size: 11px;
                    margin-right: 4px;
                    transform: translateY(2px) scale(1.2);
                }
            }
        }
    }

    &--action-trigger-button {
        height: 100%;
        background: none;
        margin-right: 22px;
        font-size: 20px;
        padding: 0;
        color: #90b3b3;

        >svg {
            margin-top: 5px;
        }

        &:hover {
            color: #c3dada;
        }
    }

    &--profile-selector {
        display: flex;
        align-items: center;

        &--popup {
            &--trigger {
                margin-left: 10px;
                @include button-style-2;
                border-radius: 0px;
                padding: 0;
                width: 25px;
                height: 25px;
                min-width: 25px;

                >svg {
                    font-size: 18px;
                    vertical-align: middle;
                }
            }
            &--main {
                @include background-color-2;
                padding: 10px;
                box-shadow: 0px 0px 3px black;

                >div {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    font-weight: bold;

                    &:not(:first-child) {
                        margin-top: 10px;
                    }

                    >label {
                        font-size: 14px;
                        min-width: 120px;
                        opacity: 0.7;
                    }

                    >input {
                        color: white;
                        font-size: 14px;
                        @include background-color-6;
                        padding: 0px;
                        font-weight: bold;
                        padding: 0px 5px;
                        height: 25px;
                        cursor: text;
                        min-width: 220px;
                    }

                    .search-select {
                        &--current-option {
                            @include background-color-6;
                            font-size: 14px;
                            padding-left: 5px;
                            height: 25px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }

    &--status {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 26px;

        &--bulb {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-shadow: 0px 0px 3px #000000;

            &.started, &.resumed, &.loop-on {
                background: #4dbbbb;
            }

            &.stopped, &.paused, &.loop-off {
                background: #ce757c;
            }

            &.starting, &.stopping, &.resuming, &.pausing {
                background: #d2b775;
            }
        }

        &--label {
            margin-left: 8px;
            font-size: 12px;
            color: #ddd;
            font-weight: bold;
        }

        &.crashed-status {
            color: #dc7479;

            >svg {
                position: absolute;
                top: 6px;
                font-size: 17px;
            }

            .profile-editor--status--label {
                margin-left: 21px;
                color: inherit;
            }
        }
    }

    &--leg {
        position: relative;
        margin-top: 10px;

        &--header {
            position: relative;
            color: #90b3b3;
            cursor: pointer;

            &:hover {
                background: #105758;
            }

            >svg {
                position: absolute;
                font-size: 22px;
                right: 26px;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 2px;
            }
        }
    
        &--title {
            position: relative;
            font-weight: bold;
            font-size: 19px;
            text-indent: 26px;
            border-bottom: 1px solid;
            line-height: 40px;
        }

        &--editor{
            position: relative;
            margin: 9px 26px 0px;
            overflow-x: auto;
            animation: slideDown 300ms;
        }
    }

    &--accounts {
        position: relative;
        margin-top: 10px;

        &--disabled-popup {
            max-width: 205px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #011b1af5;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
            margin: 5px 0px;


            &::after {
                content: '';
                position: absolute;
                left: 9px;
                top: 100%;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid #011b1af5;
                clear: both;
            }
        }

        &--title {
            position: relative;
            color: #90b3b3;
            font-weight: bold;
            font-size: 19px;
            text-indent: 26px;
            border-bottom: 1px solid;
            line-height: 40px;
            cursor: pointer;

            &:hover {
                background: #105758;
            }

            >svg {
                position: absolute;
                font-size: 22px;
                right: 26px;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 2px;
            }
        }

        &--list {
            position: relative;
            margin: 12px 26px 0px;
            animation: slideDown 300ms;
        }

        &--empty-message {
            font-size: 14px;
            color: #7aa0a0;
            font-weight: bold;
            padding-top: 11px;
            position: relative;
        }

        &--item {
            position: relative;
            display: table-row;
        }

        &--exchange-name, &--exchange-accounts {
            position: relative;
            display: table-cell;
        }

        &--exchange-name {
            padding: 11px 0px;
            color: #eee;
            font-size: 15px;
            font-weight: bold;
            padding-right: 15px;
        }

        &--exchange-account-selector-row {
            display: flex;
        }

        &--account-name {
            font-size: 14px;
            width: 200px;

            .search-select {
                background: #368686;
                line-height: 29px;
                &--current-option {
                    text-indent: 12px;
                    font-weight: bold;
                }
                &--options-wrapper {
                    z-index: 1000;
                }
                &--option {
                    &.smart_pos_acct {
                        color: #dac8ff;
                        &:hover, &.focused-option {
                            color: white;
                            background: #6e589a;
                        }
                    }
                } 
            }
        }

        &--smart-pos-switch-interval {
            position: relative;
            display: flex;
            line-height: 29px;
            margin-left: 11px;
            font-weight: bold;

            &--name {
                font-size: 13px;
                color: #d2ecec;
            }

            &--value {
                background: #368686;
                font-size: 13px;
                padding: 0px 8px;
                margin-left: 6px;
                width: 60px;
                color: white;
                border-radius: 1px;
                cursor: text;
                font-weight: bold;
            }
        }

        &--smart-accounts-wrapper {
            position: relative;
            margin-top: 9px;
        }

        &--smart-pos-backup-account {
            display: inline-flex;
            font-weight: bold;
            line-height: 29px;

            label {
                color: white;
                margin-right: 11px;
            }

            .search-select {
                background: #368686;
                width: 200px;
                margin-bottom: 10px;
                font-size: 14px;

                &--current-option {
                    text-indent: 12px;
                    font-weight: bold;
                }
            }
        }

        &--smart-accts-round-robin-switch {
            display: flex;
            align-items: center;
            font-weight: bold;
            line-height: 29px;
            gap: 11px;
        }

        &--venue-account {
            display: flex;
            align-items: center;
            margin-left: 11px;
            >label {
                font-size: 13px;
                color: #d2ecec;
                font-weight: bold;
            }
            &--main {
                margin-left: 6px;
            }
        }
    }

    &--smart-pos-accounts {
        position: relative;
        display: table-row;
        font-weight: bold;

        &--direction, &--list {
            position: relative;
            display: table-cell;
            vertical-align: top;
            line-height: 25px;
        }

        &--selector {
            float: left;
            line-height: 25px !important;
            height: 27px !important;
            margin-right: 10px;
            font-size: 14px !important;
        }

        &--direction {
            font-size: 16px;
            padding-right: 11px;
            &.BUY {
                color: #91e8e8;
            }
            &.SELL {
                color: #e27597;
            }
        }

        &--list {
            max-width: 600px;
        }

        &--item {
            display: flex;
            float: left;
            white-space: nowrap;
            font-size: 14px;
            border: 1px solid #368686;
            margin-right: 6px;
            margin-bottom: 6px;

            &.nil-account {
                color: #e27597;
            }

            >span {
                padding: 0px 9px;
            }

            &--remove-button {
                height: 25px;
                width: 25px;
                font-size: 15px;
                display: block;
                padding: 0;
                background: #368686;
                color: #eee;
                &:hover {
                    color: white;
                    background: #4d9e9e;
                }
                >svg {
                    transform: translateY(2px);
                }
            }
        }

        &--account-selector {
            float: left;
            width: auto;
            margin-bottom: 9px;
            margin-right: 6px;
            
            .search-select {
                &--current-option {
                    font-size: 13px;
                    padding: 0px 9px;
                    background: #043535;
                    color: #ddd;
                    border-radius: 1px;
                    line-height: 27px;

                    &:hover {
                        color: white;
                    }
                }
                &--placeholder {
                    opacity: 1.0;
                }
                &--input-icon {
                    display: none;
                }
                &--empty-message {
                    white-space: nowrap;
                }
            }
        }

        &--copy-button {
            float: left;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 9px;
            background: transparent;
            color: #ddd;
            border-radius: 3px;
            line-height: 23px;
            margin-bottom: 9px;
            border: 2px solid;

            &.BID {
                color: #e27597;
                border-color: #b66b86;

                &:hover {
                    background: #b66b86;
                }
            }

            &.ASK {
                color: #91e8e8;
                border-color: #539d9d;

                &:hover {
                    background: #539d9d;
                }
            }

            &:hover {
                color: white;
            }
        }
    }

    &--name {
        position: relative;
        color: #90b3b3;
        font-weight: bold;
        font-size: 19px;
        border-bottom: 1px solid;
        line-height: 40px;
        margin-top: 16px;
        text-indent: 0px;

        >span {
            width: 156px;
            display: block;
            float: left;
            text-indent: 26px;
        }
    }

    &--name-input {
        color: #eee;
        font-size: 16px;
        background: transparent;
        width: calc(100% - 156px);
        font-weight: bold;
        padding: 0;
        cursor: pointer;
        text-indent: 0;
        display: inline-block;
    }

    &--buttons {
        position: relative;
        text-align: center;
        width: 100%;
        padding: 11px 0px;
        z-index: 2;
        background: #063c3c;
        box-shadow: 0px 0px 3px #011716;
    }

    &--save-button, &--reset-button, &--bottom-close-button {
        font-size: 14px;
        background: #5d8cb5;
        padding: 5px 0px;
        width: 96px;
        color: #ddd;
        font-weight: bold;
        border-radius: 2px;
        margin: 0px 5px;

        &:hover {
            color: white;
        }

        &:disabled {
            color: #ddd;
            &:hover {
                cursor: default;
            }
        }
    }

    &--save-button {
        &.is-saved {
            background: #349680;
        }
    }

    &--reset-button, &--bottom-close-button {
        background: #ccc;
        color: #276b6b;

        &:hover {
            color: #428686;
        }
    }

    &--saved-icon {
        margin-right: 4px;
        transform: translateY(2px);
        font-size: 13px;
    }

    &--footer {
        position: relative;
    }

    &--message {
        position: absolute;
        display: inline-flex;
        align-items: center;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%, 0px);
        background: #001716ab;
        color: #eee;
        font-weight: bold;
        font-size: 14px;
        padding: 7px 11px;
        border-radius: 2px;
        max-width: 400px;
        text-align: left;
        word-break: break-word;
        z-index: 1000;
        bottom: 60px;

        >span {
            display: block;
            width: 100%;
        }

        &--close-button {
            background: none;
            color: #ddd;
            font-size: 17px;
            font-weight: bold;
            padding: 0;
            margin-left: 5px;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(3px);
            }
        }
    }

    &--settings {
        position: relative;
        margin-top: 10px;

        &--title {
            position: relative;
            color: #90b3b3;
            font-weight: bold;
            font-size: 19px;
            text-indent: 26px;
            border-bottom: 1px solid;
            line-height: 40px;
            cursor: pointer;

            &:hover {
                background: #105758;
            }

            >svg {
                position: absolute;
                font-size: 22px;
                right: 26px;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 2px;
            }
        }

        &--parameters {
            display: table;
            padding: 9px 26px;
            animation: slideDown 300ms;
        }

        &--parameter-row {
            display: table-row;
        }

        &--parameter-name, &--parameter-value {
            display: table-cell;
            padding: 4px 0px;
            vertical-align: middle;
        }

        &--parameter-name {
            font-size: 14px;
            font-weight: bold;
            color: white;

            >span {
                &.has-description {
                    border-bottom: 1px dashed #90b3b3;
                }
            }
        }

        &--parameter-description {
            max-width: 240px !important;
            font-size: 12px;
            background: #87a2a2ed;
            color: #072a2b;
            box-shadow: 0px 0px 2px #032525;
            border-radius: 2px;
            padding: 5px 7px;
            line-height: 17px;
        }

        &--parameter-value {
            padding-left: 30px;
            >input {
                cursor: text;
                height: 25px;
                padding: 0px 6px;
                font-size: 14px;
                color: #eee;
                background: #368686;
                font-weight: bold;
                border-radius: 1px;
                width: 69px;
            }
            .toggle {
                height: 25px;
                &--label {
                    line-height: 25px;
                }

                &--button {
                    height: 21px;
                    width: 21px;
                }

                &--track {
                    width: 43px;
                }
            }

            &--search-select {
                display: inline-block;
                width: auto;
            }

            .search-select {
                background: #368686;
                font-size: 14px;
                font-weight: bold;
                &--current-option {
                    line-height: 25px;
                    padding: 0px 6px;
                    padding-right: 30px;
                }
                &--input-icon {
                    right: 6px;
                }
            }

            &--string-input {
                min-width: 125px;
            }

            &--array-of-string-input {
                max-width: 400px;
                width: auto;
                background: #023536;

                .array-of-string-input {
                    &--value {
                        background: #498485;
                        border-color: #001112;
                    }
                }

                >input {
                    background: #0b2526;
                }
            }
        }

        &--others {
            margin: 0px 26px;
            animation: slideDown 300ms;
        }

        &--symbol-multiple-selector-trigger {
            font-size: 14px;
            font-weight: bold;
            line-height: 25px;
            padding: 0px 6px;
            background: #368686;
            cursor: pointer;
            max-width: 340px;

            &.disabled {
                cursor: not-allowed;
            }
        }

        &--disabled-popup {
            max-width: 205px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            color: #072a2b;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
        }
    }
}

.profile-container {

    &--main {
        position: absolute;
        top: 32px;
        left: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        right: 0;
    }

    &--groups {
        position: sticky;
        width: 100%;
        top: 0;
        height: 32px;
        @include background-color-3;

        ::-webkit-scrollbar{
            height: 0px;
        }

        &--save-button {
            right: 2px;
            position: absolute;
            top: 2px;
            padding: 5px 9px;
            font-size: 13px;
            background: #bfb462;
            font-weight: bold;
            color: #6d631d;
            border-radius: 2px;

            &:hover {
                color: white;
            }
        }
    }

    &--sortable-group-tabs {
        position: absolute;
        height: 100%;
        left: 135px;
        right: 0px;
        top: 0px;
        display: flex;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;

        .profile-container--group-tab {
            border-left: 1px solid black;
        }
    }

    &--group-tab {
        position: relative;
        height: 100%;
        @include background-color-4;
        color: #eee;
        font-size: 14px;
        padding: 0px 15px;
        font-weight: bold;
        float: left;
        cursor: pointer;
        max-width: 135px;

        &.all-profile-group {
            width: 105px;
            text-align: center;
        }

        >span {
            display: block;
            position: relative;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            top: 50%;
            transform: translateY(-50%);
        }

        &.is-focused {
            @include background-color-5;
            color: white;
        }

        &:hover {
            @include background-color-5;
        }
    }

    &--add-group-button {
        position: sticky;
        right: 0;
        height: 100%;
        @include background-color-6;
        color: #ddd;
        font-size: 18px;
        padding: 0;
        width: 32px;

        >svg {
            transform: translateY(2px);
        }

        &:hover {
            color: white;
        }
    }

    &--header {
        border-bottom: 1px solid #2d6c6c;
        padding: 16px 20px 4px;

        &--row-1 {
            white-space: nowrap;
            display: flex;
        }

        &--search-input {
            float: left;
            width: 250px;
            display: block;
            padding: 0px 13px;
            background: transparent;
            height: 32px;
            font-size: 14px;
            color: #eee;
            border: 2px solid;
            @include border-color-5;
            border-radius: 4px;
            cursor: text;
            font-weight: bold;
    
            &:focus {
                @include background-color-4;
                @include border-color-4;
            }
        }

        &--new-profile-button, &--edit-group-button, &--bulk-upload-button, &--switch-control-button, &--create-tmp-group-button, &--accounts-manager-button {
            float: left;
            height: 36px;
            width: 120px;
            padding: 0px;
            border-radius: 3px;
            background: transparent;
            color: #4cbbbb;
            font-size: 14px;
            font-weight: bold;
            margin-left: 12px;
            border: 2px solid #277e80;
            transition: background 0.1s, color 0.1s;
    
            &:hover:not([disabled]) {
                color: white;
            }
        }
    
        &--edit-group-button {
            color: #7ba8d0;
            border-color: #5d8cb5;

            &:hover {
                background: #5d8cb5;
            }
        }

        &--bulk-upload-button {
            color: #c290ca;
            border-color: #926798;

            &:hover {
                background: #926798;
            }
        }

        &--switch-control-button {
            color: #fb8c93;
            border-color: #e27e85;

            &:hover {
                background: #e27e85;
                color: white !important;
            }
        }

        &--create-tmp-group-button {
            width: 140px;
            color: #e2cb6a;
            border-color: #e2cb6a;

            &:hover {
                background: #e2cb6a;
                color: #094a4a !important;
            }
        }

        &--accounts-manager-button {
            color: #8bc18b;
            border-color: #8bc18b;

            &:hover {
                background: #8bc18b;
                color: white !important;
            }
        }

        &--row-2 {
            font-size: 13px;
            color: #bfd0d0;
            font-weight: bold;
            line-height: 25px;
            margin-top: 7px;
        }

        &--collapse-all-button, &--expand-all-button{
            height: 20px;
            position: relative;
            float: right;
            background: none;
            color: #bfd0d0;
            padding: 0px;
            margin-right: 6px;
            opacity: 0.7;
            font-size: 12px;
            font-weight: bold;
    
            &:hover {
                opacity: 1.0;
            }
    
            svg {
                margin-left: 3px;
                font-size: 17px;
                transform: translateY(4px);
            }
        }
    }

    &--empty-wrapper {
        margin-top: 21px;
        text-align: center;
        color: #ddd;
    }

    &--empty-icon {
        font-size: 44px;
        color: #4e8e8e;
    }

    &--empty-title {
        font-weight: bold;
        font-size: 22px;
        margin-top: 7px;
    }

    &--empty-description {
        font-size: 15px;
        margin-top: 8px;
        color: #a2c5c5;
    }

    &--portfolio-filter, &--status-filter, &--sort-by-wrapper, &--sort-order {
        display: flex;
        float: left;
        white-space: nowrap;

        >span {
            opacity: 0.7;
        }

        .search-select {
            margin-left: 9px;

            &--current-option {
                padding-right: 19px;
            }

            &--input-icon {
                right: 0;
                font-size: 15px;
                opacity: 0.7;
            }

            &--option {
                text-align: left;
                position: relative;
                font-size: 12px;
                font-weight: bold;
                background: #227173ed;
                color: #ddd;
                border-bottom: 1px solid #053333;
                padding: 6px 9px;
                line-height: 15px;
                white-space: pre;

                &:hover {
                    background: #3d9194;
                    color: white;
                }
            }

            &--options-wrapper {
                right: 0px;
                z-index: 2;
            }
        }
    }

    &--status-filter {
        margin-left: 19px;
    }

    &--sort-by-wrapper {
        margin-left: 19px;
    }

    &--sort-order {
        margin-left: 9px;
        margin-top: 3px;
        border: 1px solid #267f80;
        border-radius: 2px;

        &--button {
            height: 19px;
            width: 45px;
            font-size: 12px;
            background: none;
            font-weight: bold;
            color: #789c9c;

            &.active {
                background: #267f80;
                color: #eee;
            }

            &:hover {
                color: white;
            }
        }
    }

    &--snapshot {
        float: left;
        margin-left: 19px;
        >span {
            opacity: 0.7;
        }

        &--refresh-button {
            color:#79c5c5;
            background: transparent;
            width: 23px;
            height: 23px;
            padding: 0;
            margin-left: 3px;
            font-size: 13px;
            transition: color .2s, background .2s;
            border-radius: 2px;
            
            &:hover {
                color: #eee;
                background: #257e80;
            }

            >svg {
                transform: translateY(2px);
            }
        }
    }
}

.strategy-configurator {
    position: relative;
    display: table;

    input {
        cursor: text;
    }

    &--parameter {
        position: relative;
        display: table-row;
    }

    &--parameter-name, &--parameter-value {
        position: relative;
        display: table-cell;
        padding: 5px 0px;
        font-size: 13px;
        vertical-align: middle;
    }

    &--parameter-name {
        padding-right: 30px;
        >span {
            &.has-description {
                border-bottom: 1px dotted #90b3b3;
            }
        }
    }

    &--number-input {
        background: #6b9494;
        padding: 0px 8px;
        font-size: 13px;
        border-radius: 1px;
        color: white;
        font-weight: bold;
        height: 28px;
        margin-left: 0px;
        width: 83px;
    }

    &--array {
        display: flex;
    }

    &--array-buy, &--array-sell {
        display: flex;
        width: 50%;
        
        >label {
            margin-right: 8px;
            line-height: 28px;
        }
    }

    &--array-buy {
        margin-right: 30px;
        width: 50%;
        >label {
            color: #88dada;
        }
    }

    &--array-sell {
        >label {
            color: #ea89a3;
        }
    }

    &--toggle-params-button {
        position: absolute;
        left: 111px;
        top: -30px;
        padding: 0px;
        background: none;
        color: #c4d6d6;
        font-size: 12px;

        &:hover {
            color: #e4f3f3;
        }
    }

    &--parameter-description {
        max-width: 300px;
        display: block;
        font-size: 13px;
        background: #053333ed;
        padding: 6px 8px;
        border-radius: 2px;
        box-shadow: 0px 0px 1px black;
        word-spacing: 1px;
        line-height: 19px;
        color: #d8ecec;

        &::after {
            content: '';
            position: absolute;
            left: 9px;
            top: 100%;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #053333ed;
            clear: both;
        }
    }
}

.profile-item {
    position: relative;
    overflow: visible;
    border-radius: 2px;

    ::-webkit-scrollbar {
        height: 9px;
        background-color: #023b3b;
        border-radius: 5px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #398484;
    }

    &--header {
        position: relative;
        color: white;
        font-weight: bold;
        line-height: 35px;

        &--left {
            float: left;

            &--main {
                display: flex;
                align-items: center;
            }

            &--timestamps {
                display: flex;
                line-height: 21px;
                transform: translateY(-7px);
            }
        }

        &--state-row {
            display: flex;
            line-height: 21px;
            transform: translateY(-5px);
        }

        &--timestamp {
            &:not(:first-child) {
                margin-left: 15px;
            }
            color: #ddd;
            font-size: 13px;

            >span {
                margin-right: 5px;
                color: #c7d7d8;
                font-weight: normal;
                font-size: 12px;
            }
        }

        &--right {
            position: absolute;
            display: flex;
            height: 35px;
            right: 0px;
            bottom: 0px;
        }

        &--log-level {
            margin-right: 6px;
            margin-left: 2px;
        }
    }

    &--running-mode, &--peer-profile {
        margin-left: 10px;
        display: flex;
        align-items: center;
        font-size: 13px;

        >label {
            margin-right: 5px;
            opacity: 0.7;
        }
    }

    &--peer-profile {
        >span {
            text-decoration: underline;
            text-underline-offset: 2px;
            cursor: pointer;
            opacity: 0.8;
            
            &:hover {
                opacity: 1.0;
            }
        }
    }

    &--running-mode {
        margin-left: 15px;
        >span {
            line-height: 23px;
            padding: 0px 6px;
            @include background-color-3;
            border-radius: 2px;

            &.QUOTE {
                background: #bfb462;
                color: #4f4816;
            }

            &.HEDGE {
                background: #3ca7a9;
                color: #0a3738;
            }

            &.LSP_HEDGE {
                background: #9bc088;
                color: #101e08;
            }
        }
    }

    &--managers {
        margin-left: 9px;
    }

    &--status {
        position: relative;
        margin-right: 15px;

        &--bulb {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-shadow: 0px 0px 3px #000000;

            &.started, &.resumed, &.loop-on {
                background: #4dbbbb;
            }

            &.stopped, &.paused, &.loop-off {
                background: #ce757c;
            }

            &.starting, &.stopping, &.resuming, &.pausing {
                background: #d2b775;
            }
        }

        &--label {
            margin-left: 15px;
            font-size: 12px;
            color: #ddd;

            >span {
                opacity: 0.7;
            }
        }

        &.crashed-status {
            color: #dc7479;

            >svg {
                position: absolute;
                top: 2px;
                font-size: 17px;
            }

            .profile-item--status--label {
                margin-left: 21px;
                color: inherit;
            }
        }
    }
    
    &--name {
        float: left;
        color: #eee;
        font-size: 15px;
        user-select: all;
    }

    &--user-hostname {
        font-size: 12px;
        position: relative;
        margin-left: 15px;
        color: #c7d7d8;
        font-weight: normal;
    }

    &--view-configuration {
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;
        overflow: hidden;
    
        &--trigger {
            background: none;
            color: #e9f1f1;
            opacity: 0.7;
            width: 30px;
            padding: 0;
            font-size: 21px;
            margin-right: 2px;
    
            &:hover {
                opacity: 1.0;
            }
    
            >svg {
                transform: translateY(4px);
            }
        }
    }

    &--actions {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 0px 5px #032524;
        margin-right: 15px;

        &--button {
            text-align: left;
            position: relative;
            font-size: 12px;
            font-weight: bold;
            background: #227173ed;
            color: #ddd;
            border-bottom: 1px solid #053333;
            padding: 6px 9px;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background: #3d9194;
                color: white;
            }

            >svg {
                margin-right: 5px;
                transform: translateY(1px);
            }
        }
    }

    &--action-trigger-button, &--tune-button, &--toggle-expand-button, 
    &--timer-popup--trigger-button, &--copy-button, &--fill-popup--trigger-button, &--fill-statistics-popup--trigger-button {
        position: relative;
        float: left;
        height: 100%;
        padding: 0;
        width: 30px;
        font-size: 26px;
        background: none;
        color: #e9f1f1;
        opacity: 0.7;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            margin-top: 7px;
        }
    }


    &--action-trigger-button {
        margin-right: 2px;
        font-size: 21px;
    }

    &--timer-popup--trigger-button {
        margin-left: 1px;
    }

    &--fill-popup--trigger-button {
        font-size: 23px;
        transform: rotate(45deg);
    }

    &--fill-statistics-popup--trigger-button {
        font-size: 16px;
        margin-right: 2px;
    }

    &--copy-button {
        font-size: 17px;
        margin-right: 2px;
    }

    &--tune-button {
        font-size: 19px;
        >svg {
            margin-top: 6px;
        }
    }

    &--toggle-expand-button {
        >svg {
            margin-top: 6px;
        }
    }

    &--timer-popup {
        box-shadow: 0px 0px 5px #000606;
        border-radius: 3px;
        overflow: hidden;
        max-height: 80%;
        overflow-y: auto;

        &--trigger-button {
            font-size: 20px;
            transform: translate(-1px, -0.5px);
        }

        &--item {
            background: #023535ed;
            padding: 11px 13px 5px;
            border-bottom: 1px solid #010c0c;
            width: 244px;
            cursor: pointer;

            &:hover {
                background: #034040f2;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &--main {
        position: relative;
        overflow-x: auto;
        padding-bottom: 11px;
    }

    &--top {
        width: 100%;
    }

    &--bottom {
        display: flex;
        position: relative;
        margin-top: 9px;
    }

    &--quote-symbol-table, &--hedge-symbol-table {
        position: relative;
        width: 100%;
    }

    &--state, &--accounts {
        position: relative;
        margin-left: 7px;
    }

    &--state-header {
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        font-weight: bold;
        padding: 0px 6px;
    }

    &--state-header {
        background: #5d8cb5;
    }

    &--info {
        width: 50%;
        display: flex;
    }

    &--accounts {
        width: 100%;
    }

    &--state-main {
        position: relative;
        display: table;
        padding: 2px 0px;
    }

    &--state-row {
        display: table-row;

        &.exposure-in-usdt {
            line-height: 11px;
            .profile-item--state-row-name, .profile-item--state-row-value {
                padding-bottom: 3px !important;
            }
        }
    }

    &--state-row-name, &--state-row-value {
        display: table-cell;
        white-space: nowrap;
        font-size: 12px;
        padding: 1px 2px;
        color: #d7e8e8;
    }

    &--state-row-value {
        padding-left: 8px;
        text-align: right;
        font-weight: bold;
    }

    &--buttons {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 11px;
        overflow: hidden;
        white-space: nowrap;

        &.show {
            transition: height 0.1s, opacity 0.3s, margin-top 0.1s;
            height: 25px;
            opacity: 1.0;
            margin-top: 11px;
        }

        &.hide, &.is-saved {
            transition: height 0.3s, opacity 0.1s, margin-top 0.1s;
            height: 0;
            opacity: 0;
            margin-top: 0;
        }

        &.is-saved {
            transition-delay: 1.5s;
        }
    }

    &--message {
        float: right;
        font-size: 13px;
        color: #c7d7d8;
        line-height: 23px;
        margin-right: 3px;
        font-weight: bold;
        white-space: nowrap;
    }

    &--button {
        width: 80px;
        min-width: 80px;
        font-size: 12px;
        padding: 5px 0px;
        margin-left: 10px;
        border-radius: 1px;
        font-weight: bold;
        float: right;

        &.reset-button {
            background: #ddd;
            color: #116161;
            &:hover {
                color: #447f80;
            }
        }
    }

    &--mini-table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        font-size: 13px;
        text-align: left;
        font-weight: bold;

        thead {
            font-weight: bold;
            background: #023b3b;
            color: #ddd;
            line-height: 15px;

            th {
                padding: 5px 12px;
            }
        }

        tbody {
            td {
                padding: 3px 12px;
                vertical-align: top;

                &:first-child {
                    width: 50%;
                }
            }
        }

        &--symbol-position {
            display: table-row;

            &--symbol-name, &--position {
                display: table-cell;
                color: #c7d8d8;
                padding: 2px 0px;
            }

            &--position {
                padding-left: 35px;
                width: 65%;
                white-space: nowrap;

                &.positive {
                    color: #8fe6e8;
                }
        
                &.negative {
                    color: #fd818a;
                }
            }
        }
    }

    &--fill-popup {
        overflow: hidden;
        display: inline-flex;
    }

    &--fill-statistics-popup {
        overflow: hidden;
        display: inline-flex;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;
    }
}

.quote-symbol-table, .hedge-symbol-table {
    position: relative;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    &--header {
        &.symbols {
            position: sticky;
            left: 0px;
            background: #023b3b;
            z-index: 1;
            text-align: left;
        }
    }

    &--header-popup {
        font-size: 12px;
        background: #307979d9;
        padding: 1px 10px;
        box-shadow: 0px 0px 2px #063838;
        border-radius: 3px;
        color: #eee;
        line-height: 17px;
        max-width: 200px !important;

        >div {
            margin: 5px 0px;
        }

        &.right {
            &::after {
                right: 9px;
            }
        }

        &.left {
            &::after {
                left: 9px;
            }
        }
    }

    &--header-text-with-description {
        border-bottom: 1px dotted;
    }

    thead {
        font-size: 12px;
        font-weight: bold;
        background: #023b3b;
        color: #ddd;
        line-height: 15px;

        th {
            padding: 5px;
            white-space: nowrap;
        }
    }

    tbody {
        font-size: 13px;
        font-weight: bold;
        color: #c7d8d8;

        input {
            cursor: text;
            font-size: 13px;
            width: 9ch;
            padding: 1px 6px;
            background: #447f80;
            color: white;
            font-weight: bold;
            border-radius: 1px;
        }            
        tr {
            &:nth-child(2n+3) {
                border-top: 1px solid #023b3b;
            }
        }

        td {
            padding: 4px 5px;
            white-space: nowrap;
        }
    }

    &--symbol-name {
        position: sticky;
        left: 0px;
        background: #0a4a4a;
        z-index: 1;

        &--position-notional-sum {
            >span {
                margin-right: 4px;
                &.positive {
                    color: #8fe6e8;
                }
                &.negative {
                    color: #fd818a;
                }
            }
        }
    }

    &--direction-label, &--symbol-switch, &--symbol-reduce-only-switch, &--pricing-data, &--ref-pricing-data, &--spread-data, &--ref-spread-data, &--position-data, &--open-position {
        &.BUY {
            border-bottom: 1px dashed #023b3b;
            padding-top: 6px;
        }
        &.SELL {
            padding-bottom: 8px;
        }
    }

    &--spread-data {
        &--input {
            &.warning {
                background: #cb747a;
            }
        }
    }

    &--position-data {
        &--max-side-pos-input.warning-red {
            background: #cb747a;
        }
        &--pos-notional-popup {
            background: #096363;
            font-size: 14px;
            font-weight: bold;
            padding: 5px 8px;
            box-shadow: 0px 0px 3px black;
            border-radius: 2px;
        }
        &--vpos {
            display: inline-flex;
            flex-direction: column;
            
            &.has-notional {
                height: 15px;
                line-height: 15px;
                transform: translateY(-50%);
            }
        }
    }

    &--header-slash-symbol {
        margin: 0px 5px;
        color: #adcfd0;
    }

    &--pricing-direction-label {
        display: inline-block;
        text-align: right;
        width: 21px;
        margin-right: 6px;
        font-size: 12px;
        text-transform: capitalize;

        &.bid {
            color: #99ddde;
        }

        &.ask {
            color: #ce757c;
        }
    }

    &--data-slash-symbol {
        position: relative;
        margin: 0px 5px;
        color: #518f90;
    }

    &--direction-label {
        &.BUY {
            color: #99ddde;
        }

        &.SELL {
            color: #ce757c;
        }
    }

    &--net-position-value {
        text-align: center !important;
        &.positive {
            color: #8fe6e8;
        }

        &.negative {
            color: #fd818a;
        }

        &--notional {
            color: #c7d8d8;
        }
    }

    &--ref-product {
        font-size: 12px;
        margin-top: 7px;
        color: #9eb7b7;

        >span {
            background: #267f80;
            color: #ddd;
            padding: 1px 5px;
            margin-right: 5px;
            position: relative;
            border-radius: 2px;
        }
    }

    &--funding-block {
        padding: 3px 0px; 
        font-size: 12px;

        &:first-child {
            padding-top: 0px;
        }
        &:last-child {
            padding-bottom: 0px;
        }

        &--time {
            opacity: 0.9;
        }

        &--value {
            line-height: 11px;
            &.positive {
                color: #8fe6e8;
            }
            &.negative {
                color: #fd818a;
            }
        }
    }
}

.quote-symbol-table {

    &--fx-symbol {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        font-size: 12px;
    
        >label {
            color: #86c1c1;
        }
        >span {
            opacity: 0.8;
        }
    }
    &--pricing-data, &--ref-pricing-data {
        text-align: left;

        &--section {
            display: inline-block;
            text-align: left;
            width: auto;
        }
    }

    &--pricing-data {
        .adjusted-theory-price {
            >span {
                &.highlight {
                    padding: 0px 5px;
                    border: 2px dashed #e3cb69;
                }
            }
        }
    }

    thead {
        th {
            text-align: left;

            &:first-child {
                padding-left: 9px;
            }

            &:last-child {
                padding-right: 9px;
            }
        }
    }

    tbody {
        td {
            text-align: left;
        }
    }

    &--header {
        text-align: left;

        &.spread, &.ref-spread, &.positions, &.open-position {
            text-align: right;
        }
        &.net-position, &.adjusted-spread-threshold-delta {
            text-align: center;
        }
    }

    &--spread-data, &--ref-spread-data, &--position-data, &--open-position {
        text-align: right !important;
    }

    &--adjusted-spread-threshold-delta {
        text-align: center !important;
    }

    &--ref-symbols, &--ref-pricing-data, &--ref-spread-data {
        &--color-dot {
            position: relative;
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 4px;
            border-radius: 9px;
            box-shadow: 0px 0px 2px black;
        }
    }

    &--ref-symbols {
        margin-top: 3px;
        font-size: 12px;

        &--label {
            border-bottom: 1px dashed;
            color: #86c1c1;
            display: inline-block;
        }
        &--symbol-name {
            color: #9eb7b7;
        }

        &--color-dot {
            transform: translateY(1px);
        }
    }

    &--ref-pricing-data, &--ref-spread-data {
        &--item:not(:last-child) {
            padding-bottom: 2px;
        }
    }
}

.hedge-symbol-table {
    &--header {
        &:first-child {
            padding-left: 9px;
        }
        &.symbols, &.direction, &.switch, &.reduce-only-switch, &.funding-rate, &.liquidation {
            text-align: left;
        }

        &.positions, &.open-position {
            text-align: right;
        }
    }

    &--symbol-name, &--direction-label, &--symbol-switch, &--funding-rate, &--liquidation {
        text-align: left;
    }

    &--position-data, &--open-position {
        text-align: right !important;
    }
}

.profile-liquidations {
    &--item {
        font-size: 12px;
        text-align: left;
        &--account-name {
            opacity: 0.8;
        }
        &--liquidation-price {
            display: block;
        }
        &--risk-ratio {
            display: block;
            color: #e2cb6a;
        }
    }

    &--popup {
        position: relative;
        @include background-color-11;
        font-weight: bold;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;
        overflow: auto;

        &--trigger {
            background: none;
            font-size: 13px;
            font-weight: bold;
            color: #c7d8d8;
            text-decoration: underline dotted;

            &:hover {
                color: white;
            }
        }

        .profile-liquidations--item {
            font-size: 13px;
            padding: 5px 10px;

            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-2;
            }
        }
    }
}

.profile-group-editor {
    position: absolute;
    width: 100%;
    max-width: 480px;
    color: white;
    background: #094a4a;
    padding: 21px 0px 31px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0px 0px 7px #021313;

    &--close-button {
        position: absolute;
        right: 20px;
        background: none;
        color: #eee;
        font-size: 20px;
        z-index: 1;
        top: 25px;

        &:hover {
            color: white;
        }
    }

    &--title {
        position: relative;
        font-size: 23px;
        font-weight: bold;
        color: #eee;
        text-indent: 26px;
    }

    &--main {
        position: relative;
        margin-top: 12px;
    }

    &--content-block {
        padding: 9px 26px;
    }

    &--content-block-title {
        color: #90b3b3;
        font-size: 14px;
        font-weight: bold;
    }

    &--content-block-main {
        position: relative;
        margin-top: 5px;
    }

    &--name-input {
        cursor: pointer;
        display: block;
        width: 100%;
        background: none;
        border-bottom: 1px solid #618d8d;
        font-size: 16px;
        color: white;
        font-weight: bold;
        padding-bottom: 3px;
    }

    &--profile-selector {
        position: relative;
        padding-top: 4px;

        &--selected-groups {
            border: 1px solid #608d8c;
            padding: 4px;
            cursor: pointer;
            max-height: 196px;
            overflow-x: hidden;
        }
    
        &--selected-group {
            display: inline-block;
            font-size: 13px;
            background: #247575;
            margin: 4px;
            padding: 3px 6px;
            padding-right: 31px;
            position: relative;
            line-height: 19px;
            font-weight: bold;
            color: #ddd;
            cursor: default;
        }
    
        &--search-input {
            display: inline-block;
            height: 25px;
            background: none;
            cursor: pointer;
            font-size: 13px;
            color: #ddd;
            font-weight: bold;
            padding: 0px 6px;
        }

        &--remove-button {
            position: absolute;
            height: 100%;
            top: 0px;
            right: 0px;
            width: 25px;
            font-size: 15px;
            padding: 0;
            color: #ddd;
            background: #236161;

            &:hover {
                color: white;
            }

            svg {
                transform: translateY(2px);
            }
        }

        &--profile-list {
            position: relative;
            margin-top: 11px;
            height: 210px;
            overflow-y: auto;
        }

        &--profile-item {
            position: relative;
            padding: 7px 11px;
            border-bottom: 1px solid #063838;
            cursor: pointer;

            &:last-child {
                border: none;
            }
            &:hover {
                background: #073e3e;
            }
        }

        &--profile-item-name {
            font-size: 14px;
            font-weight: bold;
            white-space: normal;
            overflow: hidden;
        }

        &--profile-item-hostname {
            font-size: 13px;
            font-weight: normal;
            white-space: normal;
            overflow: hidden;
            opacity: 0.8;
            margin-left: 15px;
        }

        &--profile-item-selected-icon {
            position: absolute;
            right: 11px;
            font-size: 12px;
            color: #83adab;
        }
    }

    &--buttons {
        margin-top: 26px;
        text-align: center;
    }

    &--delete-button, &--save-button {
        position: relative;
        display: initial;
        width: 90px;
        padding: 7px 0px;
        font-size: 13px;
        font-weight: bold;
        background: #5d8cb5;
        color: #ddd;
        border-radius: 1px;
        margin: 0px 7px;
        border-radius: 2px;

        &:hover {
            color: white;
        }
    }

    &--delete-button {
        background: #c7586c
    }

    &--message {
        position: relative;
        display: inline-block;
        left: 50%;
        transform: translate(-50%, 13px);
        font-size: 12px;
        background: #063a3a;
        padding: 5px 14px;
        max-width: 80%;
    }

    &--delete-modal {
        position: absolute;

        &--buttons {
            position: relative;
            text-align: center;
            margin-top: 39px;
        }

        &--message {
            font-size: 18px;
            color: #b6d8d8;
        }

        &--cancel-button, &--delete-button {
            position: relative;
            margin: 0px 9px;
            width: 110px;
            font-size: 13px;
            padding: 8px 0px;
            font-weight: bold;
        }

        &--cancel-button {
            background: #eee;
            color: #237575;

            &:hover {
                color: #339090;
            }
        }

        &--delete-button {
            background: #c7586c;
            color: #eee;

            &:hover {
                color: white;
            }
        }
    }
}

.profile-accounts {

    &:hover {
        .profile-accounts--enable-edit-button {
            visibility: visible;
        }
    }

    &--title {
        position: relative;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 700;
        padding: 0 6px;
        background: #936798;
    }

    &--enable-edit-button, &--cancle-edit-button {
        position: absolute;
        right: 5px;
        background: none;
        font-size: 12px;
        font-weight: bold;
        border: 1px solid;
        height: 18px;
        padding: 0px 7px;
        border-radius: 1px;
        opacity: 0.7;

        &:hover {
            opacity: 1.0;
        }
    }

    &--enable-edit-button {
        color: #3d1342;
        visibility: hidden;

        &.is-disabled {
            cursor: not-allowed;
        }

        &--disabled-popup {
            max-width: 205px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            color: #072a2b;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
        }
    }

    &--cancle-edit-button {
        color: #eee;
    }

    &--table {
        position: relative;
        width: 100%;

        td {
            white-space: nowrap;
            font-size: 12px;
            padding: 1px 2px;
            color: #d7e8e8;
            vertical-align: top;
        }
    }

    &--account-name {
        text-align: right;
        font-weight: bold;

        .search-select {
            &--current-option {
                &--name {
                    border-bottom: 1px dotted #8babab;
                }
                &:hover {
                    color: white;
                    .search-select--current-option--name {
                        border-bottom: 1px dotted white;
                    }
                }
            }
            &--input-icon {
                display: none;
            }

            &--options-wrapper {
                right: 0;
            }

            &--options {
                max-height: 94px;
                text-align: left;
            }
        }
    }

    &--smart-pos-accounts-popup {
        @include background-color-11;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        overflow: auto;

        &--trigger {
            color: #c698cc;
            cursor: pointer;
            text-decoration: underline dotted;

            &:hover {
                text-decoration: underline solid;
            }
        }

        &--main {
            &--message {
                padding: 7px 11px;
                font-size: 14px;
                font-weight: bold;
                color: #e8ce8d;
            }
        }
    }
    
    &--smart-pos-accounts {
        position: relative;

        &--single-side {
            position: relative;
            font-weight: bold;
            display: flex;
            font-size: 14px;
            border-bottom: 1px solid;
            @include border-color-3;
            padding: 7px 11px 3px;

            &--name {
                position: relative;
                text-align: left;
                min-width: 55px;
                line-height: 21px;

                &.BUY {
                    color: #8fe6e8;
                }

                &.SELL {
                    color: #fd818a;
                }
            }

            &--account-names {
                position: relative;
                max-width: 520px;
            }

            &--account-name {
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                margin-bottom: 4px;
                border: 2px solid transparent;
                color: #d5e6e6;
                line-height: 17px;

                &.selected {
                    border: 2px dashed #e3cb69;
                    padding: 0px 4px;
                    border-radius: 2px;
                }
            }
        }

        &--backup {
            font-size: 14px;
            font-weight: bold;
            display: flex;
            padding: 5px 11px;

            >label {
                min-width: 55px;
                line-height: 21px;
            }

            &--name {
                line-height: 17px;
                border: 2px solid transparent;
                color: #d5e6e6;

                &.selected {
                    border: 2px dashed #e3cb69;
                    padding: 0px 4px;
                    border-radius: 2px;
                    display: inline-block;
                    vertical-align: top;
                }
            }

            &--selected-message {
                display: block;
                font-size: 14px;
                color: #e3cb69;
                margin-top: 2px;
            }
        }

        &--round-robin-switch {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            padding: 0px 11px 5px;
            gap: 10px;

            >label {
                opacity: 0.7;
            }
        }
    }

    &--venue-account {
        margin-top: 3px;
        >label {
            opacity: 0.7;
        }
    }
}

.profile-copy-popup {
    @include background-color-11;
    box-shadow: 0px 0px 3px black;
    padding: 9px 15px 11px;
    border-radius: 3px;
    font-weight: bold;

    &--title {
        font-size: 17px;
        color: white;
    }

    &--main {
        position: relative;
        margin-top: 5px;
    }

    &--row {
        display: table-row;

        &--label, &--main {
            display: table-cell;
            padding: 7px 0px 3px;
        }

        &--label {
            font-size: 12px;
            color: #ddd;
            padding-right: 15px;
        }

        &--main {
            font-size: 14px;
            border-bottom: 1px solid #ddd;

            >input {
                font-size: 15px;
                background: none;
                cursor: text;
                color: #eee;
                font-weight: bold;
                width: 180px;
            }

            .search-select {
                &--input-icon {
                    right: 0px;
                }
            }
        }
    }

    &--message {
        font-size: 13px;
        text-align: center;
        margin-top: 12px;
        width: 100%;
        background: #10494a;
        padding: 3px 0px;
        border-radius: 2px;

        >svg {
            transform: scale(1.3) translateY(2px);
            margin-right: 6px;
            color: #a8d7d8;
        }
    }

    &--create-button {
        float: right;
        margin-top: 11px;
        padding: 3px 14px;
        font-size: 13px;
        font-weight: bold;
        color: #eee;
        border-radius: 2px;

        &:hover {
            color: white;
        }
    }
}

.profile-switch-item, .profile-reduce-only-switch-item  {
    display: table-row;

    &:not(:last-child) {
        .profile-switch-item, .profile-reduce-only-switch-item {
            &--account-name {
                padding-bottom: 4px;
            }
        }
    }

    &--account-name {
        display: table-cell;
        padding-right: 7px;
        vertical-align: middle;
        text-align: left;
        line-height: 23px;
        color: #d7eaea;

        &.selected {
            >span, .account-popup--trigger-name {
                border: 2px dashed #e3cb69;
                border-radius: 2px;
                padding: 0px 3px;
            }
        }

        &.nil-account {
            color: #fd818a;
        }
    }

    &--toggle {
        display: flex;

        &--name {
            font-size: 12px;
            line-height: 23px;
            color: #97b3b3;
            margin-right: 7px;
        }

        &--popup {
            max-width: 308px !important;
            padding: 0;

            >span {
                position: relative;
                display: block;
                box-shadow: 0px 0px 3px #012b2b;
                background: #87a2a2ed;
                border-radius: 2px;
                font-size: 13px;
                padding: 3px 7px;
                color: #072a2b;
                word-break: break-all;
            }
        }
    }

    &--postfix {
        display: table-cell;
        vertical-align: middle;
        padding-left: 3px;
        color: #ecd783;
    }

    .toggle {
        height: 19px;
        width: auto;
        display: inline-flex;
        transform: translateY(2px);

        &--button {
            width: 15px;
            height: 15px;
        }

        &--track {
            width: 37px;
            background: #cb747a;
        }

        &--label {
            line-height: 19px;
            text-align: left;
            width: 20px;
        }
    }
}

.profile-switches {
    &--smart-accounts-popup {
        @include background-color-11;
        font-weight: bold;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;
        padding: 5px 8px;
        overflow: auto;

        &--main {
            font-size: 13px;

            .profile-switch-item {
                &:not(:last-child) {
                    padding-bottom: 5px;
                }
            }
        }
    }

    &--smart-pos-account-exception {
        line-height: 15px;
        padding: 5px 0px 3px;
        max-width: 175px;
        white-space: normal;
        color: #d27d83;
    }

    &--smart-pos-account-summary {
        cursor: pointer;
        display: inline-block;
        opacity: 0.8;
        font-size: 12px;
        text-align: left;
        &:hover {
            opacity: 1.0;
        }

        &--accounts, &--invalid-size, &--switch-offs {
            >span {
                color: white;
                margin-right: 4px;
            }
        }
        &--invalid-size, &--switch-offs, &--nil-message {
            border-top: 1px dashed #6eb7b7;
        }
        &--invalid-size {
            color: #ef7e86;
            >span {
                color: #ef7e86;
            }
        }
        &--switch-offs {
            &.has-switch-off {
                color: #e2cb6a;
                >span {
                    color: #fbe587;
                }
            }
        }
        &--nil-message {
            color: #fb8b92;
        }
    }
}

.profile-reduce-only-switches {
    text-align: left;

    &--popup {
        @include background-color-11;
        font-weight: bold;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;
        padding: 5px 8px;
        overflow: auto;

        &--main {
            font-size: 13px;
            display: table;
        }
    }

    .profile-reduce-only-switch-item {
        &:not(:last-child) {
            border-bottom: 1px dashed #023333;
        }
    }

    &--summary {
        cursor: pointer;
        display: inline-block;
        opacity: 0.8;
        font-size: 12px;
        text-align: left;
        &:hover {
            opacity: 1.0;
        }

        &--account-size, &--invalid-size, &--switched-on-size {
            >span {
                color: white;
                margin-right: 4px;
            }
        }

        &--invalid-size, &--switched-on-size {
            border-top: 1px dashed #6eb7b7;
        }

        &--invalid-size {
            color: #ef7e86;
            >span {
                color: #ef7e86;
            }
        }
    }
}

.profile-list {
    &--item {
        &--sort-handler {
            background: none;
            color: #e9f1f1;
            opacity: 0;
            font-size: 13px;
            transition: opacity 0.2s;
            cursor: move;

            &:hover {
                opacity: 1.0 !important;
            }

            >svg {
                transform: translateY(3px) rotate(-45deg);
            }
        }

        &:hover {
            .profile-list--item--sort-handler {
                opacity: 0.7;
            }
        }

        .profile-item {
            background: #0a4a4a;
            padding: 10px 20px 35px;
        }
    }
}

.profile-manual-orders {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    width: 100%;

    &--header {
        position: relative;
        border-bottom: 1px solid #99b3b3;
        color: #c4dcdc;
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
    }

    &--title {
        float: left;
    }

    &--fill-only-filter {
        float: right;
        display: flex;
        font-size: 13px;
        color: #b9caca;

        .checkbox {
            margin-left: 5px;
            width: 17px;
            height: 17px;
            margin-top: 4px;
            line-height: 17px;
        }
    }

    &--body {
        position: relative;
        overflow: auto;
        height: 100%;
    }

    &--item {
        border-bottom: 1px solid #277e80;
        padding: 5px 3px;

        @keyframes showNewItem {
            0% {
                background: #053e40;
            }
            100% {
                background: #043b3c;
            }
        }
        animation: 1.0s showNewItem;

        &--header {
            font-weight: bold;

            &--info-row {
                margin-top: 1px;
            }
        }

        &--status {
            font-size: 13px;
            float: left;

            >span {
                font-size: 12px;
                color: #c4dcdc;
            }

            &.SENT_TO_PF, &.CANCELLED, &.PENDING_CANCEL {
                color: #e2cb6a;
            }
            &.CONFIRM {
                color: #87ce93;
            }
            &.FILL, &.PARTIAL_FILL {
                color: #a6cff3;
            }
            &.REJECTED, &.EXPIRED {
                color: #fd818a;
            }
        }

        &--event-timestamp {
            float: right;
            font-size: 12px;
            color: #c4dcdc;
        }
    
        &--id {
            font-size: 12px;
            opacity: 0.7;
            user-select: all;
        }

        &--reject-reason {
            font-size: 12px;
            line-height: 1.2em;
            padding: 3px 0px;
            color: #e2cb6a;
        }

        &--main {
            font-size: 14px;
            >span {
                margin-right: 6px;
            }
        }

        &--side {
            font-size: 16px;
            font-weight: bold;
            &.BUY, &.BUY_OPEN, &.BUY_CLOSE {
                color: #90eaea;
            }
            &.SELL, &.SELL_OPEN, &.SELL_CLOSE {
                color: #fd818a;
            }
        }

        &--unit, &--price, &--account-name {
            color: white;
            font-weight: bold;
        }

        &--unit {
            &--left {
                color: #e2cb6a;
                font-size: 13px;
                margin-left: 3px;
            }
        }

        &--price {
            &--avg {
                color: #e2cb6a;
                font-size: 13px;
                margin-left: 3px;
            }
        }

        &--tags {
            margin-top: 5px;
        }

        &--tag {
            float: left;
            font-size: 13px;
            margin-right: 5px;
            background: #267f80;
            font-weight: bold;
            padding: 1px 7px;
            border-radius: 2px;

            &.post-only {
                background: #5d8cb5;
            }

            &.leverage {
                background: #936798;
            }

            &.reduce-only, &.cross-margin, &.margin-trade {
                background: #ecb145;
                color: #422b00;
            }

            &.margin-buy {
                background: #b9838c;
                color: #300e13;
            }
            &.auto-repay {
                background: #6eb3b3;
                color: #194646;
            }
        }

        &--notional {
            color: #b0f0f3;
        }

        &--timestamps {
            margin-top: 5px;
        }

        &--timestamp {
            display: flex;
            white-space: nowrap;
            font-size: 12px;
            font-weight: bold;
            color: #c4dcdc;

            &--value {
                width: 100%;
                text-align: right;
            }
        }

        &--cancel-button {
            width: 100%;
            border-radius: 2px;
            padding: 3px 0px;
            background: #936798;
            color: white;
            font-weight: bold;
            margin-top: 3px;
        }
    }
}

.profile-manual-transactions {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    width: 100%;

    &--title {
        position: relative;
        border-bottom: 1px solid #99b3b3;
        color: #c4dcdc;
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
    }
}

.profile-state-snapshot-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        height: 71px;
        min-height: 71px;
        border-bottom: 1px solid #0a4a4a;

        &--title {
            text-align: center;
            font-weight: bold;
            background: #0a4a4a;
            font-size: 15px;
            height: 32px;
            line-height: 32px;
        }

        &--create-snapshot-button {
            height: 25px;
            width: 233px;
            left: 50%;
            position: absolute;
            bottom: 7px;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: bold;
            background: #267e7f;
            color: #dddd;

            &:hover {
                color: white;
            }
        }
    }
    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--item {
        position: relative;
        border-bottom: 1px solid #002928;
        padding: 7px 9px;
        background: #023b3b;
        cursor: pointer;

        &--remove-button {
            position: absolute;
            display: none;
            right: 7px;
            top: 9px;
            font-size: 13px;
            padding: 0;
            width: 18px;
            background: none;
            color: #ddd;

            &:hover {
                color: white;
            }
        }

        &--name {
            font-size: 16px;
            font-weight: bold;
            word-break: break-all;
            margin-right: 25px;
        }

        &--footer {
            font-size: 13px;
            margin-top: 5px;
            font-weight: bold;
            line-height: 17px;
        }

        &--profile-count {
            float: left;
            color: #97caca;
        }

        &--created-time {
            float: right;
            opacity: 0.7;
            font-size: 12px;
        }

        &:hover {
            background: #034848;
            .profile-state-snapshot-container--item--remove-button {
                display: block;
            }
        }
    }

    &--delete-modal {
        &--body {
            position: absolute;
        }

        &--caption {
            font-size: 19px;
            font-weight: bold;
        }

        &--buttons {
            text-align: center;
            margin-top: 25px;
        }

        &--button {
            font-size: 15px;
            font-weight: bold;
            padding: 7px 0px;
            width: 115px;
            margin: 0px 7px;
            border-radius: 1px;

            &.cancel {
                color: #248686;

                &:hover {
                    background: #599a9a;
                    color: white;
                }
            }

            &.delete {
                background: #c7586c;
                color: #eee;

                &:hover {
                    color: white;
                }
            }
        }
    }
}

.profile-state-snapshot-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: auto;
    height: 95%;
    max-width: 100%;
    min-width: 500px;
    color: white;
    background: #094a4a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 7px #021313;
    overflow: visible;

    &--header {
        position: relative;
        padding: 9px 20px;
        border-bottom: 1px solid #90b3b3;

        &--title {
            position: relative;
            float: left;
            font-size: 19px;
            font-weight: 700;
            color: #eee;
        }

        &--close-button {
            background: none;
            font-size: 18px;
            position: absolute;
            right: 13px;
            color: #ddd;
            top: 11px;

            &:hover {
                color: white;
            }
        }

        &--edit-title-popup {

            &--title {
                font-size: 13px;
                border-bottom: 1px solid #90b3b3;
                padding: 4px 7px 2px;
                font-weight: bold;
                color: #c4dada;
            }

            &--body {
                background: #1b6969;
                box-shadow: 0px 0px 2px black;
                border-radius: 2px;
                width: 168px;

                &--main {
                    padding: 3px 7px 7px;
                }
            }

            &--trigger {
                background: none;
                font-size: 16px;
                color: #90b3b3;
                transform: translate(4px, 5px);
    
                &:hover {
                    color: white;
                }
            }

            &--input {
                width: 100%;
                background: none;
                border-bottom: 1px solid #0a4a4a;
                cursor: text;
                font-size: 14px;
                color: white;
                font-weight: bold;
            }

            &--save-button {
                display: block;
                width: 100%;
                margin-top: 7px;
                font-size: 13px;
                border-radius: 1px;
                font-weight: bold;
                padding: 2px 0px;
                background: #7ab3b3;
                color: #eee;

                &:hover {
                    color: white;
                }
            }
        }
    }

    &--body {
        position: relative;
        height: 100%;
    }

    &--table {
        .ReactVirtualized__Table__headerRow {
            background: #032f2f;
            overflow: visible !important;
        }
        &--header {
            text-transform: none;
            display: flex;
            font-size: 13px;
            line-height: 19px;
        }
        &--row {
            font-weight: bold;

            &:nth-child(n+2) {
                border-top: 1px solid #032f30;
            }

            .profile-action-popup--trigger {
                background: none;
                color: #9cd2d2;
                margin-left: 4px;
                opacity: 0.8;
                visibility: hidden;
                >svg {
                    transform: scale(1.5) translateY(1px);
                }

                &:hover {
                    opacity: 1.0;
                }
            }

            &:hover {
                .profile-action-popup--trigger {
                    visibility: visible;
                }
            }
        }

        &--profile-name {
            font-size: 15px;
            color: #cedada;
        }
    }

    &--recover-button {
        margin-left: 11px;
        color: #7cc7c7;
        background: transparent;
        border: 1px solid;
        border-radius: 2px;
        padding: 0px 7px;
        font-weight: bold;
        font-size: 13px;

        &:hover {
            background: #6cb1b1;
            color: #032f2f;
            border-color: #6cb1b1;
        }

        &:disabled {
            border-color: transparent;
            color: #ecda52;
            border-color: transparent;
            background: none;
        }
    }

    &--cell {
        &.current {
            display: flex;
        }
    }

    &--state {
        display: flex;
        font-size: 13px;
    }

    &--state-item {
        position: relative;
        display: flex;
        &:not(:first-child) {
            margin-left: 11px;
        }

        &--mark {
            display: block;
            width: 9px;
            height: 9px;
            margin-right: 5px;
            background: white;
            box-shadow: 0px 0px 2px black;
            border-radius: 50%;
        }

        &.STARTED, &.RESUMED {
            .profile-state-snapshot-modal--state-item--mark {
                background: #4dbbbb;
            }
        }

        &.STOPPED, &.PAUSED {
            .profile-state-snapshot-modal--state-item--mark {
                background: #ce757c;
            }
        }

        &.STARTING, &.STOPPING, &.RESUMING, &.PAUSING {
            .profile-state-snapshot-modal--state-item--mark {
                background: #d2b775;
            }
        }

        &.CRASHED {
            color: #f5838b;
            >svg {
                font-size: 15px;
                transform: translateY(1px);
                margin-right: 3px;
            }
        }
    }
}

.profile-accounts-manager {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 95%;
    background: #094a4a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 7px #021313;

    &--header {
        position: relative;
        padding: 0px 20px;
        border-bottom: 1px solid #90b3b3;
        line-height: 43px;
    }

    &--title {
        position: relative;
        float: left;
        font-size: 19px;
        font-weight: bold;
        color: #eee;
    }

    &--close-button {
        background: none;
        font-size: 18px;
        position: absolute;
        right: 13px;
        color: #ddd;
        top: 11px;

        &:hover {
            color: white;
        }
    }

    &--body {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &--main {
        position: absolute;
        top: 43px;
        bottom: 0px;
        width: 100%;
        display: flex;

        &--left {
            position: relative;
            width: 100%;
        }

        &--right {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 220px;
            min-width: 250px;
            height: 100%;
            overflow: hidden;
            background: #094040;
            border-left: 1px solid #032e2e;
        }
    }

    &--state {
        display: flex;
        font-size: 13px;
        float: left;
    }

    &--state-item {
        position: relative;
        display: flex;
        &:not(:first-child) {
            margin-left: 11px;
        }

        &--mark {
            display: block;
            width: 9px;
            height: 9px;
            margin-right: 5px;
            background: white;
            box-shadow: 0px 0px 2px black;
            border-radius: 50%;
        }

        &.STARTED, &.RESUMED {
            .profile-accounts-manager--state-item--mark {
                background: #4dbbbb;
            }
        }

        &.STOPPED, &.PAUSED {
            .profile-accounts-manager--state-item--mark {
                background: #ce757c;
            }
        }

        &.STARTING, &.STOPPING, &.RESUMING, &.PAUSING {
            .profile-accounts-manager--state-item--mark {
                background: #d2b775;
            }
        }

        &.CRASHED {
            color: #f5838b;
            >svg {
                font-size: 15px;
                transform: translateY(1px);
                margin-right: 3px;
            }
        }
    }

    &--batch-editor-wrapper {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }

    &--batch-editor {
        position: relative;
        padding-bottom: 11px;

        &--title {
            position: sticky;
            top: 0px;
            height: 27px;
            line-height: 27px;
            font-weight: bold;
            text-indent: 9px;
            font-size: 14px;
            background: #011f1f;
            z-index: 2;
        }

        &--body {
            margin-top: 3px;
        }

        &--exchange, &--symbols-to-filter, &--accounts {
            position: relative;
            margin: 5px 9px 21px;

            &--label {
                font-size: 13px;
                font-weight: bold;
                border-bottom: 1px solid #90b3b3;
                line-height: 23px;
                color: #c0e8e8;
            }

            &--body {
                margin-top: 3px;
            }

            .search-select {
                font-weight: bold;

                &--input-icon {
                    opacity: 0.7;
                    right: 7px;
                }

                &--clear-button {
                    right: 7px;
                }
            }
        }

        &--symbols-to-filter {
            &--symbol-selector {
                width: 100%;
                margin-top: 8px;
                height: 27px !important;
                background: #65a0a0 !important;
                border: none !important;

                &:hover {
                    background: #529898 !important;
                }
            }
        }

        &--buttons {
            position: relative;
            margin-top: 15px;
        }

        &--apply-button {
            position: relative;
            width: calc(100% - 18px);
            left: 9px;
            font-size: 14px;
            font-weight: bold;
            padding: 6px 0px;
            border-radius: 2px;
            background: #8bc18b;
            color: #1d3e1d;

            &:disabled {
                background: #ddd;
                color: #094040 !important;
            }

            &:hover {
                color: white;
            }
        }
    }

    &--buttons {
        position: relative;
        bottom: 0;
        width: auto;
        padding: 0px 3px;
        border-top: 1px solid #032e2e;
    }

    &--sync-button, &--reset-button, &--save-button {
        width: calc(33.33% - 6px);
        margin: 6px 3px;
        height: 25px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 2px;
    }

    &--sync-button {
        background: #d2ae53;
        color: #4a3809;

        &:hover {
            color: white;
        }

        &:disabled {
            background: #d0bc8c;
            color: #584924;
        }
    }

    &--reset-button {
        background: #ddd;
        color: #0a4a4a;

        &:hover {
            background: white;
            color: #186b6b;
        }
    }

    &--save-button {
        background: #5d8cb5;
        color: #ddd;
        &:hover {
            color: white;
        }
        &:disabled {
            background: #89a5bd;
            color: white;
        }
    }

    &--table {
        .ReactVirtualized__Table__headerRow {
            background: #032f2f;
            &:hover {
                background: #032f2f;
            }
        }

        .profile-accounts-manager--table--header {
            position: relative;
            >span {
                display: block;
                font-size: 13px;
                font-weight: bold;
                text-transform: capitalize;
            }  
        }

        .ReactVirtualized__Table__row {
            align-items: flex-start;
        }

        &--no-content {
            position: relative;
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #a9cccb;
        }

        &--batch-control-popup {
            border-radius: 2px;
            overflow: hidden;
            box-shadow: 0px 0px 5px #032e2e;
            margin-top: 3px;

            &--trigger {
                position: absolute;
                right: 3px;
                top: 0px;
                background: none;
                font-size: 19px;
                color: #a8cece;
                height: 100%;
                padding: 0;

                &:hover {
                    color: white;
                }
            }
            &--header {
                font-size: 13px;
                font-weight: bold;
                text-indent: 9px;
                line-height: 21px;
                background: #085050;
                color: white;
                border-bottom: 1px solid #539696;
            }
        }

        &--toggle-smart-accounts-button {
            position: absolute;
            right: 0px;
            top: 0px;
            padding: 0px 5px;
            border-radius: 2px;
            font-size: 13px;
            font-weight: bold;
            background: #ddd;
            color: #094a4a;

            &:hover {
                background: white;
                color: #032f2f;
            }
        }

        &--row {
            &:nth-child(n+2) {
                border-top: 1px solid #032f30;
            }
            &:hover {
                background: #083e3d;
                .switch-controller--table--batch-operation {
                    opacity: 1.0;
                }
            }
            &:hover {
                .profile-accounts-manager--table--profile-action-trigger {
                    opacity: 1.0 !important;
                }
            }
        }

        &--profile-action-trigger {
            float: right;
            background: none;
            font-size: 19px;
            color: #a8cece;
            padding: 0;
            margin-right: 3px;
            opacity: 0;

            &:hover {
                color: white;
            }
        }

        &--profile-name, &--hostname, &--state, &--buttons, &--exchange-accounts {
            padding: 10px 0px;
            font-size: 14px;
            font-weight: bold;
        }

        &--profile-name, &--hostname {
            font-size: 14px;
        }

        &--profile-name {
            &--symbols {
                color: #acd6d6;
                margin-top: 3px;
                font-size: 13px;
                white-space: normal;
            }
        }

        &--buttons {
            display: flex;
        }

        &--button {
            width: 55px;
            font-weight: bold;
            margin-right: 4px;
            border-radius: 2px;

            &.reset {
                background: #ddd;
                color: #0a4a4a;
                &:hover {
                    background: white;
                    color: #186b6b;
                }
            }
        }

        &--saving-text {
            color: #9abdbd;
        }

        .profile-exchange-account-editor {
            .search-select {
                &--options-wrapper {
                    position: fixed;
                }
            }
        }
    }

    &--saving-profile-window, &--syncing-profile-window {
        position: absolute;
        width: 220px;
        background: #053d3de0;
        bottom: 5px;
        right: 5px;
        border-radius: 3px;
        box-shadow: 0px 0px 5px #011d1d;
        overflow: hidden;

        &--body {
            display: flex;
            flex-direction: column-reverse;
            padding: 1px 0px 3px;
            max-height: 300px;
            overflow: auto;
        }

        &--item {
            position: relative;
            padding: 1px 5px;


            &--name {
                position: relative;
                float: left;
                width: 100%;
                font-size: 13px;
                font-weight: bold;
                line-height: 21px;
            }

            &--icon {
                position: absolute;
                right: 5px;

                >img {
                    width: 21px;
                    height: 21px;
                }
                >svg {
                    font-size: 18px;
                    color: #7fd8d8;
                    transform: translateY(2px);
                }

                &--failed {
                    color: #d16b6b !important;
                }
            }

            &--error-message {
                float: left;
                color: #d16b6b;
                font-size: 14px;
                font-weight: bold;
            }
        }

        &--footer {
            background: #1b6f6f;
            padding: 1px 5px;
            font-size: 13px;
            font-weight: bold;
        }

        &--close-button {
            position: absolute;
            right: 5px;
            background: none;
            font-size: 13px;
            font-weight: bold;
            color: #ddd;
            padding: 0;

            &:hover {
                color: white;
            }
        }
    }
}

.profile-exchange-account-editor {
    font-weight: bold;

    &--account-name-search-select {
        .search-select--input-icon {
            opacity: 0.7;
        }
    }

    &--smart-pos-switch-interval {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 9px;
        white-space: nowrap;
        font-size: 14px;

        &--name {
            color: #ddd;
        }

        &--value {
            font-weight: bold;
            margin-left: 5px;
            width: 55px;
            text-indent: 7px;
            cursor: text;
            background: #66a0a0;
            color: white;
            border-radius: 2px;
            height: 25px;
            padding: 0;
        }
    }   

    &--smart-pos-accounts {

        &--direction {
            font-size: 13px;
            font-weight: bold;
            font-size: 13px;
            font-weight: bold;
            border-bottom: 1px dashed #537d7d;

            &.BUY {
                margin-top: 3px;
                color: #b2dcdc;
            }
            &.SELL {
                margin-top: 5px;
                color: #f5838c;
            }
        }

        &--list {
            position: relative;
            margin-top: 5px;
            display: flex;
        }

        &--editor {
            float: left;
        }

        &--copy-button {
            float: left;
            font-size: 13px;
            line-height: 23px;
            margin-left: 5px;
            border-radius: 2px;
            padding: 0px 8px;
            font-weight: bold;
            background: none;
            border: 1px solid;

            &.BID {
                color: #f5838c;
                &:hover {
                    border: 1px solid #f5838c;
                    background: #f5838c;
                    color: #690911;
                }
            }

            &.ASK {
                color: #b2dcdc;
                &:hover {
                    border: 1px solid #b2dcdc;
                    background: #b2dcdc;
                    color: #2D5858;
                }
            }
        }
    }

    &--smart-accounts-wrapper {
        &--backup-account, &--round-robin-switch {
            margin-top: 10px;
            display: flex;
            align-items: center;
            font-size: 14px;

            >label {
                margin-right: 10px;
            }

            .search-select {
                background: #65a0a0;
                height: 25px;
                border-radius: 3px;

                &--current-option {
                    line-height: 25px;
                    text-indent: 8px;
                    border-radius: 3px;

                    &:hover {
                        background: #529898;
                    }
                }
            }

            .toggle {
                height: 25px;

                &--button {
                    width: 21px;
                    height: 21px;
                }

                &--track {
                    width: 44px;
                }

                &--label {
                    line-height: 25px;
                }
            }
        }
    }
}

@keyframes animation-hostname-profile-state-fade-out {
    0% {
        opacity: 1.0;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(10%);
    }
}

.hostname-profile-state {
    position: absolute;
    z-index: 10;
    bottom: 0px;
    right: 23px;
    background: #012322c4;
    box-shadow: 0px 0px 3px black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;

    &.fading-out {
        animation: animation-hostname-profile-state-fade-out 300ms;
        opacity: 0;
    }


    &--header {
        position: relative;
        line-height: 25px;
        background: #106161;
        font-size: 13px;
        font-weight: bold;
        padding: 0px 7px;

        &--title {
           float: left;
           padding-right: 30px; 
        }

        &--toggle-button {
            position: absolute;
            height: 100%;
            right: 0px;
            background: none;
            font-size: 18px;
            color: #ddd;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(3px);
            }
        }
    }

    &--main {
        padding: 2px 7px;
        table {
            border-collapse: collapse;
            td {
                font-size: 15px;
                font-weight: bold;
                line-height: 22px;       
                
                &:not(:last-child) {
                    padding-right: 7px;
                }
            }
        }
    }

    &--item {
        &--state {
            position: relative;
            max-width: 200px;

            >span {
                padding-left: 23px;
                &.pending {
                    padding-left: 23px;
                    color: #bfb462;
                }
                &.complete {
                    color: white;
                }
            }
            >img {
                position: absolute;
                height: 19px;
                margin-top: 2px;
            }
            >svg {
                position: absolute;
                font-size: 19px;
                margin-top: 2px;
                color: #3de8b0;
            }
        }
    }

    &--fetch-button {
        border: 1px solid;
        background: none;
        color: #7eaeaf;
        font-weight: bold;

        &.is-fetching {
            opacity: 0.7;
        }

        &:hover {
            opacity: 1.0;
            color: white;
        }
    }

    &--error-message {
        color: #dc7479;
        font-size: 13px;
        line-height: 17px;
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.profile-stopped-updating-symbol-pricings {
    position: absolute;
    z-index: 10;
    bottom: 0px;
    right: 23px;
    background: #012322c4;
    box-shadow: 0px 0px 3px black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;

    &--header {
        position: relative;
        line-height: 25px;
        background: #ca626a;
        font-size: 13px;
        font-weight: bold;
        padding: 0px 7px;

        &--title {
           float: left;
           padding-right: 30px; 
        }

        &--toggle-button {
            position: absolute;
            height: 100%;
            top: 0px;
            right: 0px;
            background: none;
            font-size: 18px;
            color: #ddd;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(3px);
            }
        }
    }

    &--main {
        padding: 0px 7px 2px;
        max-height: 150px;
        overflow: auto;

        table {
            border-collapse: collapse;
            th, td {
                font-size: 13px;
            }
            th {
                padding-top: 2px;
                position: sticky;
                top: 0px;
                background: #012524;
            }
            td {
                font-size: 13px;
                font-weight: bold;
                line-height: 22px;
                border-bottom: 1px dashed #0f6161;       
                
                &:not(:last-child) {
                    padding-right: 7px;
                }

                >span {
                    cursor: pointer;
                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }
}


.profile-symbols-to-be-handled {
    position: absolute;
    z-index: 10;
    top: 32px;
    right: 23px;
    background: #012322c4;
    box-shadow: 0px 1px 3px #242424;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;

    &--header {
        position: relative;
        line-height: 25px;
        background: #ca626a;
        font-size: 13px;
        font-weight: bold;
        padding: 0px 7px;
    }

    &--title {
        float: left;
        padding-right: 30px; 
     }

    &--toggle-button {
        position: absolute;
        height: 100%;
        top: 0px;
        right: 0px;
        background: none;
        font-size: 18px;
        color: #ddd;

        &:hover {
            color: white;
        }

        >svg {
            transform: translateY(3px);
        }
    }

    &--search-input {
        width: 100%;
        display: block;
        font-size: 13px;
        height: 25px;
        background: #064a49;
        color: white;
        font-weight: bold;
        text-indent: 5px;
        cursor: text;
    }

    &--main {
        padding: 0px 7px 2px;
        max-height: 315px;
        min-width: 300px;
        overflow: auto;

        table {
            border-collapse: collapse;
            th, td {
                font-size: 13px;
            }
            th {
                padding-top: 5px;
                position: sticky;
                top: 0px;
                background: #022c2c;
                color: #bcdddd;
                text-align: left;
                z-index: 2;
            }
            td {
                font-size: 13px;
                font-weight: bold;
                line-height: 22px;
                padding: 3px 0px;
                border-bottom: 1px dashed #0f6161;       
                
                &:not(:last-child) {
                    padding-right: 11px;
                }

                >span {
                    cursor: pointer;
                    &:hover {
                        color: white;
                    }
                }

                &.BUY {
                    color: #8fe6e8;
                }
                &.SELL {
                    color: #fd818a;
                }

                .checkbox {
                    width: 20px;
                    height: 20px;

                    >svg {
                        margin-bottom: 1px;
                    }
                }
            }
        }
    }

    &--types {
        background: #022c2c;
        border-bottom: 1px solid #a3bdbd;

        >button {
            font-size: 15px;
            font-weight: bold;
            background: none;
            padding: 5px 5px 3px;
            color: #a3bdbd;
            border-bottom: 2px solid transparent;
            

            &:not(:first-child) {
                margin-left: 11px;
            }

            &:hover {
                color: white;
            }

            &.selected {
                border-color: #a3bdbd;
                color: white;
            }
        }
    }

    &--empty-message {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        display: block;
        padding: 10px 0px;
        color: #86a5a4;
    }

    &--symbols-stopped-updating-price {

        &--criterias {
            margin: 7px 0px 5px;
        }

        &--should-include-option-toggle {
            display: flex;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;

            >label {
                margin-right: 5px;  
                opacity: 0.8;
            }

            .checkbox {
                width: 20px;
                height: 20px;
            }
        }
    }

    &--switch-off {
        &--groups {
            display: inline-flex;
            align-items: center;
            width: 100%;
            margin: 7px 0px 5px;

            &--button {
                font-size: 14px;
                font-weight: bold;
                background: none;
                color: #ddd;
                border-bottom: 2px solid transparent;
                color: #ddd;
                
                &:not(:last-child) {
                    margin-right: 11px;
                }

                &.selected {
                    color: white;
                    border-bottom: 2px solid white;
                }

                &:hover {
                    color: white;
                }
            }
        }

        &--sign-button {
            background: #5d8cb5;
            border-radius: 2px;
            font-size: 13px;
            padding: 2px 7px;
            font-weight: bold;
            color: #ddd;

            &:hover {
                &:not(:disabled) {
                    color: white;
                }
            }

            &.success {
                background: #75b175;
                color: #061006;
            }

            &.failed {
                background: #db8e94;
                color: #000000;
            }

            &:disabled {
                opacity: 0.8;
            }
        }
    }
}

.profile-notification-popup {
    border-radius: 3px;
    box-shadow: 0px 0px 3px black;
    overflow: hidden;
    width: 280px;
    display: flex;
    flex-direction: column;

    input {
        @include background-color-1;
    }

    &--trigger {
        background: none;
        color: #e9f1f1;
        opacity: 0.7;
        width: 30px;
        padding: 0;
        font-size: 21px;
        margin-right: 1px;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            transform: translateY(4px);
        }
    }

    &--header {
        @include background-color-5;
        border-bottom: 1px solid black;
        line-height: 24px;
        min-height: 24px;
        padding: 2px 10px;
        font-size: 14px;
        font-weight: bold;
    }

    &--main {
        position: relative;
        @include background-color-11;
        overflow-y: auto;
    }
}

.user-profiles {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;

    ::-webkit-scrollbar {
        height: 9px;
        background-color: #023b3b;
        border-radius: 5px;
    }

    &--header {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;
        line-height: 30px;
        background: #0a4a49;
        white-space: nowrap;

        &--right-align-content {
            width: 100%;
            display: inline-flex;
            flex-direction: row-reverse;
        }

        &--fetch-button {
            width: 95px;
            padding: 0px;
            font-size: 14px;
            font-weight: bold;
            background: #116261;
            color: #ddd;

            &:hover {
                color: white;
            }
        }
    }

    &--title {
        font-size: 16px;
        margin-left: 15px;
    }

    &--last-update-time, &--is-updating {
        font-size: 14px;
        font-weight: bold;
        margin-right: 15px;
    }

    &--is-updating {
        min-width: 55px;
        text-align: right;
    }

    &--user-search {
        height: 28px;
        position: relative;
        display: block;
        margin-left: 15px;
        min-width: 250px;
        font-size: 14px;
        background: transparent;
        border-left: 2px solid #032829;
        padding-left: 15px;
        color: white;
        cursor: text;
        font-size: 14px;
        font-weight: bold;
    }

    &--profile-search {
        >input {
            width: 100%;
            height: 28px;
            font-size: 14px;
            font-weight: bold;
            background: #073e3d;
            display: block;
            margin: 0;
            padding: 0;
            text-indent: 10px;
            cursor: text;
            color: white;
            border-bottom: 1px solid #4d7a7a;
        }
    }

    &--body {
        position: relative;
        padding: 5px 15px 15px;
        overflow-y: hidden;
        overflow-x: auto;
    }

    &--list {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        &--title {
            font-size: 16px;
            line-height: 36px;
        }
        &--main {
            padding: 10px 10px 3px;
            height: 100%;
            overflow: auto;
        }
    }

    &--item-wrapper {
        font-size: 14px;
        padding: 7px 10px;
        border-radius: 3px;
        margin-bottom: 7px;

        &.not-found {
            background: #c18a8a !important;
            color: #460909 !important;
        }
    }

    &--item {
        display: flex;
        align-items: center;

        >span {
            display: block;
            width: 100%;
            word-break: break-all;
        }

        &--remove-button {
            padding: 0px 0px;
            margin: 0;
            font-size: 14px;
            background: none;
            color: #ddd;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(2px) scale(1.2);
            }
        }
    }

    &--empty-assigned-message {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
    }
}

.profile-managers {
    &--popup {
        @include background-color-11;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;
        overflow: hidden;
        font-weight: bold;

        &--trigger {
            @include button-style-5;
            cursor: pointer;
            font-size: 13px;
            height: 23px;
            line-height: 23px;
            padding: 0px 7px;
            border-radius: 2px;

            &.empty {
                @include button-style-4;
            }

            &:hover {
                color: white;
            }
        }
    }

    &--header {
        display: flex;
        padding: 5px 11px;
        @include background-color-5;
        font-size: 14px;
    }

    &--title {
        width: 100%;
    }

    &--is-updating {
        min-width: 100px;
        text-align: right;
    }

    &--body {
        padding: 11px 11px 0px;
        max-width: 320px;
    }

    &--trader {
        position: relative;
        display: flex;
        float: left;
        align-items: center;
        font-size: 14px;
        margin-bottom: 7px;
        margin-right: 30px;

        >label {
            margin-right: 11px;
            min-width: 85px;
        }
    }
}

.profile-fill-statistics {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    min-width: 320px;
    width: 100%;
    @include background-color-11;

    &--profile-selector {
        position: relative;
        padding: 10px 10px 0px;
        border-bottom: 2px solid;
        @include border-color-4;     

        &--buttons {
            display: flex;
            gap: 10px;

            >button {
                font-size: 14px;
                font-weight: bold;
                height: 28px;
                padding: 0px 10px;
            }
        }

        &--trigger {
            @include button-style-2;
        }

        &--clear-all-button {
            @include button-style-1;
        }

        &--popup {
            @include background-color-3;
            padding: 10px 0px;
            box-shadow: 0px 0px 3px black;
            display: flex;
            flex-direction: column;
            width: 720px;
        }

        &--header {
            display: flex;
            gap: 10px;
            padding: 0px 10px;

            >input {
                flex-grow: 1;
                height: 28px;
                @include background-color-2;
                cursor: text;
                font-size: 14px;
                color: white;
                font-weight: bold;
                padding: 0px 10px;
            }

            >button {
                @include button-style-4;

                &:disabled {
                    opacity: 0.7;
                }
            }
        }

        &--list {
            position: relative;
            margin-top: 10px;
            padding: 0px 10px;
            overflow: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }

        &--item {
            display: flex;
            align-items: center;
            font-weight: bold;
            gap: 8px;
            cursor: pointer;

            .checkbox {
                width: 24px;
                height: 24px;
            }

            >label {
                font-size: 14px;
                cursor: inherit;
            }

            >span {
                font-size: 13px;
                font-weight: bold;
                @include background-color-2;
                padding: 3px 5px;
                border-radius: 3px;
                color: #ddd;
            }
        }

        &--prepared-profiles {
            padding: 10px;
            border-bottom: 1px solid;
            @include border-color-2;

            &--option {
                @include button-style-2;
                font-size: 13px;
                height: 24px;
                font-weight: bold;
            }
        }

        &--profile-size-warning {
            @include text-color-highlight-red;
            font-size: 16px;
            font-weight: bold;
            line-height: 32px;
        }

        &--selected-list {
            margin-top: 10px;
            gap: 5px;
        }

        &--selected-item {
            font-size: 14px;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            @include background-color-1;
            padding: 5px;
            height: 18px;
            padding-left: 12px;
            box-shadow: 0px 0px 3px black;
            margin-bottom: 10px;
            border-radius: 30px;

            >span {
                font-size: 13px;
                margin-left: 5px;
                @include background-color-2;
                border-radius: 20px;
                line-height: 21px;
                padding: 0px 10px;
                color: #ddd;
            }

            >button {
                background: none;
                display: flex;
                align-items: center;
                color: #ddd;
                padding: 0;
                margin-left: 5px;
                font-size: 14px;
                opacity: 0.6;
                width: 21px;
                height: 21px;
                justify-content: center;
                border-radius: 30px;

                &:hover {
                    opacity: 1;
                    @include background-color-5;
                }
            }
        }
    }

    &--header {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        line-height: 24px;
        padding: 0px 10px;
        @include background-color-5;

        >label {
            width: 100%;
        }

        &--is-fetching, &--profile-name {
            font-size: 13px;
            margin-left: 10px;
            white-space: nowrap;
        }

        &--profile-name {
            margin-left: 20px;
        }
    }

    &--date-time-inputs {
        display: flex;
        align-items: flex-end;
        margin-top: 5px;
        padding: 0px 10px 10px;

        >span {
            display: block;
            min-width: 15px;
            height: 26px;
            text-align: center;
        }

        &--go-button {
            height: 28px;
            margin-left: 10px;
            width: 40px;
            padding: 0;
            font-size: 14px;
            font-weight: bold;
            @include button-style-2;

            &:hover {
                color: white;
            }

            &:disabled {
                opacity: 0.6;
                &:hover {
                    color: initial;
                }
            }
        }
    }

    &--date-time-tabs {
        margin-top: 10px;
        padding: 0px 10px;
        display: flex;
    }

    &--date-time-tab {
        width: 65px;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
        margin-right: 5px;
        border-radius: 3px;
        padding: 0;
        @include background-color-6;
        color: #ddd;

        &:hover {
            @include background-color-5;
            color: white;
        }

        &:disabled {
            opacity: 0.7;

            &:hover {
                @include background-color-6;
                color: #ddd;
            }
        }
    }

    &--date-time-input {
        font-weight: bold;
        label {
            display: block;
            font-size: 14px;
        }
        input {
            font-size: 13px;
            margin-top: 3px;
            font-weight: bold;
            width: 190px;
            color: white;
            border-radius: 3px;
            height: 28px;
            padding: 0px 10px;
            cursor: pointer;
            @include background-color-6;
        }
    }

    &--selectors {
        display: flex;
        align-items: center;
        padding: 0px 10px 10px;
    }

    &--portfolio, &--manual-order-user, &--instrument {
        display: flex;
        align-items: center;
        font-weight: bold;
        @include background-color-6;
        height: 28px;
        padding: 0px 10px;
        padding-right: 0px;
        font-size: 14px;
        border-radius: 3px;
        flex-basis: 100%;

        &:not(:first-child) {
            margin-left: 10px;
        }

        >span {
            font-size: 13px;
            opacity: 0.7;
            white-space: nowrap;
        }

        .search-select {
            margin-left: 10px;

            &--current-option--name {
                margin-right: 30px;
                font-size: 14px;
            }

            &--input-icon {
                opacity: 0.75;
            }
        }
    }

    &--portfolio, &--instrument {
        max-width: 150px;
    }

    &--manual-order-user {
        max-width: 240px;
    }

    &--search-input-wrapper {
        padding-bottom: 10px;
        border-bottom: 1px solid;
        @include border-color-1;
    }

    &--search-input {
        width: 100%;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        height: 28px;
        min-height: 28px;
        margin: 0px;
        margin-left: 10px;
        @include background-color-6;
        color: white;
        cursor: text;
        max-width: 220px;
        border-radius: 3px;
    }

    &--result {
        position: relative;
        height: 100%;
        overflow: auto;

        &--empty-data {
            font-weight: bold;
            font-size: 16px;
            font-weight: 24px;
            padding: 10px 0px;
            text-align: center;
        }

        &--item {
            padding: 8px 10px 10px;
            &:not(:last-child) {
                border-bottom: 1px solid black;
            }
            &.has-no-data {
                background: #3a3f41;
            }

            &--title {
                color: white;
                font-weight: bold;
                font-size: 16px;
                display: flex;
                align-items: center;
                white-space: nowrap;

                >span {
                    width: 100%;
                }

                .warning {
                    width: auto;
                    font-size: 14px;
                    @include text-color-highlight-yellow;
                }
            }

            &--main {
                margin-top: 5px;
            }
        }
    }

    &--aggregated-stats {
        padding: 8px 10px 10px;
        border-bottom: 2px solid;
        @include background-color-3;
        @include border-color-5;

        &--error-message, &--title {
            font-size: 16px;
            font-weight: bold;
            @include text-color-highlight-yellow;
        }

        &--main {
            margin-top: 5px;
        }

        &--avg-price-ratio {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            margin-top: 10px;

            >label {
                width: 100%;
                opacity: 0.8;
            }
        }

        .profile-fill-statistics--fee {
            margin-top: 0px;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;

        th, td {
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            white-space: nowrap;

            &:not(:first-child) {
                text-align: right;
                padding-right: 10px;
                width: 30%;
            }
        }

        th {
            &.buy {
                background: #548f90;
                color: white;
            }
            &.sell {
                background: #a16a72;
                color: white;
            }
        }
        td {
            &.buy {
                background: #224d57;
            }
            &.sell {
                background: #4a4150;
            }
        }

        tbody {
            th {
                color: #ccc;
                padding-right: 5px;
            }
        }
    }

    &--fee {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 16px;
        white-space: nowrap;
        margin-top: 10px;
        font-weight: bold;
        line-height: 20px;

        >label {
            width: 100%;
            opacity: 0.8;
        }

        &--popup {
            @include background-color-5;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            padding: 5px 10px;
            box-shadow: 0px 0px 3px black;

            &--trigger {
                text-decoration: underline dotted;
                .total-value {
                    margin-right: 10px;
                }
                .ratio {
                    font-size: 14px;
                }
            }
        }
    }
}

.profile-param-scanner {
    position: relative;
    color: white;
    font-weight: bold;

    &--identity {
        display: flex;
        align-items: center;
        line-height: 30px;
        border-bottom: 1px dashed;
        @include border-color-2;
        padding-bottom: 10px;

        >label {
            font-size: 20px;
        }

        >span {
            margin-left: 10px;
            font-size: 14px;
            opacity: 0.7;
        }
    }

    &--auto-fills {
        &--item {
            padding: 10px 0px;
            &:not(:first-child) {
                border-top: 1px solid;
                @include border-color-1;
            }
            &--header {
                white-space: nowrap;
            }
            &--param-name {
                font-size: 18px;
                line-height: 24px;
        
                >label {
                    opacity: 0.6;
                }
            }
            &--symbol-name, &--path {
                font-size: 13px;
                line-height: 20px;
                opacity: 0.6;
            }
            &--modification {
                display: flex;
                align-items: center;
                margin-top: 5px;

                >svg {
                    font-size: 20px;
                    margin: 0px 20px;
                }
            }
            &--value {
                >label {
                    opacity: 0.6;
                    font-size: 13px;
                    line-height: 16px;
                }
                >div {
                    font-size: 16px;
                    line-height: 24px;

                    &.empty-value {
                        @include text-color-negative;
                    }
                }
            }
        }
    }

    &--action {
        margin-top: 10px;
    }

    &--save-button {
        @include button-style-4;
        font-size: 16px;
        height: 34px;
        padding: 0px 20px;

        &.is-saved {
            @include background-color-2;
        }
    }

    &--empty-message {
        margin-top: 5px;
        font-size: 16px;
        line-height: 24px;
        @include text-color-positive;

        >svg {
            font-size: 16px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    &--message {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        @include text-color-negative;
    }
}

.profile-param-scanner-container {
    position: relative;
    color: white;
    font-weight: bold;
    min-width: 350px;

    &--description {
        font-size: 14px;
        opacity: 0.7;
    }

    &--item {
        padding: 10px 0px;
        &:not(:last-child) {
            border-bottom: 2px solid;
            @include border-color-2;
        }
    }

    &--buttons {
        border-bottom: 1px solid;
        @include border-color-2;
        padding: 10px 0px;
    }

    &--scan-all-button {
        @include button-style-2;
        font-weight: bold;
        font-size: 16px;
        height: 34px;
        padding: 0px 10px;
    }

    &--save-all-button {
        @include button-style-3;
        font-weight: bold;
        font-size: 16px;
        height: 34px;
        padding: 0px 10px;
        margin-left: 10px;
        float: right;
    }

    &--empty-message {
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
        line-height: 26px;

        >svg {
            font-size: 24px;
            vertical-align: middle;
            margin-right: 3px;
        }
    }
}

.profile-snapshot-container {
    @include background-color-2;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0px;

    &--creation {
        padding: 0px 12px 0px;
        &--title {
            font-size: 16px;
            line-height: 24px;
        }
        &--main {
            margin-top: 5px;
        }
    }

    &--loading {
        width: 20px !important;
        height: 20px !important;
        margin-left: 10px;
    }

    &--list {
        &--title {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            padding: 0px 12px;
        }

        &--empty {
            margin-top: 10px;
            text-align: center;
            opacity: 0.6;
        }

        &--main {
            display: flex;
            flex-direction: column;
            max-height: 400px;
            overflow: auto;
            padding: 0px 12px;
        }
        &--item {
            padding: 10px 0px;
            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-6;
            }
        }
    }

    >hr {
        border-top: none;
        border-bottom: 1px solid;
        @include border-color-1;
        margin: 10px 0px;
    }
}

.profile-snapshot-editor {
    font-weight: bold;
    font-size: 14px;

    &--inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &--input-wrapper {
        display: flex;
        flex-direction: column;

        >label {
            line-height: 20px;
            color: #ccc;
        }

        >input, >textarea {
            cursor: text;
            @include background-color-6;
            font-size: 14px;
            color: white;
            font-weight: bold;
            padding: 5px 10px;
            margin-top: 3px;
            color: white;
        }

        >textarea {
            resize: none;
        }
    }

    &--buttons {
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    &--save-button {
        @include button-style-4;
        margin-left: auto;
        font-size: 14px;
        padding: 5px 12px;
    }
}

.profile-snapshot {
    &--header {
        display: flex;
        align-items: flex-start;
    }

    &--meta-data {
        margin-left: auto;
        text-align: right;
        opacity: 0.6;
        font-size: 13px;
    }

    &--id {
        font-size: 13px;
        opacity: 0.6;
        user-select: text;
    }

    &--title {
        font-size: 18px;
        line-height: 32px;
    }
    &--comment {
        font-size: 14px;
        color: #ddd;
        white-space: pre-line;
    }
    &--buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
    &--button {
        &.apply {
            @include button-style-2;
        }
        &.update {
            @include button-style-3;
            margin-left: auto;
        }
        &.delete {
            @include button-style-1;
        }

        font-size: 13px;
        font-weight: bold;
        padding: 3px 10px;
    }

    &--update-popup, &--delete-popup {
        border-radius: 3px;
        overflow: hide;
        box-shadow: 0px 0px 3px black;
        @include background-color-11;
        padding: 10px;

        &--message {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &--update-popup {
        min-width: 280px;

        .profile-snapshot-editor {
            margin-top: 10px;
        }
    }

    &--delete-popup {
        button {
            margin-top: 10px;
            @include button-style-1;
            width: 100%;
            height: 28px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.profile-view-configuration {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    @include background-color-1;
    
    &--header {
        @include background-color-2;
        font-size: 13px;
        line-height: 24px;
        padding: 0px 10px;
    }

    &--main {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px 10px;
        min-width: 180px;

        >div {
            display: flex;
            align-items: center;
            line-height: 24px;

            >label {
                flex-grow: 1;
            }
        }

        .checkbox {
            width: 20px;
            height: 20px;
        }
    }
}

.profile-log-level {
    display: flex;
    align-items: center;
    gap: 8px;

    >label {
        font-size: 12px;
        opacity: 0.6;
    }

    >button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0;
        font-size: 13px;
        font-weight: bold;
        background: none;
        color: white;

        >svg {
            opacity: 0.6;
            transition: all 0.3s;
        }

        &:hover {
            >svg {
                opacity: 1.0;
            }
        }
    }

    &--popup {
        @include background-color-1;
        box-shadow: 0px 0px 3px black;
        padding: 10px;
        border-radius: 3px;
        font-weight: bold;
    }

    &--title {
        font-size: 16px;
    }

    &--list {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        border-bottom: 1px solid;
        @include border-color-4;
        padding-bottom: 10px;

        &--item {
            font-size: 14px;
            height: 24px;
            font-weight: bold;
            border: 1px solid #7b92a2;
            background: none;
            color: white;
            transition: all 0.3s;

            &:hover {
                &:not(:disabled) {
                    background: #7b92a2;
                }
            }

            &.selected {
                background: #7b92a2;
                color: #011522;
            }
        }
    }

    &--reset-button {
        @include button-style-3;
        margin-top: 10px;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0px 10px;

        >svg {
            font-size: 16px;
        }
    }
}

.signal-profile-form {
    position: relative;
    font-weight: 600;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--header {
        display: flex;
        align-items: center;
        gap: 10px;
        @include background-color-9;
        height: 32px;
        min-height: 32px;
        padding: 0px 10px;
        white-space: nowrap;

        &--buttons {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 5px;
            padding-left: 10px;

            >button {
                display: flex !important;
                align-items: center !important;
                gap: 5px;
                height: 24px !important;
                font-weight: bold !important;
                font-size: 14px;
                @include button-style-2;

                >svg {
                    font-size: 16px;
                }

                &:disabled {
                    opacity: 0.4;
                }
            }
        }
    }

    &--save-button {
        background: #66afa4 !important;
    }

    &--discard-button {
        background: #d48080 !important;
    }

    &--deploy-button {
        background: #72c1de !important;
    }

    &--name {
        font-size: 18px;
    }

    &--directory {
        background: #d9a341;
        font-size: 14px;
        padding: 0px 10px;
        line-height: 21px;
        color: #4e3200;
        border-radius: 3px;

        &.stored {
            background: #83c183;
            color: #173717;
        }
    }

    &--main {
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        gap: 10px;
    }

    &--ui {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 10px;
        padding-right: 10px;
        overflow-y: auto;
    }

    &--json {
        position: relative;
        width: 100%;
        height: 100%;

        >div {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &--section {
        &--title {
            font-size: 14px;
            line-height: 24px;
            padding: 0px 12px;
            @include background-color-9;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                @include background-color-7;
            }

            >svg {
                margin-left: auto;
                color: #ddd;
            }
        }

        &--main {
            animation: slideDown 300ms;
        }
    }

    &--instance {
        position: relative;
        margin-top: 10px;

        &--param {
            display: inline-block;
            vertical-align: top;
            min-width: 120px;
            margin-right: 10px;
        }
    }

    &--sortable-signal-config-item {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding: 10px 0px;

        &:not(:last-child) {
            border-bottom: 1px solid;
            @include border-color-4;
        }
    }

    &--sortable-handle {
        width: 28px;
        height: 28px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        cursor: grab;
        background: none;
        color: #ddd;
    }
    
    &--sortable-signal-configs {
        &--add-button {
            @include button-style-4;
            height: 28px;
            padding: 0px 10px;
            font-size: 16px;
            margin-top: 10px;
        }
    }

    &--symbols {
        margin-top: 10px;
    }
}

.signal-param {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &--label {
        font-size: 13px;
        line-height: 20px;
        color: #9ca9b0;

        &.has-description {
            text-decoration: underline dashed;
            text-underline-offset: 2px;
            cursor: help;
        }

        &--popup {
            width: auto;
            max-width: 400px !important;
            @include background-color-7;
            font-size: 13px;
            font-weight: bold;
            padding: 10px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;
        }
    }

    .toggle {
        height: 24px;

        &--button {
            width: 20px;
            height: 20px;
        }

        &--track {
            width: 42px;
        }

        &--label {
            line-height: 24px;
        }
    }

    .search-select {
        position: relative;
        background: #7b92a2;
        @include background-color-6;
        height: 24px;
        min-width: 120px;
        font-size: 14px;
        
        &--current-option {
            min-height: 100%;
            line-height: 24px;
            display: flex;
            align-items: center;

            >span {
                padding: 0px 10px;
                line-height: 24px;
                flex-grow: 1;
            }

            >svg {
                position: relative;
                top: unset;
                transform: unset;
                margin: 0px 8px;
                transition: all 0.3s;
                right: unset;
            }
        }
    }

    >input {
        width: 80px;
        height: 24px;
        cursor: text;
        @include background-color-6;
        color: white;
        font-size: 14px;
        font-weight: bold;
        padding: 0px 10px;
    }

    &--sides {
        display: flex;
        align-items: center;
    }

    &--side {
        height: 24px;
        border: 1px solid;
        font-size: 14px;
        font-weight: 600;
        background: none;
        padding: 0px 7px;

        &.BID {
            @include text-color-positive;
            border-right: none;

            &.selected {
                color: #061928;
                border-color: #90eaea;
                background: #90eaea;
            }
        }

        &.ASK {
            @include text-color-negative;
            border-left: none;

            &.selected {
                color: #061928;
                border-color: #f27c85;
                background: #f27c85;
            }
        }
    }

    &--config-missing {
        line-height: 24px;
        @include text-color-highlight-red;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;

        >svg {
            font-size: 16px;
        }
    }
}

.signal-config-item {
    position: relative;
    width: 100%;

    &:hover {
        .signal-config-item--remove-button {
            opacity: 1.0;
        }
    }

    &--head {
        display: flex;
        align-items: center;
        gap: 10px;

        >input {
            height: 28px;
            cursor: text;
            background: #7b92a2;
            color: #061928;
            font-size: 16px;
            font-weight: bold;
            padding: 0px 10px;
            width: 320px;
        }
    }

    &--current-type {

        &.has-description {
            text-decoration: underline dashed;
            text-underline-offset: 2px;
        }

        &--popup {
            width: auto;
            max-width: 400px !important;
            @include background-color-7;
            font-size: 13px;
            font-weight: 600;
            padding: 10px;
            box-shadow: 0px 0px 3px black;
            margin: 3px 0px;
            border-radius: 3px;
        }
    }

    &--type-selector {

        &--trigger {
            @include button-style-2;
            height: 26px;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border-radius: 0px;
            gap: 5px;
            border: 1px solid #7b92a2;

            &.empty-value {
                background: none;
                color: #7b92a2;
            }

            &.invalid-type {
                background: #fd818a !important;
                border-color: #fd818a !important;
            }
        }

        &--popup {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 500px !important;
            @include background-color-2;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;
            font-weight: 600;
        }

        &--title {
            font-size: 14px;
            line-height: 24px;
            min-height: 24px;
            @include background-color-5;
            padding: 0px 10px;
            border-bottom: 1px solid;
            @include border-color-1;
        }

        &--search-input {
            height: 28px;
            min-height: 28px;
            font-size: 14px;
            font-weight: bold;
            color: white;
            @include background-color-1;
            padding: 0px 10px;
            cursor: text;
        }

        &--list {
            overflow-y: auto;
        }

        &--item {
            padding: 10px;
            cursor: pointer;
            transition: all 0.3s;
            
            &:hover {
                @include background-color-8;
            }

            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-3;
            }

            &--type {
                font-size: 16px;
            }

            &--description {
                font-size: 14px;
                opacity: 0.7;
                margin-top: 10px;
            }
        }
    }

    &--remove-button {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        font-size: 16px;
        background: none;
        color: white;
        @include border-color-4;
        border-radius: 3px;
        opacity: 0;
        transition: all 0.3s;

        &:hover {
            @include background-color-4;
            border-color: #bbb;
        }
    }

    &--params {
        position: relative;
        z-index: 2;

        &--item {
            position: relative;
            display: inline-flex;
            margin-right: 10px;
            margin-top: 10px;

            &.ARRAY_OF_SAMPLERS, &.ARRAY_OF_VARIABLES {
                display: block;
                width: 100%;
                margin-right: unset;
            }

            .signal-param {
                width: 100%;
            }
        }
    }
}

.signal-param-list {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;

    &--main {
        @include background-color-7;
        padding: 10px 0px 0px 10px;
        height: 68px;
        overflow-y: auto;
    }

    &--item {
        display: inline-flex;
        align-items: center;
        vertical-align: top;
        height: 24px;
        font-size: 14px;
        @include background-color-3;
        margin-bottom: 10px;
        margin-right: 10px;
        box-shadow: 0px 0px 1px black;

        &.invalid {
            background: #fd818a;
            color: #041928;
        }
    
        >label {
            padding: 0px 10px;
        }

        >button {
            display: flex;
            align-items: center;
            width: 24px;
            height: 24px;
            @include background-color-1;
            color: white;
            opacity: 0.4;
            transition: all 0.3s;
            border-left: 1px solid black;

            &:hover {
                opacity: 1.0;
            }
        }
    }

    .search-select {
        width: 100%;
        height: 28px !important;
        border-top: 1px solid;
        @include border-color-3;

        &--current-option {
            line-height: 28px;
            padding: 0px 10px;
            @include background-color-4;
            transition: all 0.3s;
            font-size: 14px;

            >span {
                padding: 0px !important;
            }

            &:hover {
                @include background-color-8;
            }
        }

    }
}

.signal-profile-container {
    position: relative;
    height: 100%;
    display: flex;
    gap: 10px;
    font-weight: 600;
    overflow-y: hidden;

    &--menu {
        width: 280px;
        min-width: 280px;
        overflow: auto;
        @include background-color-1;
    }

    &--empty-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-items: center;
        gap: 10px;
        color: #ddd;

        >svg {
            font-size: 48px;
            opacity: 0.6;
        }
    }

    &--form {
        overflow: auto;
        flex-grow: 1;
    }
}

.signal-profile-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;

    &--section {
        &--header {
            padding: 5px 10px;
            @include background-color-7;
            display: flex;
            align-items: center;
            font-size: 13px;
            height: 20px;
            gap: 5px;
            cursor: pointer;
            transition: all 0.3s;
            color: #bbb;

            &:hover {
                @include background-color-4;
                color: white;
            }

            >svg {
                font-size: 16px;
            }

            >button {
                margin-left: auto;
                @include button-style-2;
                font-size: 13px;
                font-weight: bold;
                border-radius: 0px;
            }
        }

        &--empty {
            margin-top: 10px;
            color: #ccc;
            font-size: 14px;
            padding: 0px 10px;
        }

        &--list {
            margin-top: 10px;
            animation: slideDown 300ms;
        }

        &--item {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            padding: 5px 10px;
            cursor: pointer;

            &.selected {
                background: #46677e78;
            }

            &:hover {
                background: #46677e78;

                .signal-profile-menu--section--item--delete--trigger {
                    opacity: 1.0;
                }
            }

            &--title {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &--delete {
                &--trigger {
                    opacity: 0;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    background: none;
                    color: #bbb;
                    font-size: 16px;
                    transition: all 0.3s;
                }

                &--popup {
                    @include background-color-2;
                    padding: 10px;
                    box-shadow: 0px 0px 3px black;
                    border-radius: 3px;
                    font-weight: 600;
                }

                &--message {
                    font-size: 14px;
                }

                &--confirm-button {
                    @include button-style-1;
                    margin-top: 10px;
                    width: 100%;
                    font-size: 14px;
                    font-weight: bold;
                    height: 28px;
                }
            }
        }
    }
}

.ace_editor {
    width: 100% !important;
    height: 100% !important;
    textarea, div, span {
        font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
    }
}

.twap-updater-container {
    display: flex;
    align-items: normal;
    height: 100%;
    font-weight: bold;
    overflow-y: hidden;

    &--side {
        width: 220px;
        min-width: 220px;
        display: flex;
        flex-direction: column;

        &--head {
            display: flex;
            align-items: center;
            gap: 10px;

            >button {
                width: 50%;
                white-space: nowrap;
                font-size: 14px;
                height: 28px;
                font-weight: bold;
            }
        }

        &--fetch-latest-button {
            @include button-style-3;
        }

        &--create-new-button {
            @include button-style-5;
        }
    }

    &--updater-list {
        margin-top: 10px;
        flex-grow: 1;
        overflow-y: auto;
    }

    &--updater {
        cursor: pointer;
        border-bottom: 1px solid;
        @include border-color-1;
        padding: 10px;
        transition: all 0.3s;
        overflow: hidden;

        &:hover {
            @include background-color-2;
        }

        &.selected {
            @include background-color-1;
        }

        &--main {
            margin-top: 8px;
            display: flex;
            align-items: center;
            gap: 8px;
            overflow: hidden;
        }

        &--header {
            font-size: 13px;
            display: flex;
            align-items: center;
            gap: 10px;

            .hostname {
                @include background-color-5;
                padding: 0px 5px;
                border-radius: 3px;
            }

            .user {
                opacity: 0.6;
            }
        }

        &--profile-name {
            font-size: 14px;
            line-height: 20px;
            word-break: break-word;
        }

        &--progress {
            margin-left: auto;
            width: 40px;
            min-width: 40px;
        }

        &:hover {
            .twap-updater-container--remove-updater--trigger {
                opacity: 1;
            }
        }
    }

    &--main {
        flex-grow: 1;
        padding-left: 20px;
        overflow-y: auto;
    }

    &--remove-updater {
        @include background-color-4;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: bold;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;

        &--trigger {
            margin: 0;
            padding: 0;
            margin-left: auto;
            font-size: 16px;
            display: flex;
            align-items: center;
            background: none;
            color: #ddd;
            height: 15px;
            opacity: 0;
        }

        &--main {
            >button {
                @include button-style-1;
                width: 100%;
                margin-top: 10px;
                height: 25px;
                font-weight: bold;
                font-size: 13px;
            }
        }
    }
}

.twap-updater {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 20px;

    .title {
        @include background-color-1;
        font-size: 14px;
        font-weight: bold;
        color: white;
        line-height: 24px;
        padding: 0px 10px;
    }

    &--main {
        display: flex;
        align-items: normal;
        gap: 20px;

        &--updater {
            display: flex;
            align-items: normal;
            gap: 20px;
        }
    }

    &--updater-params {
        &--main {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &--footer {
            margin-top: 10px;

            &--buttons {
                display: flex;
                align-items: center;
                gap: 10px;

                >button {
                    flex-basis: 100%;
                    height: 24px;
                    font-size: 14px;
                    font-weight: bold;

                    &:disabled {
                        opacity: 0.4;
                    }
                }
            }
        }

        &--reset-button {
            @include button-style-2;
        }

        &--create-button {
            @include button-style-4;
        }
    }

    &--updater-param {
        >label {
            font-size: 13px;
            opacity: 0.7;
            line-height: 20px;
        }

        .search-select {
            &--current-option {
                width: 200px;
                line-height: 24px;
                padding: 0px 10px;
                @include background-color-6;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .positive-number-input {
            >input {
                width: 200px;
                padding: 0px 10px;
                @include background-color-6;
                color: white;
                font-size: 14px;
                height: 24px;
                font-weight: bold;
                cursor: text;

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
    }

    &--sides {
        >button {
            width: 50%;
            height: 26px;
            font-size: 14px;
            font-weight: bold;
            background: none;
            border: 1px solid;
            transition: all 0.3s;

            &.BUY {
                @include text-color-positive;
                border-right: none;
                &.selected {
                    color: #061928;
                    background: #90eaea;
                    border-color: #90eaea;
                }
            }

            &.SELL {
                @include text-color-negative;
                border-left: none;
                &.selected {
                    color: #061928;
                    background: #fd818a;
                    border-color: #fd818a;
                }
            }
        }
    }

    &--state-overview {
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 200px;
    }

    &--updater-state, &--profile-state {
        &--main {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;


            >div {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                white-space: nowrap;

                >label {
                    opacity: 0.6;
                }

                >div {
                    margin-left: auto;
                }
            }
        }
    }

    &--action-buttons {
        display: flex;
        align-items: center;
        gap: 10px;

        >button {
            flex-basis: 100%;
            height: 24px;
            font-size: 14px;
            font-weight: bold;

            &:disabled {
                opacity: 0.4;
            }
        }
    }

    &--start-button {
        @include button-style-2;
    }

    &--pause-button {
        @include button-style-1;
    }

    &--pause-and-reset-button {
        @include button-style-3;
    }

    &--updater-logs {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-height: 258px;

        &--main {
            overflow-y: auto;
            flex-grow: 1;
        }

        &--item {
            font-size: 13px;
            line-height: 20px;
            padding: 5px 10px;
            color: #96b0c4;
            transition: all 0.3;

            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-1;
            }

            &:hover {
                @include background-color-1;
            }
        }
    }

    .profile-item {
        padding-right: 10px;
        &--mini-table {
            thead {
                @include background-color-1;
            }
        }

        ::-webkit-scrollbar {
            @include background-color-1;
        }
        
        ::-webkit-scrollbar-thumb {
            @include background-color-2;
        }
    }

    .quote-symbol-table, .hedge-symbol-table {

        thead {
            @include background-color-2;
        }

        &--header {
            @include background-color-2;
        }

        &--symbol-name {
            @include background-color-3;
        }

        tbody {
            color: aliceblue;

            tr {
                @include border-color-4;
            }

            td {
                @include border-color-2;

                input {
                    @include background-color-6;
                }
            }
        }

        &--direction-label {
            &.SELL {
                @include text-color-negative;
            }
        }

        &--pricing-direction-label {
            &.ask {
                @include text-color-negative;
            }
        }

        .profile-switches--smart-pos-account-summary--switch-offs, .profile-reduce-only-switches--summary--switched-on-size {
            @include border-color-5;
        }
    }
}