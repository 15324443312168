@import '../../../styles/variables';

.toggle {
    position: relative;
    cursor: pointer;
    width: 100px;
    height: 28px;

    &.on {
        .toggle {
            &--track {
                @include background-color-6;
            }
            &--button {
                left: 20px;
            }
        }
    }

    &.disabled {
        cursor: not-allowed;
        div, span {
            cursor: not-allowed !important;
        }
    }

    &--track {
        position: relative;
        width: 46px;
        height: 100%;
        background: lightslategray;
        border-radius: 20px;
        float: left;
        box-shadow: inset 0px 0px 3px black;
        transition: background 0.3s;
    }

    &--button {
        position: absolute;
        height: 24px;
        width: 24px;
        background: #ddd;
        border-radius: 100%;
        left: 2px;
        top: 2px;
        box-shadow: 0px 0px 5px black;
        float: left;
        transition: left 0.2s;
    }

    &--label {
        line-height: 28px;
        margin-left: 7px;
        font-size: 13px;
        cursor: pointer;
        width: 40px;
        float: left;
        font-weight: bold;

        &.is-empty-value {
            color: #fd818a;
            margin-left: 3px;
        }
    }
}