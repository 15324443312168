@import '../styles/variables';

.page {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--body {
        position: relative;
        height: 100%;
    }
}

.profile-page {
    &--body {
        display: flex;
        width: 100%;
    }
    &--profiles {
        position: relative;
        width: 100%;
    }
    &--messages {
        width: 280px;
        min-width: 280px;
    }
}

.trade-page {

    &--body {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &--left, &--center {
        position: relative;
        min-width: 280px;
        width: 280px;
        height: 100%;
        border-right: 1px solid black;
        overflow-y: auto;
    }

    &--account-info {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &--draggable-line {
            position: absolute;
            width: 100%;
            height: 6px;
            top: 50%;
            transform: translateY(-3px);
            z-index: 2;
            background: transparent;
            cursor: ns-resize;
        }
    }

    &--account-balance {
        position: absolute;
        top: 0px;
        height: 50%;
        width: 100%;
    }   

    &--position {
        position: absolute;
        top: 50%;
        bottom: 0;
        width: 100%;
    }

    &--center {
        min-width: 310px;
        width: 310px;

        .trade-page--account-info {
            height: 90%;
            &--draggable-line {
                display: none;
            }
        }
    }

    &--right {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &--order-editor, &--order-book, &--account-balance, &--position {
        &--title {
            position: relative;
            font-size: 14px;
            font-weight: bold;
            @include background-color-3;
            line-height: 28px;
            height: 28px;
            text-indent: 10px;
        }
    }

    &--order-editor, &--order-book {
        &--main {
            padding: 10px;
        }

        &--title {
            &--connection {
                position: absolute;
                height: 100%;
                right: 0;
                top: 0;
            }

            .socket-connection {
                line-height: unset;
                margin-right: 11px;
            }
        }
    }

    &--account-balance, &--position {
        display: flex;
        flex-direction: column;
        &--main {
            position: relative;
            height: 100%;
            overflow: auto;
        }
    }

    &--account-balance {
        .virtualized-dynamic-height-list--item {
            &:not(:last-child) {
                border-bottom: 1px solid black;
            }
        }
        .account-balance {
            &--empty-message {
                font-size: 14px;
                font-weight: bold;
                margin: 5px 0px;
            }
            &--spot-item, &--margin--item,&--cross-margin--item, &--cross--item, &--future--item, &--swap--item, &--option--item {
                margin-bottom: 0px;
            }
        }
        .account-asset-item {
            padding: 10px;
        }
        .cross-margin-account-balance-item {
            .search-select--search-input {
                width: 100%;
            }
        }
    }

    &--position {
        .virtualized-dynamic-height-list--item {
            &:not(:last-child) {
                border-bottom: 1px solid black;
            }
        }
        .position-item {
            padding: 10px;
        }
    }

    &--empty-data {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin: 10px 0px;
    }
}