.profile-bulk-upload-editor {
    position: relative;
    width: 100%;
    height: 100%;
    background: #125656;

    ::-webkit-scrollbar{
        width: 13px;
        height: 13px;
        background-color: #053333;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #267d7d;
    }

    &--header {
        position: relative;
    }

    &--close-button {
        position: absolute;
        right: 15px;
        top: 15px;
        background: none;
        font-size: 21px;
        color: #ddd;
        &:hover {
            color: white;
        }
    }

    &--save-button, &--reset-button, &--sync-button {
        float: left;
        width: 89px;
        font-size: 14px;
        font-weight: bold;
        margin: 10px 5px 0px;
        height: 29px;
        border-radius: 2px;
    }

    &--sync-button {
        width: 175px;
        background: #a79c4f;
        color: #eee;

        &:hover {
            color: white;
        }
    }

    &--save-button {
        background: #5d8cb5;
        color: #eee;

        &:hover {
            color: white;
        }
    }

    &--reset-button {
        margin-left: 10px;
        color: #276b6b;
        background: #eee;

        &:hover {
            color: #428686;
        }
    }

    &--table-wrapper {
        position: absolute;
        width: 100%;
        top: 50px;
        bottom: 0px;
        overflow: auto;
    }

    thead {
        th {
            position: sticky;
            top: 0;
            background: #011f1f;
            font-size: 12px;
            z-index: 2;
            text-align: left;
            padding: 8px 7px;
            line-height: 15px;

            &:first-child {
                position: sticky;
                left: 0px;
                z-index: 10;
                text-align: right;
            }
        }
    }

    &--table {
        position: relative;
        border-collapse: collapse;

        &--section-title {
            position: relative;

            th, td {
                background: #053333 !important;
                position: sticky !important;
                top: 58px !important;
                padding: 4px 13px !important;
                text-align: left !important;
                z-index: 5;
                border-bottom: 1px solid #021f1e;
            }

            th {
                font-size: 14px !important;
                color: white !important; 
            }

            td {
                border-right: 1px solid #021f1e; 
            }
        }
    }

    &--symbol-row, &--strategy-row {
        font-weight: bold;

        th {
            position: sticky;
            left: 0px;
            z-index: 1;
            font-size: 13px;
            text-align: right;
        }
    }

    &--symbol-row {
        color: white;

        th, td {
            position: sticky;
            top: 81px;
            z-index: 3;
            background: #5189b9;
            padding: 0;
        }

        th {
            background: #3a6b94;
            z-index: 4;
            padding-right: 7px;
        }

        &--names {
            position: relative;
            display: flex;
        }

        &--symbol-name {
            float: left;
            font-size: 12px;
            max-width: 172px;
            text-indent: 7px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            line-height: 27px;
            border-right: 1px solid #224f73;

            &:last-child {
                border-right: none;
            }
        }

        td {
            border-bottom: 1px solid #021f1e;
            border-right: 1px solid #021f1e;
        }

        &--popup {
            background: #6b9494f0;
            margin: 3px 0px;
            box-shadow: 0px 0px 5px #001717;
            border-radius: 2px;

            .single-limit {
                padding: 4px 7px;
                &--data-row {
                    font-weight: bold;
                    font-size: 13px;
                    &--name {
                        float: left;
                        opacity: 0.7;
                    }
                    &--value {
                        float: right;
                    }
                }
            }
        }
    }

    &--strategy-row {
        background: transparent;
        color: white;

        th, td {
            border-bottom: 1px solid #010e0e;
        }

        th {
            background: #044040;
            padding: 3px 7px;
        }

        td {
            font-size: 13px;
            text-indent: 7px;
            border-right: 1px solid #010e0e;
        }
    }

    &--strategy-value {
        &.DIME {
            color: #8dbde8;
        }

        &.LOCKDIME {
            color: #ac83ea;
        }

        &.COG {
            color: #9bd49b;
        }
    
        &.HIT {
            color: #ea7eae;
        }

        &.MULTILEVEL {
            color: gold;
        }

        &.OPTMULTILEVEL {
            color: #61bf9e;
        }

        &.MAKE {
            color: #d9ac71;
        }
    }

    &--updating-list, &--syncing-list {
        position: absolute;
        z-index: 99;
        background: #052121de;
        top: 50px;
        left: 10px;
        box-shadow: 0px 0px 3px black;
        padding: 8px 13px;
        border-radius: 3px;
        max-height: 70%;
        overflow: auto;
        min-width: 150px;

        &--header {
            position: relative;
        }

        &--title {
            position: relative;
            font-size: 14px;
            font-weight: bold;
        }

        &--close-button {
            position: absolute;
            right: 0px;
            background: none;
            padding: 1px;
            color: #ddd;
            font-size: 14px;
            top: 1px;

            &:hover {
                color: white;
            }
        }

        &--no-change-message {
            font-size: 14px;
            color: #b5d6d5;
        }

        &--main {
            position: relative;
            margin-top: 5px;
        }

        &--item {
            position: relative;
            font-size: 13px;
            font-weight: bold;
            padding-right: 50px;
            margin: 2px 0px;
            color: #eee;
        }

        &--item-rolling-image {
            position: absolute;
            float: right;
            width: 17px;
            margin-left: 30px;
            top: 0;
            right: 0;
        }

        &--item-success-icon {
            position: absolute;
            right: 0;
            top: 2px;
            font-size: 15px;
            color: #6df3f0;
        }

        &--item-failed-icon {
            position: absolute;
            right: 0;
            top: 2px;
            font-size: 15px;
            color: #ef7e93;
        }

        &--error-popup {
            z-index: 99;
            background: #964d5de6;
            font-size: 13px;
            padding: 3px 7px;
            max-width: 200px;
            border-radius: 2px;
            box-shadow: 0px 0px 3px black;
            transform: translateY(-3px);
        }
    }
}

.profile-bulk-upload-param-row {
    &--param-name {
        text-align: right;
        font-size: 13px;
        white-space: nowrap;
        position: sticky;
        padding: 0px 7px 0px 13px;
        left: 0;
        background: #044040;
        z-index: 2;
        border-bottom: 1px solid #010d0d;
        color: #ddd;
        cursor: pointer;

        &:hover {
            color: white;
        }

        &.not-clickable {
            color: #ddd;
            cursor: default;
        }
    }

    &--symbol-params {
        display: flex;
        white-space: nowrap;
    }

    &--symbol-param {
        position: relative;
        border-right: 1px solid #010d0d;
        width: 172px;

        &:last-child {
            border-right: none;
        }
    }

    &--param {
        &.bulk-editable {
            background: #a79c4f;

            .toggle--label, .search-select--current-option--name, .profile-bulk-upload-param-row--param--number-input {
                color: white;
            }
        }

        &--number-input {
            position: relative;
            font-size: 13px;
            color: white;
            font-weight: bold;
            background: transparent;
            width: calc(100% - 16px);
            padding: 0px 8px;
            height: 23px;
            display: block;
            cursor: text;
        }

        .toggle {
            position: relative;
            height: 23px;
            left: 5px;
            top: 3px;
            width: 60px;

            &--button {
                height: 13px;
                width: 13px;
            }

            &--track {
                height: 17px;
                width: 35px;
            }

            &--label {
                font-weight: bold;
                font-size: 13px;
                line-height: 17px;
                margin-left: 5px;
                width: 10px;
            }
        }

        .search-select {
            ::-webkit-scrollbar {
                width: 3px;
                background-color: #053333;
                overflow: hidden;
            }
            &--current-option {
                font-size: 13px;
                font-weight: bold;
                text-indent: 7px;
                line-height: 25px;
            }

            &--options-wrapper {
                margin-left: 5px;
                margin-top: 3px;
            }

            &--option {
                line-height: 23px;
                font-weight: bold;
                background: #0b3a3a;
                padding: 0px 15px;

                &.focused-option, &:hover {
                    background: #1a7373;
                }
            }
        }

        &--array {
            position: relative;
            display: flex;
            width: 100%;

            &--section {
                position: relative;
                width: 50%;
                min-width: 50%;

                &.first-section {
                    border-right: 1px dashed #010d0d;
                }
            }
        }

        &--array-of-string-input {
            background: transparent;
            padding: 5px;
            padding-bottom: 0px;

            .array-of-string-input {
                &--value {
                    font-size: 13px;
                    margin-bottom: 5px;
                    margin-right: 5px;
                    gap: 3px;
                }
            }

            >input {
                margin-bottom: 5px;
                background: #082929;
                width: 100px;
            }
        }
    }

    &--data-block {
        white-space: nowrap;
        border-bottom: 1px solid #010e0e;
        border-right: 1px solid #010e0e;
        padding: 0px;
        height: 25px;

        &.vertical-align-top {
            vertical-align: top;
        }

        .profile-param {
            &--multiple-length-array {
                padding: 3px 8px 6px;

                &--add-element-button {
                    background: #479090;
                    padding: 1px 9px;
                }

                &--item--remove-button {
                    >svg {
                        margin-top: 0px;
                    }
                }

                &--inner-item {    
                    >span, >input {
                        margin: 0 !important;
                    }
    
                    >span {
                        font-size: 12px;
                        padding-right: 7px;
                    }
                    >input {
                        background: none;
                        width: 50px;
                        padding: 0px;
                        height: 17px;
                        color: white;
                    }
                }
            }            
        }
    }

    &--bulk-editor {
        position: fixed;
        margin: 5px;
        background: #021f1e;
        width: 180px;
        padding: 5px 9px 11px;
        color: #eee;
        box-shadow: 0px 0px 3px black;
        z-index: 100;

        &--title {
            float: left;
            font-size: 13px;
            font-weight: bold;
        }

        &--close-button {
            background: none;
            color: #eee;
            font-size: 15px;
            padding: 0;
            margin-top: 1px;
            float: right;
        }

        &--main {
            margin-top: 7px;
            .profile-bulk-upload-param-row--param {
                &--number-input {
                    background: #125656;
                }
                .toggle {
                    left: 0px;

                    &--label {
                        text-align: left;
                    }
                }
                .search-select {
                    &--current-option {
                        background: #125656;
                    }
                }
            }
            .profile-param {
                &--multiple-length-array {
                    &--item--remove-button {
                        transform: translateX(1px);
                    }
                }
            }
        }
    }

    &--symbol-filter {
        float: left;
        width: 100%;
        >input {
            width: 100%;
            display: block;
            padding: 0;
            text-indent: 0px;
            background: none;
            border-bottom: 1px solid #044040;
            cursor: text;
            color: #b7d8d7;
            font-weight: bold;
            font-size: 13px;
        }
    }
}

.profile-bulk-upload-status-row {

    th, td {
        position: sticky !important;
        top: 31px !important;
    }

    th {
        background: #044040 !important;
        padding: 6px 7px !important;
        cursor: pointer;

        &:hover {
            color: white;
        }
    }

    td {
        border-right: 1px solid #010d0d;
        background: #125656;
        z-index: 3;
        
        &:hover {
            .profile-bulk-upload-status-row--action-trigger {
                opacity: 1.0;
            }
        }
    }

    &--status-wrapper {
        position: relative;
        display: flex;
        margin: 0px 20px 0px 8px;
    }

    &--sort-button {
        float: left;
        background: transparent;
        border: 1px solid #6fa5a4;
        line-height: 10px;
        position: absolute;
        left: 9px;
        color: #6fa5a4;
        font-weight: bold;
        padding: 1px 10px 4px;
        top: 4px;
        border-radius: 2px;
        font-size: 12px;
        opacity: 0.8;

        &:hover {
            opacity: 1.0;
            background: #3f7b7a;
            color: white;
        }

        >svg {
            font-size: 12px;
            transform: translate(-3px, 2px);
        }
    }

    &--status {
        position: relative;
        display: flex;
        margin-right: 9px;

        &--bulb {
            width: 9px;
            height: 9px;
            background: #ce757c;
            position: absolute;
            border-radius: 50%;
            box-shadow: 0px 0px 3px #000000;

            &.started, &.resumed {
                background: #4dbbbb;
            }

            &.starting, &.stopping, &.resuming, &.pausing {
                background: #d2b775;
            }
        }

        &--label {
            margin-left: 14px;
            font-size: 12px;
            color: #eee;
            font-weight: bold;
        }

        &.crashed-status {
            color: #e87179;

            .profile-bulk-upload-status-row--status--label {
                color: inherit;
                margin-left: 4px;
            }
        }
    }

    &--action-trigger {
        position: absolute;
        right: 6px;
        background: none;
        font-size: 17px;
        padding: 0;
        color: #81a9a8;
        z-index: 2;
        opacity: 0;

        svg {
            transform: translateY(2px);
        }

        &:hover {
            color: #9dc3c2;
        }
    }

    &--data {
        &.selected {
            background: #a79c4f;
            
            .profile-bulk-upload-status-row--status--label {
                color: white;
            }

            .profile-bulk-upload-status-row--action-trigger {
                color: #094644;

                &:hover {
                    color: #021f1e;
                }
            }
        }
    }

    &--bulk-editor {
        position: fixed;
        margin: 5px 0px;
        background: #021f1e;
        width: 180px;
        color: #eee;
        box-shadow: 0px 0px 3px black;
        z-index: 100;
        overflow: hidden;
        border-radius: 3px;
        width: 180px;

        &--header {
            position: relative;
            border-bottom: 1px solid #1b9292;
            padding: 5px 9px 5px; 
        }

        &--title {
            float: left;
            font-size: 13px;
            font-weight: bold;
        }

        &--close-button {
            background: none;
            color: #eee;
            font-size: 15px;
            padding: 0;
            margin-top: 1px;
            float: right;
        }
    }
}

.profile-bulk-upload-ref-symbol-row {
    background: transparent;
    color: white;

    th, td {
        border-bottom: 1px solid #010e0e;
        vertical-align: top;
    }

    th {
        position: sticky;
        z-index: 1;
        left: 0px;
        background: #044040;
        padding: 3px 7px;
        text-align: right;
        font-size: 12px;
        line-height: 19px;
        color: #ddd;
        cursor: pointer;
        &:hover {
            color: white;
        }
    }

    td {
        font-size: 13px;
        border-right: 1px solid #010e0e;
        vertical-align: top;
    }

    &--symbol-blocks {
        position: relative;
        display: flex;
    }

    &--symbol-block {
        max-width: 172px;

        &:not(:last-child) {
            border-right: 1px dotted #010e0e;
        }
    }

    &--item {
        position: relative;
        padding-bottom: 7px;

        &:not(:last-child) {
            border-bottom: 1px dotted #010e0e;
        }

        &.is-bulk-editing {
            background: #a79c4f;

            .profile-bulk-upload-ref-symbol-row--item-param--title {
                color: #125656;
            }
            .search-select--current-option {
                border-color: #125656;
            }
        }

        &--header {
            position: relative;
            display: flex;
        }

        &--symbol-name-search-select-wrapper {
            width: 100%;
        }

        &--remove-ref-symbol-button {
            background: none;
            padding: 0px 4px;
            font-size: 13px;
            color: #ddd;
            >svg {
                transform: translate(-2px, 2px);
            }
            &:hover {
                color: white;
            }
        }

        &--symbol-name-popup {
            font-size: 12px;
            background: #6b9494ba;
            font-weight: bold;
            padding: 2px 6px;
            box-shadow: 0px 0px 2px #044040;
            border-radius: 2px;
            max-width: 161px !important;
            line-height: 15px;
        }
    }

    .search-select {
        margin: 0px 7px;
        width: auto;

        &--current-option {
            border-bottom: 1px solid #6b9494;
            font-size: 12px;
            color: white;
            font-weight: bold;
            line-height: 21px;
        }

        &--input-icon {
            right: 0px;
        }

        &--options-wrapper {
            ::-webkit-scrollbar {
                width: 3px;
                background-color: #053333;
                overflow: hidden;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: none;
                box-shadow: none;
                background-color: #368686;
            }
        
        }

        &--option {
            font-size: 12px;
            padding: 0px 7px;
        }
    }

    &--add-ref-symbol-button {
        font-size: 12px;
        font-weight: bold;
        background: #6b9494;
        color: #ddd;
        margin: 5px 9px 7px;
        padding: 1px 9px;
        border-radius: 1px;

        &:hover {
            color: white;
        }

        &:disabled {
            color: #ddd;
        }
    }

    &--item-param {
        position: relative;
        padding: 0px 7px;

        &--title {
            margin-top: 4px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #8ec1c1;
        }

        .profile-param {
            margin-top: 3px;
            &--array {
                position: relative;
                display: flex;
                width: 100%;

                &--section {
                    width: 50%;
                    &:not(:last-child) {
                        border-right: 1px dashed #010e0e;
                    }
                    &:not(:first-child) {
                        margin-left: 7px;
                    }
                }
            }

            input {
                width: 100%;
                background: transparent;
                font-size: 13px;
                font-weight: bold;
                color: white;
                cursor: text;
                height: 17px;
                padding: 0px;
            }
        }

        .toggle {
            height: 17px;
            width: auto;

            &--track {
                width: 35px;
            }
            &--button {
                width: 13px;
                height: 13px;
            }
            &--label {
                line-height: 17px;
                font-size: 12px;
                color: white;
                font-weight: bold;
                width: auto;
                margin-left: 4px;
            }
        }
    }

    &--bulk-editor {
        position: fixed;
        background: #021f1e;
        width: 180px;
        padding: 5px 9px 11px;
        color: #eee;
        box-shadow: 0px 0px 3px black;
        z-index: 100;

        &--header {
            >span {
                float: left;
                font-size: 13px;
                font-weight: bold;
            }
        }
        &--close-button {
            background: none;
            color: #eee;
            font-size: 14px;
            padding: 0;
            margin-top: 1px;
            float: right;

            &:hover {
                color: white;
            }
        }

        &--param {
            margin-top: 7px;
            &:first-child {
                margin-top: 3px;
            }
        }

        &--param-name {
            white-space: nowrap;
            font-size: 12px;
            color: #8caba9;
            font-weight: bold;
        }

        input {
            position: relative;
            font-size: 13px;
            color: white;
            font-weight: bold;
            background: #125656;
            text-indent: 8px;
            height: 23px;
            width: 100%;
            cursor: text;
        }

        .profile-param {
            margin-top: 3px;
            &--array {
                position: relative;
                display: flex;
                width: 100%;
                &--section {
                    position: relative;
                    width: 50%;
                    &:not(:first-child) {
                        border-left: 1px solid #02201e;
                    }
                }
            }
        }
        .toggle {
            height: 17px;
            width: auto;

            &--track {
                width: 35px;
            }
            &--button {
                width: 13px;
                height: 13px;
            }
            &--label {
                line-height: 17px;
                font-size: 12px;
                color: white;
                font-weight: bold;
                width: auto;
                margin-left: 4px;
            }
        }
    }

    &--row-selectors {
        margin-top: 9px;
        &--title {
            font-size: 13px;
            font-weight: bold;
            border-bottom: 1px solid #115656;
        }
        &--list {
            display: flex;
            flex-direction: column;
        }
        &--item {
            margin-top: 3px;
            font-size: 13px;
            padding: 1px 0px;
            font-weight: bold;
            background: #022020;
            border: 1px solid #6b9493;
            color: #ddd;
            border-radius: 2px;
            &:hover {
                background: #115656;
                color: white;
            }
        }
    }

    &--symbol-filter {
        float: left;
        width: 100%;
        >input {
            width: 100%;
            display: block;
            padding: 0;
            text-indent: 0px;
            background: none;
            border-bottom: 1px solid #044040;
            cursor: text;
            color: #b7d8d7;
            font-weight: bold;
            font-size: 13px;
        }
    }
}