
@import '../../styles/variables';

.workspace-tabs {
    display: flex;
    height: 32px;
    line-height: 32px;
    font-weight: bold;
    font-size: 14px;
    @include background-color-3;

    &--list {
        display: flex;
        overflow: auto;
    }

    &--add-button {
        width: 32px;
        padding: 0;
        font-size: 16px;
        margin: 0;
        @include button-style-3;
        border-radius: 0px;

        >svg {
            transform: translateY(2px);
        }
    }
}

.workspace-tab {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    @include background-color-2;
    color: #eee;
    cursor: pointer;
    white-space: nowrap;
    border-right: 1px solid black;
    font-size: 14px;
    font-weight: bold;

    &:hover {
        @include background-color-5;
    }

    &.highlight {
        @include background-color-5;
        color: white;
    }

    >label {
        cursor: inherit;
    }

    >input {
        cursor: text;
        font-size: 14px;
        background: transparent;
        font-weight: bold;
        border-bottom: 1px solid black;
        color: white;
        padding: 1px 0px;
    }

    &--remove-button {
        background: none;
        opacity: 0.4;
        padding: 0;
        color: white;
        margin: 0;
        margin-left: 10px;
        height: 100%;
        font-size: 14px;

        &:hover {
            opacity: 1 !important;
        }

        >svg {
            transform: translateY(2px);
        }
    }

    &--remove-popup {
        box-shadow: 0px 0px 3px black;
        padding: 10px 15px 15px;
        @include background-color-1;
        font-weight: bold;
        font-size: 16px;

        &--message {
            line-height: 24px;
        }

        &--buttons {
            display: flex;
            margin-top: 10px;

            button {
                padding: 0;
                margin: 0px 5px;
                width: 50%;
                font-size: 14px;
                font-weight: bold;
                height: 27px;
                line-height: 27px;

                &:first-child {
                    margin-left: 0px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &.remove {
                    @include button-style-1;
                }

                &.cancel {
                    @include button-style-2;
                }
            }
        }
    }
}

.workspace-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &--layout {
        position: relative;
        height: 100%;
    }

    &--saving-message {
        z-index: 2;
        position: absolute;
        color: white;
        top: 40px;
        right: 20px;
        font-size: 13px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        height: 24px;
        line-height: 24px;
        padding: 0px 10px;

        &--dot {
            display: block;
            width: 9px;
            height: 9px;
            @include background-color-10;
            border-radius: 10px;
            margin-right: 8px;
        }
    }
}

.workspace-layout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;

    &--add-component-button {
        position: absolute;
        bottom: 20px;
        left: 20px;
        @include background-color-3;
        color: white;
        font-size: 24px;
        padding: 0;
        width: 44px;
        height: 44px;
        z-index: 2;
        box-shadow: 0px 0px 3px black;
        border-radius: 50%;
        opacity: 0.6;
        transition: all 0.3s;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            transform: translateY(4px);
        }
    }

    &--grid-wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        margin: 0;
        z-index: 1;
        overflow: auto;
    }

    &--grid {
        &--item {
            .react-resizable-handle {
                &::after {
                    border-color: white !important;
                    opacity: 0.5;
                }
            }
        }
    }
}

.workspace-component {
    position: absolute;
    width: 100%;
    height: 100%;
    @include background-color-3;
    overflow: hidden;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        display: flex;
        line-height: 32px;
        height: 28px;
        min-height: 28px;
        @include background-color-1;
        white-space: nowrap;
        border-bottom: 1px solid black;
        @include border-color-4;

        >label {
            font-family: 'Orator';
            width: 100%;
            font-weight: bold;
            font-size: 16px;
            text-indent: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: move;
            transition: all 0.3s;

            &:hover {
                @include background-color-6;
            }
        }
    }

    &--selector-popup--trigger, &--remove-button {
        padding: 0;
        margin: 0px;
        width: 32px;
        font-size: 14px;
        border-radius: 0;
        border-left: 1px solid;
        color: #aaa;
        @include background-color-3;
        @include border-color-1;
        transition: all 0.3s;

        &:hover {
            color: white;
            @include background-color-7;
        }
        
        >svg {
            transform: translateY(2px);
        }
    }

    &--selector-popup {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        overflow: hidden;
        
        &--search {
            @include background-color-2;
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            padding: 0px 10px;
            color: white;
            font-weight: bold;
            cursor: text;
            border-bottom: 1px solid black;
        }
        &--list {
            position: relative;
            max-height: 800px;
            overflow-y: auto;
        }

        &--item {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            text-align: left;
            height: 30px;
            padding: 0px 10px;
            @include background-color-8;
            color: #ddd;
            transition: all 0.3s;

            &:not(:last-child) {
                border-bottom: 1px solid black;
            }

            &:hover {
                @include background-color-5;
                color: white;
            }

            >svg {
                margin-right: 8px;
                font-size: 16px;
            }

            >label {
                cursor: pointer;
            }
        }
    }

    &--body {
        position: relative;
        width: calc(100% - 20px);
        height: 100%;
        overflow: auto;
        padding: 10px;
    }
}