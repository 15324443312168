@import '../../../styles/_variables';

.checkbox {
    position: relative;
    width: 25px;
    height: 25px;
    @include background-color-6;
    border-radius: 2px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }

    &:hover {
        box-shadow: inset 0px 0px 3px black;
    }

    >svg {
        position: absolute;
        color: #eee;
    }
}