@import '../../styles/variables';

.vault-container {
    position: relative;
    width: 100%;
    min-width: 720px;
    font-weight: bold;
    font-size: 16px;

    &--header {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        @include background-color-7;
        height: 32px;
        padding: 0px 16px;
    }

    &--block-number {
        display: flex;
        align-items: center;

        >label {
            opacity: 0.7;
            font-size: 13px;
            white-space: nowrap;
        }

        >div {
            margin-left: 8px;
        }
    }

    &--wallet {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: auto;

        >button {
            @include button-style-2;
            margin-left: 10px;
            font-size: 13px;
            font-weight: bold;
        }

        &--network, &--account {
            display: flex;
            align-items: center;

            >label {
                opacity: 0.7;
                font-size: 13px;
                white-space: nowrap;
            }

            >div {
                margin-left: 8px;
            }

            >button {
                @include button-style-2;
                margin-left: 10px;
                font-size: 13px;
            }
        }

        &--account {
            margin-left: 10px;
        }
    }

    &--overview {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    &--contracts {
        position: relative;

        &--popup {
            @include background-color-2;
            padding: 10px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;

            &--trigger {
                display: flex;
                align-items: center;
                @include button-style-2;
                font-weight: bold;

                >svg {
                    margin-left: 10px;
                    transform: translateY(1px);
                }
            }
        }

        &--list {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
        }

        &--item {
            display: flex;
            align-items: center;

            &--select-button {
                text-align: left;
                padding: 0;
                margin: 0;
                font-size: 14px;
                font-weight: bold;
                background: transparent;
                color: #ddd;

                &:hover {
                    color: white;
                    text-decoration: underline;
                }
            }

            &--remove-button {
                margin-left: 10px;
                background: transparent;
                color: white;
                opacity: 0.3;
                transition: all 0.3s;

                &:hover {
                    opacity: 1.0;
                }

                >svg {
                    transform: translateY(2px);
                }
            }
        }

        &--add {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 10px;

            >input {
                width: 100%;
                cursor: text;
                margin-right: 10px;
                padding: 0px 5px;
                height: 24px;
                color: white;
                font-weight: bold;
                @include background-color-1;
            }

            >button {
                @include button-style-5;
                height: 24px;
            }
        }
    }

    &--basic-info {
        font-size: 14px;
        border-right: 1px dashed;
        @include border-color-2;
        padding-right: 20px;

        >div {
            display: flex;
            align-items: center;
            vertical-align: top;
            line-height: 22px;

            >label {
                font-size: 13px;
                opacity: 0.7;
                min-width: 75px;
            }

            >div {
                user-select: text;
            }
        }
    }

    &--traders {
        position: relative;
        width: 100%;
        margin-left: 20px;
        align-self: flex-start;
    }

    table {
        position: relative;
        width: 100%;
        border-collapse: collapse;

        >thead {
            @include background-color-1;

            th {
                font-size: 13px;
                text-align: left;
                height: 24px;
                opacity: 0.7;
            }
        }

        >tbody {
            a {
                color: #7faaca;
                &:hover {
                    text-decoration: underline;
                }
            }

            tr {
                transition: all 0.3s;
                &:hover {
                    background: #062033;
                }
            }

            td {
                font-size: 16px;
                line-height: 32px;
            }
        }

        th, td {
            padding: 0px 16px;
            white-space: nowrap;
        }
    }
}

.vault-traders {
    position: relative;
    font-size: 14px;

    &--header {
        display: flex;
        border-bottom: 1px solid;
        @include border-color-2;
        padding-bottom: 5px;

        >label {
            opacity: 0.7;
        }

        &--loading {
            width: 18px !important;
            height: 18px !important;
        }

        &--buttons {
            margin-left: auto;
            display: flex;
            gap: 10px;

            >button {
                @include button-style-5;
            }
        }
    }

    &--list {
        margin-top: 5px;

        &--item {
            display: inline-block;
            margin-right: 10px;
            line-height: 22px;

            >a {
                color: #7faaca;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &--set-traders-popup {
        @include background-color-2;   
        padding: 10px;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        width: 336px;

        >textarea {
            @include background-color-1;
            font-size: 14px;
            padding: 5px 8px;
            font-weight: bold;
            color: white;
            cursor: text;
            width: 320px;
            resize: none;
            height: 160px;
            line-height: 20px;
            border: none
        }

        >button {
            @include button-style-3;
            margin-top: 10px;
            font-size: 16px;
            height: 30px;
        }

        &--message {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;

            >p {
                margin: 0;
                font-size: 14px;
                line-height: 20px;

                >a {
                    color: #7faaca;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            >button {
                margin-left: auto;
                background: transparent;
                color: #ddd;
                transition: all 0.3s;

                >svg {
                    transform: translateY(3px);
                }

                &:hover {
                    color: white;
                }
            }
        }
    }
}

.vault-tokens {
    position: relative;
    margin-top: 10px;
    
    &--header {
        position: relative;
        display: flex;
        align-items: center;
        line-height: 40px;
        font-size: 18px;
        padding: 0;
        border-bottom: 2px solid;
        @include border-color-2;

        &--buttons {
            position: relative;
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 10px;

            >button {
                font-size: 14px;
                padding: 5px 12px;
                
                &:disabled {
                    opacity: 0.6;
                }
            }
        }
    }

    &--add-token-button {
        @include button-style-5;
    }

    &--withdraw-everything-button {
        @include button-style-3;
    }

    &--add-popup {
        @include background-color-2;   
        padding: 10px;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;

        >input {
            display: block;
            @include background-color-1;
            cursor: text;
            color: white;
            font-size: 16px;
            font-weight: bold;
            padding: 8px;
            min-width: 220px;
        }

        >button {
            @include button-style-3;
            margin-top: 10px;
            width: 100%;
            padding: 5px 0px;
            font-size: 16px;

            &:disabled {
                opacity: 0.6;
            }
        }
    }

    &--table {
        position: relative;
        margin-top: 10px;

        tr {
            &:hover {
                button {
                    opacity: 1.0;
                }
            }
        }
    }

    &--withdraw-popup {
        @include background-color-2;
        width: 250px;
        padding: 10px;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;

        &--input-wrapper {
            @include background-color-1;
            display: flex;
            align-items: center;
            padding: 8px;

            >input {
                background: transparent;
                cursor: text;
                color: white;
                font-size: 16px;
                font-weight: bold;
                width: 100%
            }

            &--max-button {
                @include button-style-2;
                border-radius: 3px;
                font-size: 14px;
            }
        }

        &--trigger {
            @include button-style-5;
            height: 21px;
            opacity: 0.3;
        }

        &--remove-button {
            position: relative;
            margin-left: 10px;
            background: transparent;
            color: #ddd;
            opacity: 0.3;
            transition: all 0.3s;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(2px);
            }
        }

        &--error-message {
            background-color: #0924398a;
            color: #ccc;
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
            padding: 5px 10px;
        }

        >button {
            @include button-style-3;
            margin-top: 10px;
            width: 100%;
            padding: 5px 0px;
            font-size: 16px;

            &:disabled {
                opacity: 0.6;
            }
        }
    }

    &--error-message {
        display: flex;
        align-items: flex-start;
        background: #0924398a;
        margin-top: 10px;
        padding: 8px 12px;

        >p {
            margin: 0;
            font-size: 14px;
            width: 100%;
        }

        >button {
            color: white;
            background-color: transparent;
            vertical-align: middle;
            display: flex;
            align-items: center;
            font-size: 16px;
            opacity: 0.6;

            &:hover {
                opacity: 1.0;
            }
        }
    }
}

.vault-actions {
    position: relative;
    margin-top: 20px;
    padding-bottom: 20px;

    &--header {
        position: relative;
        display: flex;
        align-items: center;
        line-height: 40px;
        font-size: 18px;
        border-bottom: 2px solid;
        @include border-color-2;

        &--tabs {
            margin-left: auto;

            >button {
                background: transparent;
                color: white;
                border: 1px solid;
                @include border-color-6;
                border-radius: 0px;
                padding: 5px 12px;
                opacity: 0.6;
                font-weight: bold;
                transition: all 0.3s;

                &.selected {
                    opacity: 1.0;
                    @include background-color-6;
                }

                &:first-child {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                &:last-child {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }

    &--search-wrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;

        >input {
            width: 240px;
            font-size: 14px;
            padding: 6px 8px;
            @include background-color-2;
            font-weight: bold;
            color: white;
            cursor: text;
        }

        >button {
            margin-left: auto;
            @include button-style-5;
            height: 27px;
            padding: 0px 12px;
        }
    }

    &--table {
        position: relative;
        margin-top: 10px;

        td {
            button {
                opacity: 0.3;
            }
        }

        tr {
            &:hover {
                button {
                    opacity: 1.0;
                }
            }
        }
    }

    &--added-tag {
        margin-left: 10px;
        padding: 2px 10px;
        border-radius: 30px;
        font-size: 13px;
        @include background-color-6;
    }

    &--contract-row {
        td {
            @include background-color-7;
            line-height: 24px !important;
            >div {
                font-size: 14px;
                margin: 2px 0px;
                >label {
                    margin-left: 10px;
                    padding: 2px 10px;
                    border-radius: 30px;
                    font-size: 13px;
                    @include background-color-5;
                }
            }
        }

    }

    &--execute-popup {
        @include background-color-2;   
        padding: 10px;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        font-weight: bold;

        &--name {
            font-size: 14px;
            border-bottom: 1px solid;
            line-height: 20px;
            padding-bottom: 5px;
            @include border-color-6;
        }

        &--inputs {
            margin-top: 10px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 10px;

            &--item {
                display: flex;
                align-items: center;
    
                >label {
                    font-size: 14px;
                    margin-right: 10px;

                    >span {
                        font-size: 13px;
                        @include background-color-6;
                        margin-right: 5px;
                        padding: 2px 5px;
                    }
                }

                &--input-wrapper {
                    position: relative;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                    >input {
                        display: block;
                        @include background-color-1;
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        padding: 5px 8px;
                        cursor: text;
                        width: 120px;
                    }
                }

                &--decimals-input {
                    margin-right: 10px;
                    margin-left: 10px;
                    >label {
                        font-size: 14px;
                        opacity: 0.6;
                    }
                    >input {
                        @include background-color-1;
                        font-size: 16px;
                        color: white;
                        padding: 5px 8px;
                        font-weight: bold;
                        width: 20px;
                        cursor: text;
                        margin-left: 5px;
                    }
                }
            }
        }


        &--error-message {
            background-color: #0924398a;
            color: #ccc;
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
            padding: 5px 10px;
            white-space: pre;
        }

        >button {
            @include button-style-3;
            margin-top: 10px;
            width: 100%;
            max-width: 160px;
            padding: 5px 0px;
            font-size: 16px;
            float: right;

            &:disabled {
                opacity: 0.6;
            }
        }
    }

    &--footer {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
    }

    &--messages {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    &--message {
        background-color: #0924398a;
        color: #ccc;
        font-size: 14px;
        font-weight: bold;
        padding: 5px 10px;
        white-space: pre;
        display: flex;

        >button {
            background: transparent;
            color: white;
            margin-left: 10px;
            display: flex;
            align-items: center;
            opacity: 0.6;

            &:hover {
                opacity: 1.0;
            }
        }
    }

    &--add-button, &--remove-button {
        margin-left: auto;
        @include button-style-3;
        height: 30px;
        padding: 0px 10px;
        font-size: 14px;

        &:disabled {
            opacity: 0.6;
        }
    }
}

.vault-execute-popup {
    @include background-color-2;   
    padding: 10px;
    box-shadow: 0px 0px 3px black;
    border-radius: 3px;
    font-weight: bold;

    &--name {
        font-size: 14px;
        border-bottom: 1px solid;
        line-height: 20px;
        padding-bottom: 5px;
        @include border-color-6;
    }

    &--trigger {
        @include button-style-5;
        height: 21px;
    }

    &--inputs {
        margin-top: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &--item {
            display: flex;
            align-items: center;

            &--type {
                font-size: 13px;
                @include background-color-6;
                margin-right: 5px;
                padding: 2px 5px;
            }

            >label {
                font-size: 14px;
                margin-right: 10px;
            }

            &--input-wrapper {
                position: relative;
                margin-left: auto;
                display: flex;
                align-items: center;
                white-space: nowrap;

                >input {
                    display: block;
                    @include background-color-1;
                    color: white;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 5px 8px;
                    cursor: text;
                    width: 120px;
                }
            }

            &--decimals-input {
                margin-right: 10px;
                margin-left: 10px;
                >label {
                    font-size: 14px;
                    opacity: 0.6;
                }
                >input {
                    @include background-color-1;
                    font-size: 16px;
                    color: white;
                    padding: 5px 8px;
                    font-weight: bold;
                    width: 20px;
                    cursor: text;
                    margin-left: 5px;
                }
            }
        }
    }


    &--message {
        background-color: #0924398a;
        color: #ccc;
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
        padding: 5px 10px;
        white-space: pre;
        display: flex;
        align-items: flex-start;
        line-height: 20px;

        >div {
            margin-right: 10px;
            >a {
                color: #7faaca;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        >button {
            margin-left: auto;
            background: transparent;
            color: white;
            display: flex;
            align-items: center;
            opacity: 0.6;
            transition: all 0.3s;
            height: 20px;
            
            &:hover {
                opacity: 1.0;
            }
        }
    }

    >button {
        @include button-style-3;
        margin-top: 10px;
        width: 100%;
        max-width: 160px;
        padding: 5px 0px;
        font-size: 16px;
        float: right;

        &:disabled {
            opacity: 0.6;
        }
    }
}

.vault-synthetix-perps {
    position: relative;
    margin-top: 20px;

    &--header {
        position: relative;
        display: flex;
        align-items: center;
        line-height: 40px;
        font-size: 18px;
        padding: 0;
        border-bottom: 2px solid;
        @include border-color-2;
    }

    &--table {
        position: relative;
        margin-top: 10px;

        td {
            >button {
                margin-left: 10px;
                opacity: 1;
            }
        }

        tr {
            &:hover {
                .vault-synthetix-perps--row-action {
                    opacity: 1.0;
                }
            }
        }
    }

    &--contract {
        >span {
            margin-left: 10px;
            padding: 2px 10px;
            border-radius: 30px;
            font-size: 13px;
            @include background-color-5;
        }
    }

    &--row-action {
        display: flex;
        align-items: center;
        gap: 10px;
        opacity: 0.3;
    }
}