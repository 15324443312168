@import '../../../styles/variables';

.error-boundary-fallback {
    position: relative;
    font-weight: bold;
    padding: 10px;

    &--title {
        font-size: 18px;
    }

    &--message {
        margin-top: 10px;
        font-size: 16px;
        color: #fd818a;
    }

    >button {
        @include button-style-5;
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        height: 32px;
        padding: 0px 10px;
    }
}