@import '../../styles/variables';

.option-delta-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &--section {
        position: relative;
        width: 100%;
        padding-bottom: 11px;

        &--title {
            font-size: 14px;
            line-height: 24px;
            background: #022f2f;
            font-weight: bold;
            text-indent: 11px;
        }
    }

    &--symbol-delta-table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        font-weight: bold;

        thead {
            background: #075d5d;
            font-size: 13px;
            th {
                padding: 3px 11px;
                text-align: left;
                color: #e4f3f3;

                &.align-right {
                    text-align: right;
                }
            }
        }

        tbody {
            td {
                font-size: 15px;
                padding: 3px 11px;
                text-align: left;
                &.align-right {
                    text-align: right;
                }
            }
        }
    }

    &--coin-delta {
        position: relative;
        ::-webkit-scrollbar{
            height: 9px;
        }

        &--item {
            position: relative;
            margin: 7px 0px 14px;
            font-weight: bold;

            &--header {
                position: relative;
                padding: 0px 11px;
                line-height: 27px;

                &--coin, &--net-pos {
                    float: left;
                }

                &--coin {
                    font-size: 17px;
                }

                &--net-pos {
                    margin-left: 11px;
                    font-size: 15px;
                    >span {
                        opacity: 0.8;
                    }
                }

                &--timestamp {
                    float: right;
                    font-size: 13px;
                    opacity: 0.8;
                }
            }

            &--main {
                overflow: auto;
            }

            &--table {
                position: relative;
                border-collapse: collapse;
                min-width: 100%;

                thead {
                    background: #075d5d;
                    font-size: 13px;
                    line-height: 15px;
                    th {
                        padding: 3px 5px;
                        color: #e4f3f3;
                        &:first-child {
                            position: sticky;
                            left: 0px;
                            z-index: 3;
                            background: #075d5d;
                        }
                        &:not(:last-child) {
                            border-right: 1px solid #022f2f;
                        }
                    }
                }
                tbody {
                    th {
                        position: sticky;
                        left: 0px;
                        z-index: 3;
                        background: #0a4a4a;
                        border-right: 1px solid #022f2f;
                    }

                    td {
                        font-size: 14px;
                        font-weight: bold;
                        padding: 7px;
                        text-align: center;
                        line-height: 17px;

                        &:not(:last-child) {
                            border-right: 1px solid #022f2f;
                        }
                    }

                    tr {
                        &:not(:last-child) {
                            th, td {
                                border-bottom: 1px solid #022f2f;
                            }
                        }
                    }
                }

                &--data {
                    &--price {
                        opacity: 0.7;
                    }
                    &--price, &--hedge {
                        display: block;
                    }
                }

                &--row-header {
                    font-size: 17px;
                    font-weight: bold;

                    &.positive {
                        background: #117979;
                    }
                    &.negative {
                        background: #ef727b;
                    }
                }
            }
        }
    }
}

.option-implied-volatility-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &--header {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        line-height: 35px;
        @include background-color-2;
        border-radius: 3px;
        white-space: nowrap;

        &--coin-select {
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;

            >span {
                margin-right: 10px;
                opacity: 0.7;
            }

            .search-select {
                width: auto;
                &--current-option {
                    padding-right: 25px;
                }
                &--input-icon {
                    right: 0;
                    opacity: 0.7;
                }
                &--search-input {
                    width: 100%;
                }
                &--options-wrapper {
                    margin: 0px;
                }
            }
        }

        &--filter {
            margin-left: 20px;
            font-size: 14px;
            font-weight: bold;

            >label {
                opacity: 0.7;
                margin-right: 8px;
            }

            >input {
                @include background-color-6;
                height: 24px;
                padding: 0px 10px;
                color: white;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
                width: 50px;
                border-radius: 3px;
                cursor: text;
            }

            >span {
                opacity: 0.7;
                margin: 0px 5px;
            }
        }
    }

    &--expiration-popup {
        @include background-color-7;
        padding: 10px;
        color: #ddd;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;

        &--trigger {
            @include button-style-3;
            height: 24px;
            line-height: 25px;
            padding: 0px 10px;
        }
        &--item {
            display: flex;
            font-size: 14px;
            font-weight: bold;
            line-height: 25px;

            >label {
                margin-right: 10px;
            }

            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    }

    &--body {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow-y: hidden;

        &--left, &--right {
            position: absolute;
            top: 0;
            bottom: 0;
        }

        &--left {
            left: 0;
            right: 50%;
        }

        &--right {
            left: 50%;
            right: 0;
            border-left: 1px solid black;
        }
    }
}

.option-implied-volatility-matrix {
    position: relative;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;

    &--header {
        border-bottom: 1px solid;
        position: relative;
        width: 100%;
        height: 40px;
        min-height: 40px;
        @include border-color-4;

        &--group-by-select {
            margin-left: 10px;
            display: inline-flex;
            white-space: nowrap;
            line-height: 40px;
            font-weight: bold;
            font-size: 14px;

            >span {
                margin-right: 10px;
                opacity: 0.7;
            }

            .search-select {
                &--current-option {
                    padding-right: 20px;
                }
                &--input-icon {
                    opacity: 0.7;
                    right: 0px;
                }
                &--search-input {
                    width: 100%;
                }
            }
        }
    }

    &--main {
        position: relative;
        width: 100%;
        height: 400%;
        overflow: auto;
    }

    &--group-matrix {
        position: relative;
        display: table;
        width: 100%;

        &--header {
            display: table-row;
            line-height: 25px;

            &--data {
                display: table-cell;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
                padding-top: 10px;

                &.BID {
                    @include text-color-positive;
                }

                &.ASK {
                    @include text-color-negative;
                }
            }
        }

        &--row {
            display: table-row;

            &:not(:last-child) {
                .option-implied-volatility-matrix--group-matrix--row--data {
                    border-bottom: 1px solid #043131;
                }
            }

            &--data {
                display: table-cell;
                padding: 3px;
                &.name {
                    width: 1%;
                    vertical-align: middle;
                    padding-left: 10px;
                    font-size: 14px;
                    font-weight: bold;

                    &.CALL {
                        @include text-color-positive;
                    }

                    &.PUT {
                        @include text-color-negative;
                    }
                }
            }
        }
    }

    &--group {
        position: relative;
        &--name {
            text-indent: 11px;
            line-height: 24px;
            font-size: 14px;
            font-weight: bold;
            @include background-color-11;
            position: sticky;
            top: 0px;
            z-index: 1;

            &.CALL {
                @include text-color-positive; 
            }
            &.PUT {
                @include text-color-negative;
            }
        }
    }

    &--table {
        position: relative;
        width: 100%;
        tbody {
            th {
                font-size: 13px;
                color: #ddd;
                opacity: 0.7;
                width: 1%;
                font-weight: bold;
                padding-right: 5px;
            }
            td {
                text-align: center;
                padding: 3px 0px;
                font-size: 14px;
                font-weight: bold;
            }
        }
        tfoot {
            td {
                text-align: center;
                font-size: 13px;
                font-weight: bold;
                color: #ddd;
                opacity: 0.7;
            }
        }
    }
}

.option-implied-volatility-surface {
    position: relative;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid;

        @include border-color-4;

        &--row {
            display: flex;
            overflow-x: auto;
            align-items: center;
            height: 40px;
            min-height: 40px;
            gap:  15px;
        }

        &--select {
            position: relative;
            display: flex;
            line-height: 31px;
            font-size: 14px;
            font-weight: bold;

            &--name {
                margin-right: 10px;
                white-space: nowrap;
                font-size: 14px;
                opacity: 0.7;
                font-weight: bold;
            }

            &--buttons {
                position: relative;
                white-space: nowrap;
            }

            &--button {
                position: relative;
                font-size: 14px;
                font-weight: bold;
                padding: 0px;
                height: 24px;
                background: none;
                border: 1px solid;
                padding: 0px 10px;
                color: #ddd;
                @include border-color-5;
                transition: all 0.3s;

                &:hover {
                    @include background-color-5;
                    color: white;
                }

                &:not(:first-child) {
                    border-left: none;
                }

                &:not(:last-child) {
                    border-right: 1px solid black;

                    &.active {
                        border-right: 1px solid black;
                    }
                }

                &:first-child {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                &:last-child {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }

                &.active {
                    @include background-color-6;
                    color: white;
                }
            }
        }

        &--position-toggle, &--position-portfolio, &--position-offset {
            display: flex;
            align-items: center;

            >label {
                margin-right: 10px;
                white-space: nowrap;
                font-size: 14px;
                opacity: 0.7;
                font-weight: bold;
            }

            >input {
                @include background-color-6;
                border-radius: 3px;
                color: white;
                width: 50px;
                font-weight: bold;
                font-size: 14px;
                height: 24px;
                padding: 0px 5px;
                cursor: text;
            }
        }

        .toggle {
            width: auto;
            height: 24px;
            &--button {
                width: 20px;
                height: 20px;
            }
            &--track {
                width: 42px;
            }
            &--label {
                line-height: 24px;
            }
        }

        .search-select {
            &--current-option {
                @include background-color-6;
                border-radius: 3px;
                padding-right: 23px;
                padding-left: 10px;
                font-size: 14px;
                height: 24px;

                &--name {
                    line-height: 24px;
                }

                >svg {
                    opacity: 0.4;
                    right: 5px;
                }
            }
            &--body {
                position: fixed;
            }
        }
    }

    &--body {
        position: relative;
        width: 100%;
        height: 100%;
    }
}