@import '../../styles/variables';

.token-transfer-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &--header {
        &--main {
            position: relative;
            text-align: center;
            font-weight: bold;
            background: #0a4a4a;
            font-size: 15px;
            height: 32px;
            min-height: 32px;
            line-height: 32px;
        }

        &--tabs {
            position: relative;
            width: 100%;
        }

        &--tab {
            width: 50%;
            height: 32px;
            min-height: 32px;
            padding: 0;
            background: #012e2e;
            font-size: 12px;
            font-weight: bold;
            color: #eee;
            text-transform: capitalize;

            &.active {
                background: #1c7070;
                color: white;
            }

            &:hover {
                color: white;
            }

            &:not(:first-child) {
                border-left: 1px solid #1c7070;
            }
        }

        &--bulk-transfer-popup {
            background: #012b2bf0;
            margin-top: 5px;
            padding: 0px;
            height: calc(100% - 50px);
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;

            &--trigger {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                padding: 0;
                width: 33px;
                font-size: 15px;
                background: none;
                color: #82b3b4;

                &:hover {
                    color: #97c9ca;
                }

                >svg {
                    transform: translateY(2px);
                }
            }

            &--title {
                font-size: 16px;
                line-height: 33px;
                height: 33px;
                min-height: 33px;
                text-indent: 11px;
                font-weight: bold;
                color: white;
            }

            &--body {
                position: relative;
                width: 100%;
                height: calc(100% - 33px);
            }
        }
    }

    &--body {
        padding: 5px 7px;
        height: 100%;
        overflow: hidden;
    }
}

.token-transfer-editor {
    position: relative;
    height: 100%;

    .search-select {
        position: relative;
        @include background-color-6;
        font-size: 16px;

        &--current-option {
            line-height: 28px;
            text-indent: 10px;
            font-size: 16px;
            color: white;

            &.not-clickable {
                @include background-color-7;
            }
        }

        &--input-icon {
            right: 7px;
        }
    }

    &--single-transfer {
        position: relative;

        &--main {
            position: relative;
            display: table;
        }

        &--message {
            @include background-color-9;
            font-size: 14px;
            line-height: 20px;
            margin-top: 10px;
            padding: 5px 10px;
            font-weight: bold;
            border-radius: 3px;
            text-align: center;
            word-break: break-word;
        }

        &--buttons {
            width: 100%;
            margin-top: 10px;
            display: flex;
        }

        &--reset-button, &--confirm-button {
            width: 50%;
            display: inline-block;
            margin: 0px 5px;
            padding: 0;
            font-weight: bold;
            height: 30px
        }

        &--reset-button {
            @include button-style-5;
            margin-left: 0px;
        }

        &--confirm-button {
            @include button-style-4;
            margin-right: 0px;
            color: #eee;

            &.WITHDRAW {
                background: #936798;
            }
        }

        .token-transfer-editor--transfer-account {
            &--type, &--pair-name {
                margin-top: 5px;
            }
        }

        .token-transfer-editor--transfer-account-swap {
            position: absolute;
            transform: rotate(-90deg) translate(25px, -7px);
        }

        .search-select {
            &--current-option {
                &--name {
                    display: block;
                    max-width: 165px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &--cross-portfolio-popup {
            @include background-color-2;
            padding: 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;

            &--description {
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
                color: white;
            }

            &--main {
                >button {
                    @include button-style-2;
                    margin: 10px 0px 0px;
                    width: 100%;
                }
            }
        }
    }

    &--row {
        position: relative;
        display: table-row;

        &--label, &--component {
            position: relative;
            display: table-cell;
            font-weight: bold;
            padding: 5px 0px;
        }

        &--label {
            font-size: 14px;
            padding-right: 10px;
            vertical-align: top;
            line-height: 28px;
        }

        &--component {
            width: 100%;
        }
    }

    &--transfer-account {
        position: relative;
        width: 100%;
        font-weight: bold;

        &--available {    
            position: relative;
            width: 100%;
            font-size: 13px;
            line-height: 18px;
            margin-top: 5px;

            &--item {
                position: relative;
                display: flex;
                width: 100%;
                white-space: nowrap;
                >label {
                    width: 100%;
                    opacity: 0.7;
                }
                >span {
                    &.clickable {
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }

        .search-select {
            &--body {
                position: fixed;
            }
        }
    }

    &--transfer-account-swap {
        background: transparent;
        color: #ddd;
        font-size: 19px;

        &:hover {
            color: white;
        }
    }

    &--account-balance-popup {
        @include background-color-4;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        padding: 10px;
        z-index: 100 !important;

        .margin-account-balance-item {
            max-width: 280px;
        }

        .cross-account-balance-item {
            min-width: 280px;
        }

        .account-asset-item {
            max-width: 250px;
        }
    }

    &--amount {
        position: relative;
        display: inline-flex;
        input {
            height: 28px;
            padding: 0px;
            display: block;
            cursor: text;
            @include background-color-6;
            text-indent: 7px;
            font-size: 16px;
            color: white;
            font-weight: bold;
            min-width: 60px;


            &:disabled {
                cursor: not-allowed;
                @include background-color-7;
            }
        }

        &--value-input, &--percent {
            position: relative;
            float: left;
            width: calc(50% - 2px);
        }

        &--value-input {
            margin-right: 2px;
        }

        &--percent {
            display: flex;
            align-items: center;
            margin-left: 2px;
            line-height: 28px;
            padding-right: 5px;
            @include background-color-6;

            &.disabled {
                background: #19364b;
            }

            >span {
                margin-left: 3px;
                font-size: 14px;
            }
        }

        &--percent-input {
            width: 100%;
        }
    }

    &--x-portfolio-transfer-record {
        font-weight: bold;
        >label {
            font-size: 13px;
            opacity: 0.7;
        }
        .toggle {
            position: relative;
            margin-top: 5px;
        }
    }

    &--bulk-transfer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        min-width: 600px;
        overflow: hidden;

        &--tabs {
            position: relative;
            border-bottom: 1px solid;
            @include border-color-5;
            height: 28px;
        }
    
        &--tab {
            width: 33.3333%;
            font-size: 14px;
            font-weight: bold;
            background: none;
            border: 1px solid;
            @include border-color-5;
            color: #eee;
            border-bottom: none;
            height: 100%;
    
            &:not(:last-child) {
                border-right: none;
            }
    
            &:hover {
                color: white;
            }
    
            &.active {
                @include background-color-6;
                color: white
            }
        }

        &--controls {
            z-index: 100;
            position: relative;
            display: flex;
            flex-direction: column;

            .token-transfer-editor--token {
                width: 230px;
            }
    
            .token-transfer-editor--transfer-account {
    
                &--name {
                    width: 230px;
                    min-width: 230px;
                }
    
                &--type {
                    width: 230px;
                    min-width: 230px;
                }
    
                &--pair-name {
                    width: 120px;
                    min-width: 120px;
                }
    
                &--type, &--pair-name {
                    margin-top: 10px;
                }
            }

            &--body {
                position: relative;
                height: 100%;
                overflow: auto;
                border-right: 1px solid;
                @include border-color-5;
            }
        }

        &--config, &--counter-account-unified-control, &--interval, &--cross-portfolio-transfer-record {
            margin-top: 5px;
            margin-right: 10px;

            .token-transfer-editor--row {
                &--label {
                    min-width: 88px;
                }
                &--component {
                    width: auto;
                }
            }
        }

        &--counter-account-unified-control {
            display: flex;
            white-space: nowrap;

            .token-transfer-editor--row {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &--amount-unified-control {
            width: 230px;

            &--item {
                padding: 10px;
                @include background-color-7;
                overflow: hidden;

                &.inactive {
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover {
                        @include background-color-5;
                    }
                }

                &:not(:first-child) {
                    margin-top: 10px;
                }

                &--title {
                    position: relative;
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .token-transfer-editor--row {
                &--label {
                    min-width: 88px;
                }
                &--component {
                    width: auto;
                }
            }
        }

        &--interval-input {
            width: 210px;
            @include background-color-6;
            height: 28px;
            font-size: 16px;
            color: white;
            font-weight: bold;
            padding: 0px 10px;
            cursor: text;
        }

        &--main {
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }

        &--table {
            
            .ReactVirtualized__Table__headerRow {
                @include background-color-1;
            }

            .ReactVirtualized__Grid__innerScrollContainer {
                overflow: visible !important;
            }

            .ReactVirtualized__Table__row {
                align-items: flex-start;
                border-bottom: 1px solid;
                @include border-color-2;
                overflow: visible !important;
                
                &:hover {
                    .token-transfer-editor--bulk-transfer--remove {
                        opacity: 1.0;
                    }
                }

                .ReactVirtualized__Table__rowColumn {
                    padding-top: 10px;
                    overflow: visible !important;
                }
            }

            &--header {
                font-size: 13px;
                line-height: 27px;
                height: 27px;
            }
        }

        &--body {
            position: relative;
            height: 100%;
            overflow: hidden;
            overflow-x: auto;
            font-weight: bold;
            margin-left: 10px;

            .token-transfer-editor--transfer-account {        
                &--name, &--type, &--pair-name {
                    min-width: 185px;
                    font-weight: bold;
                }
    
                &--type, &--pair-name {
                    margin-top: 5px;
                }
            }
        }

        &--summary {
            display: flex;
            line-height: 28px;
            height: 28px;
            align-items: center;
            padding: 0px 10px;
            font-weight: bold;
            @include background-color-5;
            white-space: nowrap;

            &--title {
                margin-right: 10px;
                font-size: 14px;
                opacity: 0.7;
            }

            &--main {
                width: 100%;
                font-size: 14px;
                >span {
                    margin-right: 11px;

                    &.warning-yellow {
                        color: #edc67e;
                    }
                    &.warning-red {
                        color: #e3858d;
                    }
                }
            }

            &--close-button {
                padding: 0px;
                background: none;
                font-size: 20px;
                color: #ddd;

                &:hover {
                    color: white;
                }

                >svg {
                    transform: translateY(2px);
                }
            }
        }

        &--footer {
            padding: 10px 12px;
            border-top: 1px solid;
            @include background-color-9;
            @include border-color-5;

            &--button {
                font-size: 14px;
                font-weight: bold;
                width: 70px;
                height: 28px;
                color: #ddd;

                &:hover {
                    color: white;
                }

                &.add {
                    float: left;
                    @include button-style-4;
                }

                &.reset, &.submit, &.discard {
                    float: right;
                    margin-left: 10px;
                }

                &.reset {
                    @include button-style-2;
                }

                &.submit {
                    @include button-style-3;
                }

                &.discard {
                    @include button-style-1;
                }
            }

            &--message {
                font-size: 16px;
                font-weight: bold;
                color: #e07981;
                float: right;
                margin-right: 5px;
                line-height: 28px;
            }
        }

        &--cross-portfolio-popup {
            @include background-color-2;
            padding: 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;

            &--description {
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
                color: white;
            }

            &--main {
                >button {
                    @include button-style-2;
                    margin: 10px 0px 0px;
                    width: 100%;
                }
            }
        }

        &--amount {
            width: 200px;
        }

        &--state {
            font-size: 13px;
            min-width: 80px;
            text-align: center;
            font-weight: bold;

            &.WAITING {
                color: lightblue;
            }

            &.TRANSFERING {
                color: gold;
            }

            &.TRANSFERRED {
                color: #4dbbbb;
            }

            &.FAILED {
                color: #ce757c;
            }
        }

        &--message {
            width: 150px;
            max-width: 150px;
            line-height: 1.3em;
            font-size: 13px;
            text-align: center;
            white-space: pre-line;
        }

        &--remove {
            background: none;
            color: white;
            font-size: 16px;
            opacity: 0;

            &:hover {
                color: #f58d97;
            }
        }

        .token-transfer-editor--transfer-account-swap {
            position: relative;
            left: 50%;
            transform: translate(-50%, 8px);
            height: 25px;
            padding: 0px;
        }

        &--account-selection-popup {
            position: relative;
            display: flex;
            flex-direction: column;
            @include background-color-1;
            padding: 0px 0px;
            box-shadow: 0px 0px 3px black;
            font-weight: bold;
            overflow: hidden;
            z-index: 101 !important;

            &--trigger {
                position: relative;
                width: 230px;
                height: 28px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                @include background-color-6;
                color: white;

                &:hover {
                    @include background-color-5;
                }
            }

            &--header {
                @include background-color-6;
                padding: 0px 10px;
                font-size: 14px;
                line-height: 28px;
                height: 28px;
                min-height: 28px;
            }
    
            &--main {
                position: relative;
                padding: 11px 9px;
                height: 100%;
                overflow: auto;
            }
    
            &--config-row {
                padding: 8px 15px;
                border-bottom: 1px solid black;
            }

            &--toggle-all-button {
                margin-top: 2px;
                height: 28px;
                font-size: 14px;
                font-weight: bold;
                color: #7eafaf;
                padding: 0px 10px;
                @include button-style-4;
            }

            &--search-string {
                position: relative;
                height: 32px;
                margin-left: 10px;
                width: 300px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                cursor: text;
                border-radius: 3px;
                @include background-color-4;
                color: white;
            }

            &--empty-result {
                position: relative;
                text-align: center;
                padding: 10px;
                font-size: 16px;
                font-weight: bold;
            }

            &--portfolios {
                position: relative;
                padding: 5px 20px 10px;
                overflow: auto;
            }

            &--portfolio {
                position: relative;
    
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
    
                &--header {
                    border-bottom: 1px solid black;
                    padding: 10px 0px;
                    line-height: 20px;
                    font-size: 14px;
    
                    >label {
                        font-size: 16px;
                        color: white;
                    }
    
                    >button {
                        padding: 0px 7px;
                        height: 24px;
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 3px;
                        margin-left: 10px;
                        @include button-style-4;
                    }
                }
    
                &--main {
                    position: relative;
                    margin-top: 5px;
                }
        
                &--item {
                    position: relative;
                    display: inline-flex;
                    line-height: 24px;
                    margin-right: 21px;
                    font-size: 14px;
        
                    >span {
                        &.black-listed {
                            text-decoration: line-through;
                            text-decoration-color: #fd818a;
                            text-decoration-thickness: 2px;
                        }
                    }
        
                    .checkbox {
                        width: 20px;
                        height: 20px;
                        transform: translateY(2px);
                        margin-left: 7px;
                    }
                }
            }
        }

        &--bulk-account-type {
            position: relative;
            display: flex;
            margin-top: 10px;

            &--type-selector, &--pair-selector {
                width: 230px;
            }

            &--pair-selector {
                margin-left: 10px;
            }
        }

        &--unified-amount, &--balance-equalizer, &--margin-ratio-equalizer {
            &--input {
                height: 28px;
                line-height: 28px;
                padding: 0px;
                text-indent: 10px;
                width: 100%;
                @include background-color-6;
                font-size: 16px;
                font-weight: bold;
                color: white;
                cursor: text;
            }

            >label {
                font-size: 13px;
                line-height: 20px;
                opacity: 0.4;
            }

            >button {
                height: 28px;
                vertical-align: top;
                margin-top: 10px;
                width: 100%;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                @include button-style-2;
                color: white;

                &:hover {
                    background: #9aadcb;
                    color: #0a182e;
                }
            }

            &--input-wrapper {
                position: relative;
                >label {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                }
            }
        }
        
        &--balance-equalizer {
            &--max-available {
                font-size: 13px;
                margin-top: 5px;
                line-height: 16px;
            }
        }

        &--margin-ratio-equalizer {
            &--amount-remark {
                font-size: 13px;
                line-height: 16px;
                margin-top: 5px;

                &.warning {
                    @include text-color-highlight-red;
                }
            }
        }

        &--position-filter-selector {
            position: relative;
            max-width: 210px;
            width: 210px;
            text-align: left;
            text-transform: none;

            .search-select {
                &--current-option {
                    font-size: 14px;
                    padding-right: 27px;
                }
                &--options-wrapper {
                    position: fixed;
                }
                &--clear-button {
                    background: #001515;
                    >svg {
                        margin-top: 1px;
                    }
                }
            }
        }

        &--position-info {
            position: relative;
            font-size: 14px;
            font-weight: bold;
            max-width: 210px;
            display: flex;
            flex-direction: column;

            &--row {
                display: inline-flex;
                width: 100%;

                &:not(:first-child) {
                    margin-top: 2px;
                }

                >label {
                    opacity: 0.6;
                }
                >span {
                    width: 100%;
                    text-align: right;
                }

                .positive {
                    color: #75e5e4;
                }

                .negative {
                    color: #dc7479;
                }

                .highlight {
                    color: #e2cb6a;
                }
            }

            &--empty-message {
                opacity: 0.6;
            }
        }
    }
}

.portfolio-account-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    @include background-color-1;
    padding: 0px 0px;
    box-shadow: 0px 0px 3px black;
    font-weight: bold;
    overflow: hidden;
    max-width: 900px !important;
    z-index: 101 !important;

    &--trigger {
        position: relative;
        @include button-style-6;
        height: 24px;
        line-height: 24px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
        color: white;

        &:hover {
            background: #488686;
        }
    }

    &--header {
        @include background-color-4;
        padding: 0px 10px;
        font-size: 14px;
        line-height: 24px;
        height: 24px;
        min-height: 24px;
    }

    &--main {
        position: relative;
        padding: 10px;
        height: 100%;
        overflow: auto;
    }

    &--config-row {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-bottom: 1px solid;
        @include border-color-2;
    }

    &--toggle-all-button {
        @include button-style-2;
        padding: 0px 10px;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;

        &.unselect-all {
            color: white;
        }

        &:hover {
            background: #094242;
        }
    }

    &--search-string {
        position: relative;
        height: 28px;
        width: 300px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        cursor: text;
        border-radius: 3px;
        @include background-color-2;
        color: white;
    }

    &--empty-result {
        position: relative;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    &--portfolios {
        position: relative;
        padding: 10px;
        overflow: auto;
    }

    &--portfolio {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &--header {
            border-bottom: 1px solid;
            @include border-color-2;
            padding-bottom: 10px;
            line-height: 20px;
            font-size: 14px;

            >label {
                font-size: 16px;
                color: white;
            }

            >button {
                @include button-style-2;
                padding: 0px 10px;
                height: 24px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                margin-left: 10px;

                &.unselect-all {
                    color: white;
                }
            }
        }

        &--main {
            position: relative;
            margin-top: 5px;
        }

        &--item {
            position: relative;
            display: inline-flex;
            min-width: 150px;
            line-height: 24px;
            margin-right: 21px;
            font-size: 14px;

            >span {
                margin-left: 8px;
                &.not-exist-warning {
                    text-decoration: line-through;
                    color: #fd818a;
                }
            }

            .checkbox {
                width: 20px;
                height: 20px;
                transform: translateY(2px);
            
                >svg {
                    position: absolute;
                }
            }
        }
    }
}