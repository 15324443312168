@import '../../styles/variables';

.timer-editor {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: auto;
    max-height: 95%;
    max-width: 100%;
    min-width: 500px;
    color: white;
    background: #094a4a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 7px #021313;

    &--header {
        position: relative;
        border-bottom: 1px solid #90b3b3;
        padding: 7px 0px;
        height: 27px;
    }

    &--title {
        position: relative;
        font-size: 19px;
        font-weight: bold;
        color: #eee;
        text-indent: 21px;
        line-height: 25px;
        float: left;
    }

    &--verify-message {
        font-weight: bold;
        float: left;
        line-height: 25px;
        margin-left: 13px;
        font-size: 13px;

        &.forbidden-message {
            color: #f17e92;
        }
    }

    &--close-button {
        position: absolute;
        right: 15px;
        background: none;
        color: #ddd;
        font-size: 20px;
        z-index: 1;
        top: 9px;

        &:hover {
            color: white;
        }
    }

    &--main {
        position: relative;
        padding: 0px 21px 230px;
        overflow-y: auto;
        height: 100%;
    }

    &--loop, &--datetime, &--description {
        position: relative;
        display: flex;
        margin-top: 12px;
        font-weight: bold;

        &--label {
            color: #ddd;
            min-width: 100px;
            font-size: 15px;
        }
    }

    &--loop {
        margin-top: 15px;
    }

    &--datetime {
        &--input {
            font-size: 14px;
            background: none;
            color: white;
            font-weight: bold;
            border-bottom: 2px solid #407575;
            width: 220px;
            
            &::-webkit-clear-button {
                display: none;
            }
            &::-ms-clear {
                display: none;
            }

            &::-webkit-calendar-picker-indicator {
                font-size: 18px;
                color: #368686;
                cursor: pointer;

                &:hover {
                    color: #ddd;
                }
            }
        }

        &--timezone-label {
            font-size: 14px;
            line-height: 22px;
            margin-left: -53px;
            opacity: 0.7;
            z-index: -1;
        }

        &--loop-input {
            position: relative;
            display: flex;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0px;
            }

            .timer-editor--datetime--input {
                width: 141px;
            }

            .timer-editor--datetime--timezone-label {
                margin-left: -50px;
            }
        }

        &--add-time-button, &--remove-time-button {
            position: relative;
            position: relative;
            margin-left: 33px;
            background: #3f7575;
            color: #ddd;
            font-size: 17px;
            padding: 0;
            width: 24px;
            border-radius: 2px;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(2.5px);
            }
        }

        &--remove-time-button {
            opacity: 0.8;

            &:hover {
                opacity: 1.0;
            }
        }
    }

    &--description {
        &--input {
            width: 100%;
            max-width: 390px;
            resize: none;
            background: #407575;
            padding: 5px 5px;
            font-size: 13px;
            border-radius: 2px;
            color: white;
            font-weight: bold;
            cursor: text;
        }
    }

    &--profile-item {
        position: relative;
        margin-top: 25px;
        z-index: 100;
        background: #094a4a;

        &:focus-within {
            z-index: 101;
        }
    }

    &--profiles {
        position: relative;

        &--add-button, &--bulk-update-button, &--resolve-all-conflict-button {
            position: relative;
            margin-top: 25px;
            font-size: 14px;
            font-weight: bold;
            color: #ddd;
            padding: 7px 17px;
            border-radius: 2px;

            &:hover {
                color: white;
            }
        }

        &--add-button {
            background: #3f7574;
        }

        &--bulk-update-button {
            margin-left: 11px;
            background: #e2cb6a;
            color: #4c4217;
        }

        &--resolve-all-conflict-button {
            margin-left: 11px;
            background: #936798;
        }
    }

    &--bulk-update-editor {
        position: relative;
        margin-top: 19px;

        &--title {
            font-size: 15px;
            font-weight: bold;
            color: #9cc1c1;
        }

        &--criteria {
            position: relative;
            margin-top: 9px;
            font-weight: bold;

            &--profile ,&--symbol {
                &--input {
                    border-bottom: 1px solid #3f7574;
                    background: transparent;
                    font-size: 13px;
                    color: white;
                    font-weight: bold;
                    cursor: text;
                }

                &--results {
                    position: relative;
                    margin-top: 7px;

                    &--item {
                        float: left;
                        font-size: 12px;
                        background: #3f7574;
                        margin-right: 5px;
                        padding: 3px 7px;
                        margin-bottom: 5px;
                        border-radius: 1px;
                        box-shadow: 0px 0px 1px black;
                    }
    
                    &--empty {
                        color: #e2cb6a;
                        font-size: 15px;
                        padding-bottom: 5px;
                    }
                }
            }

            &--profile {
                padding-bottom: 5px;
            }
        }

        .timer-profile-item {
            &--params {
                margin-top: 9px;
            }
        }
    }

    &--footer {
        position: relative;
        text-align: center;
        background: #063c3c;
        box-shadow: 0px 0px 3px #011716;
        height: 50px;
        bottom: 0;
        width: 100%;
    }

    &--reset-button, &--delete-button, &--save-button {
        width: 85px;
        font-size: 14px;
        border-radius: 2px;
        height: 29px;
        margin: 10px 5px;
        font-weight: bold;
    }

    &--reset-button {
        background: #eee;
        color: #126161;

        &:hover {
            color: #338a8a;
        }
    }

    &--delete-button {
        background: #c7586c;
        color: #ddd;

        &:hover {
            color: white;
        }
    }

    &--save-button {
        display: initial;
    }

    &--message {
        position: absolute;
        max-width: 80%;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 23px;
        background: #022b2b;
        padding: 6px 14px;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        bottom: 63px;
    }

    &--delete-modal {
        position: absolute;

        &--buttons {
            position: relative;
            text-align: center;
            margin-top: 39px;
        }

        &--message {
            font-size: 18px;
            color: #b6d8d8;
        }

        &--cancel-button, &--delete-button {
            position: relative;
            margin: 0px 9px;
            width: 110px;
            font-size: 13px;
            padding: 8px 0px;
            font-weight: bold;
        }

        &--cancel-button {
            background: #eee;
            color: #237575;

            &:hover {
                color: #339090;
            }
        }

        &--delete-button {
            background: #c7586c;
            color: #eee;
            display: initial;

            &:hover {
                color: white;
            }
        }
    }


}

.timer-profile-item {
    &--profile-name {
        position: relative;
        display: flex;
        font-weight: bold;

        &--label {
            color: #ddd;
            min-width: 100px;
            font-size: 15px;
        }

        .search-select {
            max-width: 360px;

            &--current-option {
                position: relative;
                padding-right: 50px;
                border-bottom: 2px solid #407575;
                padding-bottom: 3px;
                font-size: 15px;

                &.not-clickable {
                    >.search-select--placeholder {
                        opacity: 1.0;
                    }
                }
            }

            &--options-wrapper {
                background: #012d2d;
            }

            &--options {
                max-height: 186px;
            }

            &--input-icon {
                right: 3px;
            }

            &--search-input, &--option {
                padding: 0px 13px;
            }
        }

        &--remove-button {
            position: relative;
            margin-left: 13px;
            background: #3f7575;
            color: #ddd;
            font-size: 17px;
            padding: 0;
            width: 24px;
            min-width: 24px;
            border-radius: 2px;
            opacity: 0.7;

            &:hover {
                color: white;
                opacity: 1.0;
            }

            >svg {
                transform: translateY(2.5px);
            }
        }
    }

    &--tags {
        position: relative;
        margin-left: 100px;
        margin-top: 7px;

        &--item, &--add-button, &--input, &--input-wrapper {
            position: relative;
            float: left;
            margin-right: 7px;
            margin-bottom: 7px;
            height: 23px;
            line-height: 23px;
            padding: 0px;
        }

        &--item {
            background: #5d8cb5;
            font-size: 13px;
            font-weight: bold;
            padding-left: 9px;
            padding-right: 29px;
            box-shadow: 0px 0px 1px black;
            border-radius: 2px;
            overflow: hidden;
        }

        &--remove-button {
            position: absolute;
            right: 0px;
            height: 100%;
            padding: 0;
            width: 21px;
            background: transparent;
            color: #eee;
            border-left: 1px solid #063d3d;

            &:hover {
                color: white;
                background: #407575;
            }

            >svg {
                transform: translateY(2px);
                
            }
        }

        &--add-button {
            padding: 0px 9px;
            background: #3f7574;
            color: #ddd;
            font-weight: bold;
            border-radius: 2px;
            &:hover {
                color: white;
            }
        }

        &--input-wrapper {
            position: relative;
        }

        &--input {
            height: 22px;
            background: none;
            border-bottom: 1px solid #407575;
            font-size: 14px;
            font-weight: bold;
            padding-right: 29px;
            cursor: text;
            color: white;
        }

        &--cancel-input-button {
            padding: 0;
            left: -22px;
            position: relative;
            background: transparent;
            color: #ddd;
            font-size: 14px;
            transform: translateY(2px);
            &:hover {
                color: white;
            }
        }
    }

    &--duplicate-message {
        font-size: 13px;
        text-indent: 100px;
        margin-top: 3px;
        color: #ff78a2;
    }

    &--switch-select {
        position: relative;
        display: flex;
        width: 110px;
        line-height: 17px;
        margin: 3px 0px;

        &--label {
            font-size: 12px;
            margin-right: 5px;
        }

        .search-select {
            color: #eee;
            font-weight: bold;

            &--current-option > span {
                color: #7aa7a6;
            }

            &.enabled {
                .search-select--current-option >span {
                    color: white;
                    background: #329898;
                    padding: 0px 12px;
                    border-radius: 2px;
                }
            }

            &.disabled {
                .search-select--current-option >span {
                    color: white;
                    background: #c76282;
                    padding: 0px 10px;
                    border-radius: 2px;
                }
            }

            &--current-option {
                text-indent: 3px;
            }

            &--input-icon {
                right: 0px;
                margin-top: 1px;
            }
        }
    }

    &--conflict {
        &--button {
            color: white;
            position: absolute;
            padding: 0px;
            width: 19px;
            height: 19px;
            right: 4px;
            top: 4px;
            font-size: 17px;
            background: #e07b7b;
            border-radius: 1px;
            opacity: 0.8;
            >svg {
                transform: translateY(1px);
            }
            &:hover {
                opacity: 1.0;
            }
        }
        &--popup {
            background: #022d2d;
            font-size: 14px;
            padding: 6px 11px;
            max-width: 185px !important;
            border-radius: 2px;
            box-shadow: 0px 0px 1px black;
        }
        &--solve-button {
            position: relative;
            width: 100%;
            font-size: 12px;
            margin: 6px 0px 3px;
            padding: 5px 0px;
            border-radius: 2px;
            background: #407575;
            color: #eee;
            font-weight: bold;

            &:hover {
                color: white;
            }
        }
    }

    &--params {
        position: relative;
        margin-top: 3px;
        border-collapse: collapse;
        text-align: left;
        font-size: 13px;
        color: #ddd;
        width: auto;

        th {
            position: relative;
            padding: 5px 13px;
            white-space: nowrap;
            font-size: 13px;
        }

        thead {
            th {
                background: #053c3c;
            }
        }

        tbody {
            th {
                background: #094242;
                border-bottom: 1px solid #053c3b;
            }

            td {
                padding: 0px 13px;
                border-bottom: 1px solid #1a6161;
                border-right: 1px solid #1a6161;
                
                &:last-child {
                    border-right: none;
                }
            }
        }

        .profile-param {
            &--array {
                position: relative;
                display: flex;
                
                &--section {
                    >span {
                        font-weight: bold;
                        margin-right: 9px;
                    }

                    &.first-section {
                        >span {
                            color: #91e8e8;
                        }
                    }

                    &.second-section {
                        margin-left: 9px;
                        >span {
                            color: #e27597;
                        }
                    }
                }
            }

            &--number-input {
                position: relative;
                width: 104px;
                font-size: 14px;
                padding: 0px 5px;
                border-radius: 2px;
                background: transparent;
                color: white;
                font-weight: bold;
                cursor: text;
                height: 47px;
            }
        }

        &--switches-row {
            td {
                padding: 3px 13px !important;
            }
        }
    }

    &--symbol-param-mode {
        border-bottom: 1px solid #378586;
        margin-top: 2px;
        color: white;

        &.absolute {
            .search-select--current-option >span {
                color: #87b4dc;
            }
        }

        &.relative {
            .search-select--current-option >span {
                color: #dec27c;
            }
        }

        .search-select {
            &--input-icon {
                right: 0px;
            }
        }
    }

    &--symbol-switches {
        position: relative;
        display: flex;

        &--section {
            position: relative;
            display: table-row;
            width: 140px;
        }

        &--section-label, &--single-toggle-wrapper, &--buy-close-wrapper {
            position: relative;
            display: table-cell;
        }

        &--section-label {
            vertical-align: middle;
            font-weight: bold;
            padding-right: 9px;

            &.BUY {
                color: #91e8e8;
            }

            &.SELL {
                color: #e27597;
            }
        }
    }
}

.timer-container {
    position: relative;
    min-width: 300px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
        height: 0px;
    }

    &--header {
        position: relative;
    }

    &--toggle-detail-button {
        position: absolute;
        background: none;
        font-size: 14px;
        @include button-style-5;
        height: 28px;
        right: 0px;
        border-radius: 3px;
        font-weight: bold;
        white-space: nowrap;
    }

    &--new-button {
        position: relative;
        @include button-style-3;
        height: 28px;
        padding: 0px 10px;
        border-radius: 2px;
        font-size: 16px;
        font-weight: bold;
        color: #eee;

        &:hover {
            color: white;
        }
    }

    &--search-input {
        position: relative;
        display: block;
        margin-top: 10px;
        width: calc(100% - 18px);
        padding: 0px 10px;
        @include background-color-2;
        height: 30px;
        min-height: 30px;
        font-size: 14px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--empty-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #ddd;
    }

    &--main {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 10px;
        padding-bottom: 10px;
    }

    &--item {
        position: relative;
        border-bottom: 2px solid;
        @include border-color-2;
        padding: 10px 10px 0px;
        cursor: pointer;

        &:hover {
            @include background-color-9;
        }
    }
}

.timer-item {
    position: relative;

    &--header {
        position: relative;
        border-bottom: 1px dashed;
        @include border-color-5;

        &--id {
            font-size: 12px;
            color: #97baba;
        }

        &--loop-type {
            display: inline-block;
            display: inline-block;
            font-size: 14px;
            line-height: 23px;
            @include background-color-5;
            padding: 0px 10px;
            font-weight: bold;
            border-radius: 2px;
        }

        &--datetime {
            position: relative;
            display: flex;
            margin-top: 5px;
            line-height: 20px;

            &--timezone {
                opacity: 0.7;
                font-size: 13px;
            }


            &--value {
                margin-left: 4px;
                font-weight: bold;
                font-size: 13px;
            }
        }

        &--description {
            font-size: 14px;
            line-height: 24px;
            font-weight: bold;
            color: white;
        }
    }

    &--conflict {
        &--popup {
            font-size: 13px;
            font-weight: bold;
            @include background-color-3;
            padding: 5px 10px;
            color: #ddd;
            box-shadow: 0px 0px 1px black;
            border-radius: 2px;
        }
        &--icon {
            position: absolute;
            right: 0px;
            top: 4px;
            font-size: 18px;
            color: #ce757c;
        }
    }

    &--profile {
        position: relative;
        padding: 9px 0px;
        border-bottom: 1px solid;
        @include border-color-1;

        &:last-child {
            border-bottom: none;
        }

        &--header {
            &--left {
                float: left;
            }
        }

        &--toggle {
            float: right;
            .toggle {
                width: auto;
                height: 19px;

                &--button {
                    width: 15px;
                    height: 15px;
                }

                &--track {
                    width: 37px;
                }

                &--label {
                    width: 20px;
                    font-weight: bold;
                    line-height: 19px;
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }

        &--name {
            color: #ddd;
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;

            // &:hover {
            //     text-decoration: underline;
            // }

            &.disabled {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &--basic-info {
            font-size: 13px;
            line-height: 18px;
            margin-top: 3px;
            color: #8ba5b9;
        }

        &--symbol {
            border-bottom: 1px dashed;
            @include border-color-6;
            position: relative;
            padding: 10px 0px;

            &:last-child {
                border-bottom: none;
                padding-bottom: 0px;
            }
        }

        &--symbol-name {
            font-size: 13px;
            line-height: 18px;
            font-weight: bold;
            color: #ccc;
        }
    }

    &--symbol-params {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        margin-top: 5px;

        thead {
            th {
                font-size: 12px;
                line-height: 20px;
                color: #ddd;
                @include background-color-2;
            }
        }

        tbody {
            th {
                font-size: 12px;
                line-height: 20px;
                color: #ddd;
                @include background-color-2;
                padding: 0px 5px;
                width: 33.33333%;
            }

            td {
                font-size: 13px;
                font-weight: bold;
                color: white;
                padding: 2px 5px;
                width: 33.33333%;
            }

            tr {
                &:last-child {
                    th, td {
                        border-bottom: none;
                    }
                }
            }
        }

        &--param-mode {
            &.absolute {
                color: #87b4dc;
            }

            &.relative {
                color: #dec27c;
            }
        }
    }

    &--symbol-switch {
        position: relative;
        display: flex;
        width: 100%;

        &--name {
            font-weight: normal;
            color: #ddd;
            font-size: 12px;
        }

        &--value {
            position: relative;
            margin: 0 auto;

            &.ON {
                color: #90eaea;
            }

            &.OFF {
                color: #fd818a;
            }
        }
    }
}